import apiConfirmEmail from '@/api/mutation/auth/confirm-email';
import Logo from '@/assets/img/brand/logo-eduzz-branco.svg';
import Lines from '@/assets/img/lines.svg';
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';

const AuthBackground = styled.div`
  background-image: url(${Lines});
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: -770px -233px;
`;

const ConfirmEmail = ({ match, router }) => {
  const refCount = useRef(null);
  const { email, token } = match.params;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();

  const handleHome = () => {
    router.replace('/login');
  };

  useEffect(() => {
    apiConfirmEmail({ email, token })
      .then(() => {
        setMessage('Email verificado com sucesso!');
        toNext();
      })
      .catch((res) => {
        const errorMessage = res.source.errors[0].message;
        setMessage(errorMessage);
        if (errorMessage === 'Esse email já foi confirmado') toNext();
        else setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function toNext() {
    const interval = setInterval(() => {
      if (!refCount.current) return;
      const count = parseInt(refCount.current.innerHTML, 10);
      if (count > 1) refCount.current.innerHTML = count - 1;
      else {
        stopInterval();
        router.push('/deal');
      }
    }, 1000);

    function stopInterval() {
      clearInterval(interval);
    }
  }

  return (
    <AuthBackground className="min-vh-100 bg-auth">
      <Navbar variant="primary" expand="lg" className="p-0 b-0 mb-6">
        <Container fluid className="px-6 c-white">
          <Navbar.Brand href="#" onClick={handleHome}>
            <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top mh-100 my-4 " />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid="lg">
        <Row className="align-items-center">
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 5, offset: 3 }}>
            <Card className="p-4 p-xl-7 bg-white">
              <Card.Body className="p-0 text-center">
                <h4 className="mb-4 bold c-dark">{loading && 'Verificando....'}</h4>
                {!loading && (
                  <>
                    <h4 className="mb-4 bold c-dark">{message}</h4>
                    {!error && (
                      <>
                        <p className="text-black-50 font-size-sm mt-7">Redirecionando para o sistema em:</p>
                        <h1 ref={refCount} className="mt-2">
                          5
                        </h1>
                      </>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </AuthBackground>
  );
};

export default ConfirmEmail;
