/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingsQueryVariables = {||};
export type settingsQueryResponse = {|
  +me: ?{|
    +user: ?{|
      +id: any,
      +email: ?string,
      +first_name: ?string,
      +last_name: ?string,
      +facebook_external_id: ?string,
      +google_external_id: ?string,
      +confirm_email_token: ?string,
      +phone: ?string,
      +whatsapp: ?string,
      +link: ?string,
      +identifier: ?string,
      +meta: ?any,
      +owner: {|
        +id: any,
        +name: string,
        +document: ?string,
        +information: ?any,
        +users: $ReadOnlyArray<{|
          +id: any,
          +email: ?string,
          +first_name: ?string,
          +last_name: ?string,
          +avatar: ?string,
          +invited: ?boolean,
          +confirmed_email: boolean,
          +confirm_email_token: ?string,
          +meta: ?any,
        |}>,
      |},
    |}
  |}
|};
export type settingsQuery = {|
  variables: settingsQueryVariables,
  response: settingsQueryResponse,
|};
*/


/*
query settingsQuery {
  me {
    user {
      id
      email
      first_name
      last_name
      facebook_external_id
      google_external_id
      confirm_email_token
      phone
      whatsapp
      link
      identifier
      meta
      owner {
        id
        name
        document
        information
        users {
          id
          email
          first_name
          last_name
          avatar
          invited
          confirmed_email
          confirm_email_token
          meta
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirm_email_token",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meta",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeOutput",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "facebook_external_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "google_external_id",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "whatsapp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "link",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identifier",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "owner",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "document",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "information",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "invited",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "confirmed_email",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsQuery",
    "selections": (v6/*: any*/),
    "type": "query_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsQuery",
    "operationKind": "query",
    "text": "query settingsQuery {\n  me {\n    user {\n      id\n      email\n      first_name\n      last_name\n      facebook_external_id\n      google_external_id\n      confirm_email_token\n      phone\n      whatsapp\n      link\n      identifier\n      meta\n      owner {\n        id\n        name\n        document\n        information\n        users {\n          id\n          email\n          first_name\n          last_name\n          avatar\n          invited\n          confirmed_email\n          confirm_email_token\n          meta\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '211c044ad73e84ddd9fd898b3e5f0559';

module.exports = node;
