import { AuthContext } from '@/context/AuthContext';
import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import updateUser from '../../../../api/mutation/user/update-user';
import yup from '../../../../util/yup';

const UserField = ({ field, label, yupShape, description, opened, toggle, profile, setProfile = () => { }, editable, isPhone = false }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const { addToast } = useToasts();

  const schemaValidation = yup.object().shape(yupShape);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      formRef.current.setErrors({});
      await schemaValidation.validate(data, {
        abortEarly: false,
      });
      const newUser = {
        id: profile.id,
        data,
      };
      await updateUser(newUser);
      setLoading(false);
      const newProfile = { ...profile, ...data };
      setProfile(newProfile);
      setUser({
        ...user,
        ...data,
      });
      toggle(field);
      addToast('As alterações foram salvas.', {
        autoDismiss: true,
        appearance: 'snack',
        autoDismissTimeout: 6500,
      });
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message.includes('Necessário corresponder ao seguinte')
            ? 'Necessário ser um CNPJ válido'
            : error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (opened) {
      formRef.current.setData({ [field]: profile[field] });
    }
  }, [opened]);

  return (
    <Row className="mt-5 pb-4 border-bottom">
      <Col>
        <h5 className="bold">
          {label}
        </h5>
        {!opened
          && (
            <div className="font-size-md">
              {profile[field]}
            </div>
          )}
        {opened
          && (
            <div className="font-size-md">
              <p>{description}</p>
              <Unform ref={formRef} onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Input id={field} name={field} type="text" placeholder={label} mask={isPhone && '(99) 99999-9999'} />
                    </Form.Group>
                  </Col>

                </Row>
                <Button variant="success" type="submit"
                  loading={loading}
                  block
                >
                  Salvar
                </Button>
              </Unform>
            </div>
          )}
      </Col>
      {editable
        && (
          <Col xs="auto">
            <Button variant="link" onClick={() => toggle(field)}>
              {opened ? 'Cancelar' : 'Editar'}
            </Button>
          </Col>
        )}

    </Row>

  );
};

export default UserField;
