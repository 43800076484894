import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ShieldCheck = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}
  >
    <path d="m17.39685,10.98463a6.30155,6.30155 0 1 0 6.30155,6.30155a6.30864,6.30864 0 0 0 -6.30155,-6.30155zm3.15343,4.7594l-3.39273,3.87842a0.96783,0.96783 0 0 1 -0.69663,0.33147l-0.03191,0a0.96783,0.96783 0 0 1 -0.68599,-0.28361l-1.93921,-1.93921a0.96961,0.96961 0 0 1 1.37021,-1.37021l1.20536,1.20536l2.71029,-3.09848a0.96961,0.96961 0 1 1 1.45884,1.27804l0.00177,-0.00177z" fill="#7c1eff" />
    <path d="m16.60096,3.25615l-7.75507,-2.90882a0.53178,0.53178 0 0 0 -0.35452,0l-7.75153,2.90882a0.48569,0.48569 0 0 0 -0.3102,0.45556l0,6.23419a10.83228,10.83228 0 0 0 8.13441,10.72238a0.59204,0.59204 0 0 0 0.21271,0a6.64543,6.64543 0 0 0 0.97847,-0.3102a8.11313,8.11313 0 0 1 -0.60091,-3.07367a8.2514,8.2514 0 0 1 7.76039,-8.23013l0,-5.34258a0.48569,0.48569 0 0 0 -0.3102,-0.45556l-0.00355,0z" fill="#9d96ff" />
  </svg>
));

ShieldCheck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShieldCheck.displayName = 'ShieldCheck';

export default ShieldCheck;
