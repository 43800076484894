/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type inviteSignupMutationVariables = {|
  user_id: string,
  email: string,
  first_name: string,
  last_name: string,
  password: string,
  phone: string,
|};
export type inviteSignupMutationResponse = {|
  +invite_signup: ?{|
    +success: string,
    +message: ?string,
    +payload: ?string,
  |}
|};
export type inviteSignupMutation = {|
  variables: inviteSignupMutationVariables,
  response: inviteSignupMutationResponse,
|};
*/


/*
mutation inviteSignupMutation(
  $user_id: String!
  $email: String!
  $first_name: String!
  $last_name: String!
  $password: String!
  $phone: String!
) {
  invite_signup(user_id: $user_id, first_name: $first_name, last_name: $last_name, password: $password, email: $email, phone: $phone) {
    success
    message
    payload
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user_id",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first_name",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last_name",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "phone",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "first_name",
        "variableName": "first_name"
      },
      {
        "kind": "Variable",
        "name": "last_name",
        "variableName": "last_name"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "phone",
        "variableName": "phone"
      },
      {
        "kind": "Variable",
        "name": "user_id",
        "variableName": "user_id"
      }
    ],
    "concreteType": "ResultStatusOutput",
    "kind": "LinkedField",
    "name": "invite_signup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payload",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "inviteSignupMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "inviteSignupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "inviteSignupMutation",
    "operationKind": "mutation",
    "text": "mutation inviteSignupMutation(\n  $user_id: String!\n  $email: String!\n  $first_name: String!\n  $last_name: String!\n  $password: String!\n  $phone: String!\n) {\n  invite_signup(user_id: $user_id, first_name: $first_name, last_name: $last_name, password: $password, email: $email, phone: $phone) {\n    success\n    message\n    payload\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '299ff8af59934e5ff0cae4b55aad9c5a';

module.exports = node;
