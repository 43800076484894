/* eslint-disable max-len */
import LayoutDockless from '@/components/Partner/Layout/LayoutDockless';
import LayoutMobile from '@/components/Partner/Layout/LayoutMobile';
import DashboardPartners from '@/modules/partners/pages/DashboardPartners';
import ChangePassword from '@/pages/Auth/ChangePassword';
import ConfirmEmail from '@/pages/Auth/ConfirmEmail';
import ForgotPassword from '@/pages/Auth/ForgotPassword';
import InviteSignup from '@/pages/Auth/InviteSignup';
import Login from '@/pages/Auth/Login';
import SignupPartner from '@/pages/Auth/SignupPartner';
import OnboardInvite from '@/pages/Partner/OnboardInvite';
import Settings from '@/pages/Partner/Settings';
import { Route, Redirect, makeRouteConfig, hotRouteConfig } from 'found';
import React from 'react';

import ControlledRoute, { UserRoleRoute } from './ControlledRoute';

const redirectToDefault = '/partners/eduzz';

const Router = makeRouteConfig(
  <>
    <ControlledRoute roles={[UserRoleRoute.NOT_LOGGED]} name="login" path="/login" Component={Login} />
    <ControlledRoute roles={[UserRoleRoute.NOT_LOGGED, UserRoleRoute.AGENCY]} name="forgot_password" path="/forgot_password" Component={ForgotPassword} />
    <ControlledRoute roles={[UserRoleRoute.NOT_LOGGED]} path="/partner/signup" Component={SignupPartner} />
    <ControlledRoute path="/confirm-email/:email/:token" Component={ConfirmEmail} />
    <ControlledRoute path="/change-password/:email/:token" Component={ChangePassword} />
    <ControlledRoute path="/invite/:email/:token" Component={InviteSignup} />
    <ControlledRoute roles={[UserRoleRoute.AGENCY]} path="welcome/invite" Component={OnboardInvite} />
    <ControlledRoute roles={[UserRoleRoute.AGENCY]} redirect="/login" path="/" Component={LayoutMobile}>
      <Route Component={LayoutDockless}>
        <Route path="/partners/eduzz" Component={DashboardPartners} />
      </Route>
      <Route Component={LayoutDockless}>
        <Route name="settings" path="/settings/:option?" Component={Settings} />
      </Route>
    </ControlledRoute>
    <ControlledRoute roles={[UserRoleRoute.AGENCY]} redirect="/login" path="/" Component={LayoutMobile}>
      <Route path="/partners/eduzz" Component={DashboardPartners} />
    </ControlledRoute>
    <Redirect from="*" to={redirectToDefault} />
  </>,
);

export default hotRouteConfig(Router);
