/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import { outboxSubscription } from '@/modules/outbox/services';
import partners from '@partners-core/partners-js';
import dayjs from 'dayjs';
import React, { createContext, useState, useEffect, useRef, useMemo } from 'react';
import toast from 'react-hot-toast';

import auth from '../config/auth';
import { isSupportUser } from '../util/utils';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const rendered = useRef(false);
  const [user, setUser] = useState(auth.USER);
  const [owner, setOwner] = useState({});
  const [showGlobalButton, setShowGlobalButton] = useState(false);
  const [outboxes, setOutboxes] = useState([]);
  const [preOutboxes, setPrevOutboxes] = useState([]);
  const outboxWatcher = useRef(null);

  const hasOutboxes = useMemo(() => !!outboxes.length, [outboxes]);

  useEffect(() => {
    if (user?.token) {
      auth.setUser(user);
      if (!rendered.current) {
        me();
      }
    }
  }, [user]);

  useEffect(() => {
    const newOutboxes = outboxes.map((outbox) => outbox.id);
    const success = preOutboxes.filter((prevOutbox) => !newOutboxes.includes(prevOutbox.id));
    success.forEach(outboxSuccessAlert);
    setPrevOutboxes(outboxes);
  }, [outboxes]);

  useEffect(() => {
    if (isSupportUser(user?.email)) {
      watchOutbox();
    }

    return () => unwatchOutbox();
  }, []);

  const getUser = async (userId) => {
    const userData = await partners.user.findByPk({
      id: userId || user.id || auth.USER.id,
      select: {
        id: true,
        first_name: true,
        last_name: true,
        phone: true,
        confirmed_email: true,
        email: true,
        avatar: true,
        meta: true,
        permissions: true,
        google_external_id: true,
        owner: {
          id: true,
          name: true,
          information: true,
          qualification: true,
          balance: true,
          type: true,
          company_uuid: {
            path: 'information.company_uuid',
          },
          users: {
            id: true,
            email: true,
            first_name: true,
            last_name: true,
            avatar: true,
            device_chat_id: {
              path: 'meta.device_chat_id',
            },
            google_external_id: true,
          },
          users_aggregate: {
            aggregate: {
              count: true,
            },
          },
          version: true,
          level_id: true,
          company: true,
          partner_id: true,
        },
      },
    });

    return userData;
  };

  const me = async (userId) => {
    rendered.current = true;
    const userData = await getUser(userId);

    const newUser = {
      ...user,
      ...userData,
    };

    setOwner(newUser.owner);

    if (isSupportUser(user?.email)) {
      const { version } = newUser.owner;
      if (version?.partners !== 2) {
        partners.customer.updateVersion({ version: 2 });
        toast('Estamos atualizando a versão do Dashboard de parceiros Eduzz. Ele pode apresentar inconsistências nesse meio tempo.', {
          icon: '🚀',
          duration: 8000,
          position: 'top-right',
        });
        newUser.owner.version = { ...newUser.owner.version, partners: 2 };
      }
    }

    setUser(newUser);
  };

  const fetchOwner = async () => {
    me();
  };

  const hasPermission = (permissions) => (Array.isArray(permissions)
    ? permissions.some((p) => user?.permissions?.includes(p))
    : user?.permissions?.includes(permissions));

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  };

  const logout = () => {
    auth.logout();
    setUser({});
    setOwner({});

    deleteAllCookies();
  };

  const outboxSuccessAlert = () => {
    toast.success('As vinculações foram finalizadas com sucesso!', { duration: 8000, position: 'top-right' });
  };

  const watchOutbox = async () => {
    outboxWatcher.current = outboxSubscription((response) => {
      const responseOutbox = response.outbox.map((outbox) => {
        const now = dayjs();
        const diff = now.diff(outbox.created_at, 'minutes');
        if (outbox.status.value === 'SENT' && outbox.progress === 0 && diff > 30) {
          partners.outbox.updateByPk({
            pk_columns: {
              id: outbox.id,
            },
            _set: {
              status_enum: 'ERROR',
            },
          });
          return {
            ...outbox,
            status: {
              label: 'Erro',
              value: 'ERROR',
            },
          };
        }

        return outbox;
      });

      setOutboxes(responseOutbox);
    });
  };

  const unwatchOutbox = () => {
    outboxWatcher?.current?.disposable();
  };

  const hasGoogle = () => !!user.google_external_id;
  const setHasGoogle = () => {
    setUser({
      ...user,
      google_external_id: true,
    });
  };

  const context = {
    hasOutboxes,
    outboxes,
    showGlobalButton,
    setShowGlobalButton,
    user,
    setUser,
    getUser,
    owner,
    fetchOwner,
    setOwner,
    logout,
    hasPermission,
    hasGoogle,
    setHasGoogle,
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
}

export {
  AuthContext,
  AuthProvider,
};
