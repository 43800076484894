import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

import Checkbox from '../Input/Checkbox';

const FormCheckbox = ({ id, name, label, value, options, type, onChange, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => refs.filter((ref) => ref.checked).map((ref) => ref.value),
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false; // eslint-disable-line no-param-reassign
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values && values.toString().includes(ref.id)) {
            ref.checked = true; // eslint-disable-line no-param-reassign
          }
        });
      },
    });
  }, [fieldName, registerField]);

  const handleChange = (e, option, index) => {
    if (onChange) {
      onChange(e, option);
    }

    if (type === 'radio') {
      inputRefs.current.forEach((ref, jndex) => {
        const checked = jndex === index;
        ref.checked = checked; // eslint-disable-line no-param-reassign
      });
    }
  };

  const mountFormCheck = (option, index) => (
    <Checkbox
      type={type}
      key={option.id + option.value}
      id={option.id}
      name={option.name}
      label={option.label}
      value={option.value}
      checked={option?.checked}
      ref={(ref) => {
        inputRefs.current[index] = ref;
      }}
      onChange={(e) => handleChange(e, option, index)}
      {...rest}
    />
  );

  return (
    <>
      {options?.length > 0
        ? options.map((option, index) => mountFormCheck(option, index))
        : mountFormCheck({ id, value, name, label }, 0)}
      {error && <span className="text-danger font-size-xs ml-2 mt-2 font-weight-bold">{error}</span>}
    </>
  );
};

export default FormCheckbox;
