/* eslint-disable quotes */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
import locales from '../locales';

export default {
  [locales.BRAZILIAN_PORTUGUESE]: {
    hello: 'Ola Mundo!',
    error_404: 'Erro 404',
    user_not_found: 'Usuário não encontrado, talvez você pretendia se cadastrar?',
    // Bot dialog
    bot_introduction: 'Olá eu sou a Clintz. $delay(1000) Estou aqui para ajudar a descobrir sua demanda em Marketing e Vendas. $delay(500)Mas primeiro preciso te conhecer melhor.',
    // botDialog02: 'Vou ajudar você a investir em marketing da melhor forma! Para isso, quero conhecer voce$delay(500)$backspace(1)ê melhor. <br /><b>Como posso te chamar?</b>',
    bot_your_name: 'Vamos lá, $delay(700) como posso te chamar?',
    user_your_name_response: "Pode me chamar de ${name}.",
    bot_your_name_treplica: "Estamos realmente felizes em ter você aqui,$delay(100) ${name}.$delay(600) Agora vamos descobrir juntos a sua demanda.",
    bot_your_segment: "Para entender melhor o contexto do seu projeto, <b>pode me informar qual segmento do seu negócio?</b>",
    user_your_segment_response: "Minha empresa é do segmento de ${mySegment.text}",
    user_project_type_response: "${projectType.text}",
    bot_your_email: 'Para continuar, $delay(700) informe seu e-mail.',
    bot_monthly_income: "Entendi. Agora para entender o seu momento financeiro, $delay(500)qual o <b>faturamento mensal médio da empresa?</b> $delay(700)(Não precisa se preocupar, essa informação é sigilosa)",
    user_monthly_income: "${monthlyIncome.maskedValue}",
    bot_project_type: "Legal. Agora sobre o seu projeto, qual dessas opções melhor se encaixa na sua atual situação?",
    bot_frequency_data: "Certo. $delay(500)Agora queremos entender com que frequência você acompanha seus resultados de marketing e vendas.",
    bot_strategy_options: "Irado! E a respeito das estratégias de marketing.$delay(500) <b>Qual dessas você já trabalhou alguma vez?</b>.",
    user_frequency_data_response: "${frequency_data.text}",
    bot_choose_launch: "Legal hein, não se preocupa que vamos te ajudar nesse lançamento!",
    bot_choose_launch_2: "Tá, mas me fala ai <b>o que você vai precisar para o lançamento?</b>",
    botDialog03: "Bem, ${name}, vamos entender o cenário da sua empresa e tracar$delay(500)$backspace(3)$delay(500)çar um plano para que consiga vender mais. <br />Me fala qual é o <b>segmento da sua empresa ?</b>",
    userDialog02: "Minha empresa é do segmento de $delay(500)${segment}.",
    botDialog04: 'Legal. Para construirmos o melhor caminho de marketing para o seu momento, eu também preciso saber quanto a sua empresa está faturando por mês. <b>Pode me contar qual é a média mensal dos últimos 3 meses?</b> (Não se preocupe, esta informação é sigilosa).',
    userDialog03: "Nos ultimos 3 meses a nossa media mensal foi $delay(500)${monthlyIncome}.",
    botDialog05: 'Ok qual é o percentual em <b>investimento em marketing ?</b>',
  },
};
