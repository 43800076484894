import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { css } from 'styled-components';

const colors = ['#a9f451', '#009FD4', '#DFC1FF', '#77baa2', '#fbd55c', '#f4aee9', '#3e396f'];

const numberFromText = (text) => {
  const charCodes = text
    .split('') // => ["A", "A"]
    .map((char) => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return parseInt(charCodes, 10);
};

const ColoredAvatar = styled.div`
  ${(props) => css`
    background: ${colors[numberFromText(props.title || props.src) % colors.length]};
    color: white;
  `}
`;

const Avatar = ({ size, online, offline, tooltip, rounded, roundedCircle, title, src, className = '', imgClassName, color, children }) => {
  let divClass = `avatar ${className} `;
  let imgClass = `avatar-img ${imgClassName} `;
  let spanClass = 'avatar-title color-white ';

  if (size !== undefined && size !== 'md') divClass += `avatar-${size} `;

  if (online) divClass += 'avatar-online ';
  else if (offline) divClass += 'avatar-offline ';

  if (rounded) {
    imgClass += 'rounded ';
    spanClass += 'rounded ';
  } else if (roundedCircle) {
    imgClass += 'rounded-circle ';
    spanClass += 'rounded-circle ';
  }

  if (color) spanClass += `bg-${color} `;

  if (src && src !== undefined) {
    return (
      <>
        {tooltip
          ? (
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={(
                <Tooltip className="tooltip-primary">
                  {tooltip}
                </Tooltip>
              )}
            >
              <div className={divClass}>
                <img src={src} className={imgClass} alt="..." />
              </div>
            </OverlayTrigger>
          )
          : (
            <div className={divClass} title={title}>
              <img src={src} className={imgClass} alt="..." />
            </div>
          )}
      </>
    );
  }

  if (title !== undefined && (!src || src === undefined || src === '')) {
    let newTitle = title;
    const splitedTitle = newTitle.split(' ');
    if (splitedTitle.length > 1) {
      newTitle = splitedTitle[0].substring(0, 1) + splitedTitle[1].substring(0, 1);
      newTitle = newTitle.toUpperCase();
    } else {
      newTitle = newTitle.substring(0, 1).toUpperCase();
    }
    return (
      <>
        {tooltip
          ? (
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={(
                <Tooltip className="tooltip-primary">
                  {tooltip}
                </Tooltip>
              )}
            >
              <div className={divClass}>
                <ColoredAvatar className={spanClass} src={newTitle}>
                  {newTitle}
                </ColoredAvatar>
              </div>
            </OverlayTrigger>
          )
          : (
            <div className={divClass}>
              <ColoredAvatar className={spanClass} title={newTitle}>
                {newTitle}
              </ColoredAvatar>
            </div>
          )}
      </>
    );
  }

  return (
    <>
      {tooltip

        ? (
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={(
              <Tooltip className="tooltip-primary">
                {tooltip}
              </Tooltip>
            )}
          >
            <div className={divClass}>
              <span className={spanClass}>{children}</span>
            </div>
          </OverlayTrigger>
        )
        : (
          <div className={divClass}>
            <span className={spanClass}>{children}</span>
          </div>
        )}

    </>

  );
};

export default Avatar;
