/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type owner_set_input = {|
  average_ticket?: ?any,
  balance?: ?any,
  billing_email?: ?string,
  city?: ?string,
  clintz_eligible?: ?boolean,
  clintz_enabled?: ?boolean,
  company?: ?any,
  created_at?: ?any,
  document?: ?string,
  domain?: ?string,
  employees?: ?string,
  fields_template?: ?any,
  id?: ?any,
  indications?: ?any,
  information?: ?any,
  level_id?: ?any,
  name?: ?string,
  partner_id?: ?string,
  qualification?: ?any,
  receiver_bank_account_id?: ?string,
  referral_owner_id?: ?any,
  search_key?: ?string,
  segments?: ?any,
  state?: ?string,
  subscription_id?: ?any,
  type?: ?string,
  updated_at?: ?any,
  version?: ?any,
  website?: ?string,
|};
export type owner_append_input = {|
  balance?: ?any,
  company?: ?any,
  fields_template?: ?any,
  indications?: ?any,
  information?: ?any,
  qualification?: ?any,
  segments?: ?any,
  version?: ?any,
|};
export type updateOwnerByPkMutationVariables = {|
  id: any,
  data?: ?owner_set_input,
  append?: ?owner_append_input,
|};
export type updateOwnerByPkMutationResponse = {|
  +update_owner_by_pk: ?{|
    +name: string
  |}
|};
export type updateOwnerByPkMutation = {|
  variables: updateOwnerByPkMutationVariables,
  response: updateOwnerByPkMutationResponse,
|};
*/


/*
mutation updateOwnerByPkMutation(
  $id: uuid!
  $data: owner_set_input
  $append: owner_append_input
) {
  update_owner_by_pk(pk_columns: {id: $id}, _set: $data, _append: $append) {
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "uuid!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data",
    "type": "owner_set_input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "append",
    "type": "owner_append_input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_append",
        "variableName": "append"
      },
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "data"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "owner",
    "kind": "LinkedField",
    "name": "update_owner_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateOwnerByPkMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateOwnerByPkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateOwnerByPkMutation",
    "operationKind": "mutation",
    "text": "mutation updateOwnerByPkMutation(\n  $id: uuid!\n  $data: owner_set_input\n  $append: owner_append_input\n) {\n  update_owner_by_pk(pk_columns: {id: $id}, _set: $data, _append: $append) {\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '41e13421d05a456b7cdbf2f76a3c456d';

module.exports = node;
