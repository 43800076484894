import {
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
  Observable,
} from 'relay-runtime';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import auth from './auth';

const BASE_URL = process.env.REACT_APP_WEB_API_URL;
const BASE_URL_WS = BASE_URL.replace(/^(https|http)/, process.env.NODE_ENV === 'development' ? 'ws' : 'wss');

const oneMinute = 60 * 1000;
const cache = new QueryResponseCache({ size: 250, ttl: oneMinute });

async function fetchQuery(operation, variables, cacheConfig) {
  const queryID = operation.text;
  const isMutation = operation.operationKind === 'mutation';
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;

  // Try to get data from cache on queries
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (auth.TOKEN) {
    headers.Authorization = `Bearer ${auth.TOKEN}`;
  } else {
    headers['x-hasura-role'] = 'anonymous';
  }

  const response = await fetch(BASE_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  const json = await response.json();

  const isHasuraLimitError = json?.errors?.[0]?.message === 'hasura cloud limit of 60 requests/minute exceeded';
  if (isHasuraLimitError) {
    // eslint-disable-next-line no-alert
    alert('[STAGING] Limitação do ambiente. Aguarde um minuto para continuar testando.');
  }

  if (isQuery && json) {
    cache.set(queryID, variables, json);
  }

  // Clear cache on mutations
  if (isMutation) {
    cache.clear();
  }

  return json;
}

const setupSubscription = (config, variables) => {
  const query = config.text;
  const subscriptionClient = new SubscriptionClient(BASE_URL_WS, {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${auth.TOKEN}`,
      },
    },
  });

  return Observable.create((sink) => {
    const c = subscriptionClient.request({ query, variables }).subscribe(sink);
    return c.unsubscribe;
  });
};

export const store = new Store(new RecordSource());

const environment = new Environment({
  network: Network.create(fetchQuery, setupSubscription),
  store,
});

export default environment;
