import BannerSevenMobile from '@/assets/img/banner-seven-mobile.png';
import BannerSeven from '@/assets/img/banner-seven.png';
import BgBannerTop from '@/assets/img/bg-banner-top.png';
import BgBeneficios from '@/assets/img/bg-beneficios.png';
import BgNivel from '@/assets/img/bg-nivel.png';
import BgBannerTopMobile from '@/assets/img/bg-partners-top-mobile.png';
import { Select } from '@partners-core/components';
import { Card, Row, Accordion } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import styled from 'styled-components';

export const IconBlur = styled.div`
    background: rgba(255, 203, 0, 0.61);
    filter: blur(40px);
    border-radius: 97.3333px;
    width: 146px; 
    height: 146px;

`;

export const IconBlurMobile = styled.div`
    background: rgba(255, 203, 0, 0.61);
    filter: blur(30px);
    border-radius: 97.3333px;
    width: 100px; 
    height: 100px;

`;

export const SliderBlur = styled.div`
    background: rgba(255, 203, 0, 0.61);
    filter: blur(40px);
    border-radius: 20px;
    width: 100%; 
    height: 25px;
    position: absolute;

`;

export const LevelSlider = styled(Slider)`
  background: linear-gradient(90deg, #FFCB00 0%, rgba(172, 174, 86, 0.23) 100%);
  border: 1px solid rgba(185, 168, 80, 0.37);
  height: 9px;
  /* width: 360px; */
    .rangeslider__handle {
      background: #FFCB00;
      border: 2.66667px solid #FFFFFF;
      width: 16px;
      height: 16px;
      pointer-events: none;
    };
    .rangeslider__handle-label {
      color: #FFEFB0;
      font-size: 10px;
      position: absolute !important;
      font-weight: 500;
      top: -25px;
      left: -30px;
    };
    .rangeslider__label-item {
      color: white !important;
      font-size: 10px !important;
      pointer-events: none;
    };
    .rangeslider__fill {
      background-color: rgba(255, 203, 0, 0.61);
    }

`;

export const DatepickerContainer = styled.div`
  .react-datepicker {
    min-width: 750px !important;
  }
  .react-datepicker-popper {
    transform: translate3d(12px, 70px, 0px) !important;
  }
  .form-control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #FAFAFF;
  }
`;

export const BackgroundBannerTop = styled(Row)`
  &.banner-browser {
    background-image: url(${BgBannerTop});
    background-size: cover;
  }
  &.banner-mobile {
    background-image: url(${BgBannerTopMobile});
    background-size: 100% 100%;
  }
`;

export const CardBenefit = styled(Card)`
  background-image: url(${BgBeneficios});
  border: 1px solid #3C4065;
`;

export const CardLevel = styled(Card)`
  background-image: url(${BgNivel});
  background-size: cover;
`;

export const CardBanner = styled(Card)`
  &.card-browser {
    background-image: url(${BannerSeven}) !important;
    min-height: 240px !important;
    background-size: 100% 100%;
  }
  &.card-mobile {
    background-image: url(${BannerSevenMobile}) !important;
    min-height: 500px !important;
    background-size: 100% 100%;

  }

`;

export const AccordionMissions = styled(Accordion)`
  .card{
    background-color: rgba(48, 63, 117, 0.26) !important;
      border-color: rgba(48, 63, 117, 0.26) !important;
      background-image: none !important;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }
  .float-button{
    background-color: rgba(48, 63, 117, 0.26) !important;
    border: none !important;
    z-index: 1;
  }

`;

export const AccordionBenefit = styled(Accordion)`
  .card{
    background-color: #191835 !important;
    border-color: #191835 !important;
    background-image: none !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    top: -10px;
    box-shadow: none !important;
  }
  .d-inline-block{
    display: block !important;
  }

  .float-button{
    z-index: 1;
    background-color: #0C0B22 !important;
    border-color: #0C0B22 !important;
      &:focus {
        background-color: #2C2B56 !important;
        border-color: #2C2B56 !important;
            }
  }

  .active{
    background-color: #2C2B56 !important;
  }
`;

export const ScrollableSelect = styled(Select)`
  .react-select__value-container{
    max-height: 43px;
    overflow-y: auto;
  }
  
`;
