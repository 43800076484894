/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sendConfirmEmailMutationVariables = {||};
export type sendConfirmEmailMutationResponse = {|
  +send_confirm_email: ?{|
    +isSend: string
  |}
|};
export type sendConfirmEmailMutation = {|
  variables: sendConfirmEmailMutationVariables,
  response: sendConfirmEmailMutationResponse,
|};
*/


/*
mutation sendConfirmEmailMutation {
  send_confirm_email {
    isSend
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SendConfirmEmailOutput",
    "kind": "LinkedField",
    "name": "send_confirm_email",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSend",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "sendConfirmEmailMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "sendConfirmEmailMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sendConfirmEmailMutation",
    "operationKind": "mutation",
    "text": "mutation sendConfirmEmailMutation {\n  send_confirm_email {\n    isSend\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a0f825f1bfe95c01455a257983a43ca';

module.exports = node;
