import apiChangePassword from '@/api/mutation/auth/change-password';
import Logo from '@/assets/img/brand/logo-eduzz-branco.svg';
import Lines from '@/assets/img/lines.svg';
import { AuthContext } from '@/context/AuthContext';
import yup from '@/util/yup';
import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import React, { useState, useContext, useRef } from 'react';
import { Row, Col, Card, Navbar, Container, Alert } from 'react-bootstrap';
import styled from 'styled-components';

const AuthBackground = styled.div`
  background-image: url(${Lines});
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: -770px -233px;
`;

const schemaValidation = yup.object().shape({
  password: yup
    .string()
    .min(6)
    .required(),
  password_repeat: yup
    .string()
    .min(6)
    .required()
    .oneOf([yup.ref('password'), null], 'A confirmação de senha não confere'),
});

const ChangePassword = ({ match, router }) => {
  const refCount = useRef(null);
  const formRef = useRef(null);
  const { logout } = useContext(AuthContext);
  const { email, token } = match.params;
  const [loading, setLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [message, setMessage] = useState();

  const handleHome = () => {
    router.replace('/login');
  };

  const handleSubmit = async (payload) => {
    setMessage(null);
    try {
      formRef.current.setErrors({});
      await schemaValidation.validate(payload, {
        abortEarly: false,
      });
      onSuccess(payload.password);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const onSuccess = (password) => {
    logout();
    apiChangePassword({ email, token, password })
      .then(() => {
        setMessage('Senha alterada com sucesso!');
        toNext();
      })
      .catch((res) => {
        const errorMessage = res.source.errors[0].message;
        setMessage(errorMessage);
        if (errorMessage === 'Esse email já foi confirmado') toNext();
        // else setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function toNext() {
    setIsComplete(true);
    const interval = setInterval(() => {
      if (!refCount.current) return;
      const count = parseInt(refCount.current.innerHTML, 10);
      if (count > 1) refCount.current.innerHTML = count - 1;
      else {
        stopInterval();
        router.replace('/login');
      }
    }, 1000);

    function stopInterval() {
      clearInterval(interval);
    }
  }

  return (
    <AuthBackground className="min-vh-100 bg-auth">
      <Navbar variant="primary" expand="lg" className="p-0 b-0 mb-6">
        <Container fluid className="px-6 c-white">
          <Navbar.Brand href="#" onClick={handleHome}>
            <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top mh-100 my-4 " />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid="lg">
        <Row className="align-items-center">
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Card className="mx-xl-7 p-4 p-xl-7 bg-white card">
              <Card.Body className="p-0">
                {!isComplete && (
                  <>
                    <h4 className="mb-4 bold c-dark text-center">Alterar senha</h4>
                    <p className="c-dark font-size-sm mb-5 text-center">
                      Sua nova senha deve ter mínimo 6 caracteres e pode conter letras, números e/ou caracteres especiais.
                    </p>
                    <Unform ref={formRef} onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Input id="password" name="password" type="password" placeholder="Senha" autoComplete="new-password" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          id="password_repeat"
                          name="password_repeat"
                          type="password"
                          placeholder="Confirmar senha"
                          autoComplete="new-password"
                        />
                      </Form.Group>
                      {message && (
                        <Alert variant="danger" className="text-white" onClose={() => setMessage(null)} dismissible>
                          {message}
                        </Alert>
                      )}
                      <Button loading={loading} variant="success" type="submit" block>
                        Continuar
                      </Button>
                    </Unform>
                  </>
                )}
                {isComplete && (
                  <div className="text-center">
                    <h4 className="mb-4 bold c-dark">{message}</h4>
                    <p className="text-black-50 font-size-sm mt-7">Redirecionando para página de login em:</p>
                    <h1 ref={refCount} className="mt-2">
                      5
                    </h1>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </AuthBackground>
  );
};

export default ChangePassword;
