import React, { useState } from 'react';

import { setHasGoogleVerify } from '@/util/user-config';

import googleVinculate from '@/api/mutation/auth/google_vinculate';
import GoogleConnect from './GoogleConnect';

const ButtonGoogleVinculate = ({ setHasGoogle, text = 'Conectar com Google' }) => {
  const [googleLoading, setGoogleLoading] = useState(false);

  const handleSuccessGoogle = async (payload) => {
    setGoogleLoading(true);
    try {
      const data = {
        credential: payload.code,
      };

      await googleVinculate(data);
      setHasGoogleVerify(true);
      if (setHasGoogle) {
        setHasGoogle(true);
      }
    } catch (e) {
      console.error(e);
    }
    setGoogleLoading(false);
  };

  return (
    <GoogleConnect
      text={text}
      loading={googleLoading}
      onSuccess={handleSuccessGoogle}
      forceConcent
      variant="blue"
      className="text-left"
    />
  );
};

export default ButtonGoogleVinculate;
