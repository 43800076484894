import { Form, Button } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FiArrowRight } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import inviteUser from '../../../api/mutation/auth/invite-user';
import yup from '../../../util/yup';

const UserInviteForm = ({ ownerId, onSubmit }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const schemaValidation = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
  });

  const handleSubmit = async (data) => {
    const validationErrors = {};
    try {
      setLoading(true);
      formRef.current.setErrors({});
      await schemaValidation.validate(data, {
        abortEarly: false,
      });
      const invitation = {
        email: data.email,
        owner_id: ownerId,
      };

      const result = await inviteUser(invitation);
      setLoading(false);
      if (result.invite_user.success) {
        addToast(`Convite enviado para ${data.email}`, {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
        formRef.current.reset();
        onSubmit({ email: invitation.email, id: result.invite_user.user_id });
      } else {
        validationErrors.email = result.invite_user.message;
        formRef.current.setErrors(validationErrors);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Unform ref={formRef} onSubmit={handleSubmit}>
      <Row className="align-items-center justify-content-center">
        <Col xs={12}>
          <Form.Group>
            <Form.Input id="email" name="email" type="text" placeholder="usuario@empresa.com"
              className="form-control-translucent form-control-md c-dark alive bold"
            />
          </Form.Group>

          <Button variant="blue" type="submit" className="mb-3"
            loading={loading}
            block
          >
            Enviar convite
            <FiArrowRight className="ml-2" size="18" />
          </Button>
        </Col>
      </Row>
    </Unform>
  );
};

export default UserInviteForm;
