import React, { forwardRef } from 'react';
import { FormCheck } from 'react-bootstrap';

const Checkbox = forwardRef(({ id, name, label, value, className, formClassName, ...rest }, ref) => (
  <div className={className}>
    <FormCheck className={`mb-2 ${formClassName}`} id={id || value}>
      <FormCheck.Input ref={ref} name={name} value={value} {...rest} />
      <FormCheck.Label htmlFor={name} className="pl-2">{label || value}</FormCheck.Label>
    </FormCheck>
  </div>
));

export default Checkbox;
