import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';

const StyledType = styled.div`
    font-weight: ${(props) => props.fontWeight};
    span{
      font-size: ${(props) => props.percent / 100}em;
    }
  `;
const Typography = ({ variant = 'span', fontWeight = 'normal', tooltipPosition = 'bottom', tooltipVariant = 'primary', percent = 100, tooltip, children, ...rest }) => (
  <StyledType as={variant} variant={variant} fontWeight={fontWeight} percent={percent} {...rest}>
    {tooltip
      ? (
        <OverlayTrigger
          key={tooltipPosition}
          placement={tooltipPosition}
          overlay={(
            <Tooltip className={`tooltip-${tooltipVariant}`}>
              {tooltip}
            </Tooltip>
          )}
        >
          <span>
            {children}
          </span>
        </OverlayTrigger>
      )
      : (
        <span>
          {children}
        </span>
      )}
  </StyledType>
);

export default Typography;
