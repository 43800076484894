import React from 'react';
import { Tooltip } from 'react-tippy';

const Switch = ({ id = '', label = '', value = false, onChange, tooltip, disabled = false }) => {
  const handleChange = () => {
    const newValue = !value;
    onChange(newValue);
  };

  return (
    <div className="custom-switch">
      <Tooltip
        position="top"
        title={tooltip}
      >
        <input type="checkbox" checked={value} onChange={handleChange} className="custom-control-input" id={id} />
        <label
          className={disabled ? 'custom-control-label disabled font-size-md font-weight-regular'
            : 'custom-control-label font-size-md font-weight-regular'}
          htmlFor={id}
        >
          {label}
        </label>
      </Tooltip>
    </div>
  );
};

export default Switch;
