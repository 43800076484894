import { Typography } from '@partners-core/components';
import React from 'react';
import { Row } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import { FiCheck, FiTriangle } from 'react-icons/fi';

import { ColIcon, RowIcon, TimelineRule, TimelineContainer, ContainerTimeline } from './styles';

const Timeline = ({ myLevel, levels, getIcon, setShowModal, setLevelSelected }) => (
  <ContainerTimeline>
    <Row className="align-items-center">
      {levels?.map((item, index) => (
        <ColIcon key={`timeline-${item.name}`} xs="2" className="p-0 text-left">
          <RowIcon className="p-0 m-0 pointer" onClick={() => { setShowModal(true); setLevelSelected(item); }}>
            {index < myLevel.order
              && getIcon({ type: item.name, gradient: true, size: 22, style: { marginLeft: index === 0 ? -6 : -12, marginTop: 4 } })}
            {index === myLevel.order
              && getIcon({ type: item.name, gradient: true, size: 30, style: { marginLeft: index === 0 ? -15 : -16 } })}
            { index > myLevel.order && (
              <>
                {getIcon({ type: item.name,
                  className: 'icon-gradient',
                  gradient: true,
                  size: 22,
                  style: { marginLeft: index === 0 ? -6 : -12, marginTop: 4 } })}
                {getIcon({ type: item.name,
                  className: 'icon-disabled',
                  fill: '#545861',
                  size: 22,
                  style: { marginLeft: index === 0 ? -6 : -12, marginTop: 4 } })}
              </>
            )}
          </RowIcon>
          <TimelineContainer>
            {index < myLevel.order && (<FiCheck color="#A9A7FF" size={10} style={{ zIndex: 1000, marginLeft: index === 0 ? 0 : -6 }} />)}
            {index === myLevel.order && (<FiTriangle fill="#FFCB00" size={10} color="none" style={{ marginLeft: -6, zIndex: 1000 }} />)}
            {index > myLevel.order && (<FaLock className="lock" size={10} color="none" style={{ marginLeft: -6, zIndex: 1000 }} />)}
            {index + 1 !== levels.length
            && (<TimelineRule />)}
          </TimelineContainer>
          { item.name === 'ROSHI'
                    && (
                      <Typography variant="h6" percent="80" className="my-0 label-timeline"
                        style={{ marginLeft: -12 }}
                      >
                        {item.label}
                      </Typography>
                    )}
          { item.name !== 'ROSHI'
                    && (
                      <Typography variant="h6" percent="80" className="my-0 label-timeline"
                        style={{ marginLeft: index === 0 ? -8 : -15 }}
                      >
                        {item.label}
                      </Typography>
                    )}
        </ColIcon>
      ))}
    </Row>
  </ContainerTimeline>
);
export default Timeline;
