export default {
  get_config_owner: {
    name: 'config',
    resource: 'owner',
  },
  support_bind_product: {
    name: 'supportBindProduct',
    resource: 'owner',
  },
  partner_support_unbind: {
    name: 'supportUnbind',
    resource: 'partner',
  },
  auth_login: {
    name: 'login',
    resource: 'auth',
  },
};
