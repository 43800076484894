/* eslint-disable global-require */
/* eslint-disable camelcase */
import sendConfirmEmail from '@/api/mutation/auth/send-confirm-email';
import LogoEduzz from '@/assets/img/brand/eduzz-logo.svg';
import { useWidgetState, useWidgetDispatch } from '@/context/SidebarContext';
import OutboxModal from '@/modules/outbox/components/OutboxModal';
import { isSupportUser } from '@/util/utils';
import { Avatar, Button } from '@partners-core/components';
import { Link, useRouter } from 'found';
import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Row, Spinner } from 'react-bootstrap';
import { FiUser, FiX } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications/dist/ToastProvider';

import { AuthContext } from '../../../../context/AuthContext';
import EduzzModal from '../../Owner/EduzzModal';
import { NavbarHeader, NavbarBottom } from './styles';

const Header = () => {
  const { user, setUser, logout, hasOutboxes } = useContext(AuthContext);
  const { router } = useRouter();
  const { setShowModal, editWidget } = useWidgetDispatch();
  const state = useWidgetState();
  const { MODAL: modal } = state.widgets;
  const { addToast } = useToasts();
  const [isConfirmed, setIsConfirmed] = useState(user.confirmed_email);
  const [loading, setLoading] = useState(false);

  const URLPath = window.location.pathname;

  useEffect(() => {
    if (!hasOutboxes) {
      setShowModal({ type: 'MODAL', modal: 'outbox', show: false });
    }
  }, [hasOutboxes]);

  const openOutbox = () => {
    setShowModal({ type: 'MODAL', modal: 'outbox', show: true });
  };

  const handleLogout = () => {
    logout();
    router.push('/login');
  };

  const handleSendConfirmEmail = async () => {
    setLoading(true);
    try {
      await sendConfirmEmail();
      setIsConfirmed(true);
      addToast('Um email foi enviado para sua caixa. Verifique e confirme seu email.', {
        appearance: 'snack',
        autoDismiss: true,
        autoDismissTimeout: 6500,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading(false);
  };

  const setShowCurrentModal = (currentModal) => {
    setShowModal({
      type: 'MODAL',
      modal: currentModal,
      show: !modal[currentModal].show,
      stage: 'BASE',
    });
  };

  const selectEduzzOrigin = () => {
    const eduzzOrigin = state.widgets?.ORIGINS?.data.find((o) => o.type === 'EXTERNAL' && o.name === 'Eduzz');

    editWidget({
      type: 'ORIGINS',
      action: 'SELECT_ORIGIN',
      data: {
        ...eduzzOrigin,
        value: eduzzOrigin.id,
        label: eduzzOrigin.name,
        type: eduzzOrigin.type,
        status: eduzzOrigin.status,
      },
    });

    setUser({
      ...user,
      eduzz: {
        ...user.eduzz,
        fill_data: true,
      },
    });

    if (modal.eduzz_partners?.edit) {
      addToast(`Informações atualizadas com sucesso.
        Qualquer dúvida que você tiver, por favor consulte a nossa central de ajuda ou nos acione através do chat em horário comercial.`, {
        appearance: 'snack',
      });
    } else {
      addToast(`Estamos alinhados! Sempre que uma nova oportunidade de negócio chegar para você, um e-mail será enviado, fique ligado.
      Qualquer dúvida que você tiver, por favor consulte a nossa central de ajuda ou nos acione através do chat em horário comercial.`, {
        appearance: 'snack',
      });
    }
  };

  return (
    <>
      <NavbarHeader variant="light" expand="lg" className="p-0 navbar-fixed-top ">
        <div className="container-fluid">
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-none" />
          <Navbar.Collapse className="mr-lg-auto my-0 py-0" id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey="/deal">
              <Nav.Item>
                <Row className="align-items-center h-100 m-0 ">
                  <Link to="/partners/eduzz" className={`c-dark h-100 ${URLPath.indexOf('/partners/eduzz') > -1 ? 'active' : ''}`}>
                    <img alt="" src={LogoEduzz} height="19" className="d-inline-block align-top mh-100 px-3" />
                  </Link>
                </Row>
              </Nav.Item>
            </Nav>

            {user?.owner?.subscription?.status === 'INIT' && (
              <Button variant="info" size="md" onClick={() => router.replace('/welcome')}>
                Continuar cadastro
              </Button>
            )}

            {(hasOutboxes && isSupportUser(user?.email)) && (
              <Button variant="blue" size="sm" className="mr-3" onClick={openOutbox}>
                <Spinner animation="grow" size="xs" className="mr-2" />
                Vinculações em andamento
              </Button>
            )}
            <NavDropdown
              alignRight
              className="nav-avatar"
              title={(
                <Avatar
                  src={user?.avatar}
                  title={`${user.first_name}${user.last_name ? ` ${user.last_name}` : ''}`}
                  className="py-0"
                  rounded
                  size="xs"
                />
              )}
            >

              <Link to="/settings" className="dropdown-item font-weight-regular">
                <FiUser size="15" className="mr-2" />
                Conta
              </Link>
              <NavDropdown.Divider />
              <NavDropdown.Item href="" onClick={handleLogout}>
                Sair
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>

          {(modal.outbox?.show && hasOutboxes && isSupportUser(user?.email)) && (
            <OutboxModal />
          )}

          {modal.eduzz_partners?.show && (
            <EduzzModal
              showModal={modal.eduzz_partners.show}
              setShowModal={() => setShowCurrentModal('eduzz_partners')}
              ownerId={user.owner.id}
              userId={user.id}
              firstName={user.first_name}
              onComplete={selectEduzzOrigin}
              qualification={user.owner?.qualification}
              edit={modal.eduzz_partners?.edit}
            />
          )}
        </div>
      </NavbarHeader>

      {!isConfirmed && (
        <NavbarBottom bg="info">
          <div className="text-center w-100 c-white font-size-md font-weight-normal">
            <span className="">Por favor, confirme seu email.</span>
            {' '}
            Caso não tenha recebido,
            {' '}
            <Button
              variant="white"
              size="sm"
              loading={loading}
              onClick={handleSendConfirmEmail}
            >
              clique aqui
            </Button>
            {' '}
            para re-enviar.
            <Button variant="link" className="p-0 c-white" onClick={() => setIsConfirmed(true)}>
              <FiX className="ml-3" size="20" />
            </Button>
          </div>
        </NavbarBottom>
      )}

    </>
  );
};

export default Header;
