import React, { forwardRef } from 'react';

const Textarea = forwardRef(({ ...rest }, ref) => (
  <textarea
    className="form-control"
    ref={ref}
    {...rest}
  />
));

export default Textarea;
