import { Button } from '@partners-core/components';
import React from 'react';
import { useAccordionToggle } from 'react-bootstrap';

const ButtonToggle = ({ eventKey, variant, block, className, disabled, onSelect, children, tooltip }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, onSelect ? (e) => onSelect(eventKey, e) : undefined);
  const props = { variant, block, className, disabled, tooltip };

  return (
    <Button {...props} onClick={decoratedOnClick}>
      {children}
    </Button>
  );
};

export default ButtonToggle;
