/* eslint-disable camelcase */
import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import yup from '../../../../util/yup';

const schemaValidation = yup.object().shape({
  first_name: yup
    .string()
    .min(2)
    .required(),
  last_name: yup
    .string()
    .min(2)
    .required(),
});

const FullName = ({ id, opened, toggle, updateUser, profile, setProfile = () => {} }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      formRef.current.setErrors({});
      await schemaValidation.validate(data, {
        abortEarly: false,
      });
      const user = {
        id: profile.id,
        data,
      };
      await updateUser(user);
      setLoading(false);
      setProfile({ ...profile, ...data });
      toggle(id);
      addToast('As alterações foram salvas.', {
        autoDismiss: true,
        appearance: 'snack',
        autoDismissTimeout: 6500,
      });
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (opened) {
      formRef.current.setData({ first_name: profile.first_name, last_name: profile.last_name });
    }
  }, [opened]);

  return (
    <Row className="mt-5 pb-4 border-bottom">
      <Col>
        <h5 className="bold">
          Nome completo
        </h5>
        {!opened
          && (
            <div className="font-size-md">
              {profile?.first_name}
              {' '}
              {profile?.last_name}
            </div>
          )}
        {opened
          && (
            <div className="font-size-md">
              <p>Preencha seu nome completo.</p>
              <Unform ref={formRef} onSubmit={handleSubmit}>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Input id="first_name" name="first_name" type="text" placeholder="Nome" autoComplete="given-name" />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Input id="last_name" name="last_name" type="text" placeholder="Sobrenome" autoComplete="family-name" />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="success" type="submit"
                  loading={loading}
                  block
                >
                  Salvar
                </Button>
              </Unform>
            </div>
          )}
      </Col>
      <Col xs="auto">
        <Button variant="link" onClick={() => toggle(id)}>
          {opened ? 'Cancelar' : 'Editar'}
        </Button>
      </Col>
    </Row>

  );
};

export default FullName;
