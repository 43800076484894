/* eslint-disable camelcase */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Route } from 'found';
import _ from 'lodash';
import React from 'react';

dayjs.extend(isBetween);

export const UserRoleRoute = {
  NOT_LOGGED: 'NOT_LOGGED',
  PUBLIC: 'PUBLIC',
  LOGGED: 'LOGGED',
  AGENCY: 'AGENCY',
};

const checkTimePermission = (timePermission) => {
  if (timePermission) {
    const timeStart = timePermission?.start.split(':');
    const start = dayjs().set('hour', timeStart[0]).set('minute', timeStart[1]);
    const timeEnd = timePermission?.end.split(':');
    const end = dayjs().set('hour', timeEnd[0]).set('minute', timeEnd[1]);
    return dayjs().isBetween(start, end);
  }

  return true;
};

class ControlledRoute extends Route {
  render({ Component, props, match }) {
    if (!props) return undefined;
    const roles = match.route.roles ? match.route.roles : [UserRoleRoute.PUBLIC];
    const redirect = match.route.redirect ? match.route.redirect : '/deal';
    const authUser = JSON.parse(localStorage.getItem('AUTH_USER') ? localStorage.getItem('AUTH_USER') : '{}');
    const { owner } = authUser;
    const ownerType = owner?.type || authUser.owner_type;

    if (!_.includes(roles, UserRoleRoute.PUBLIC)) {
      if (!ownerType || ownerType === 'COMPANY') {
        if (!_.includes(roles, UserRoleRoute.NOT_LOGGED)) {
          props.router.replace(redirect);
          return null;
        }
        return (<Component {...props} />);
      }

      if (!_.includes(roles, ownerType)) {
        props.router.replace(redirect);
        return null;
      }
    }

    const { pathname } = props.match.location;

    if (!pathname.includes('/settings') && !pathname.includes('/onboard') && !pathname.includes('/welcome')) {
      if (authUser?.owner?.subscription?.status === 'CANCELED' || authUser?.owner?.subscription?.status === 'UNPAID') {
        props.router.replace('/settings/subscription');
        return null;
      }
      if (authUser?.owner?.subscription?.status === 'INIT') {
        props.router.replace('/deal');
        return null;
      }

      if (authUser?.owner?.subscription?.status === 'TRIALING') {
        const days = dayjs(new Date()).diff(authUser.owner?.subscription?.trial_until, 'days');
        if (days > 0) {
          props.router.replace('/onboard');
          return null;
        }
      }

      if (owner?.information?.time_permission && !authUser.meta?.admin) {
        const isAllowed = checkTimePermission(owner?.information?.time_permission);
        if (!isAllowed) {
          alert('Horário de acesso não permitido');
          props.router.replace('/settings');
          return null;
        }
      }
    }
    return (<Component {...props} />);
  }
}

export default ControlledRoute;
