import confirmEmail from '@/api/mutation/auth/confirm-email';
import inviteSignup from '@/api/mutation/auth/invite-signup';
import Logo from '@/assets/img/brand/logo-eduzz-branco.svg';
import Lines from '@/assets/img/lines.svg';
import CardSignUpStep2 from '@/components/Auth/CardSignUpStep2';
import Skeleton from '@/components/Skeleton';
import { AuthContext } from '@/context/AuthContext';
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Navbar, Container } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

import auth from '../../config/auth';

const AuthBackground = styled.div`
  background-image: url(${Lines});
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: -770px -233px;
`;

const InviteSignup = ({ match, router }) => {
  const [valid, setValid] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState();
  const { email, token } = match.params;
  const { setUser, logout } = useContext(AuthContext);
  const { addToast } = useToasts();

  const handleHome = () => {
    router.replace('/login');
  };

  useEffect(() => {
    async function fetchData() {
      if (auth.isAuthenticated()) {
        logout();
        window.location.reload();
      }

      const result = await confirmEmail({ email, token });
      if (result.confirm_email.success) {
        setUserId(result.confirm_email.user_id);
        setValid(true);
      } else {
        setValid(false);
        addToast('Atenção - o token de validação expirou. Solicite um novo convite.', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
      }
    }
    fetchData();
  }, []);

  const handleSignup = async (data) => {
    setLoading(true);
    const signupData = {
      ...data,
      email,
      user_id: userId,
    };

    const res = await inviteSignup(signupData);
    setLoading(false);
    if (res.invite_signup.success) {
      setUser(res.invite_signup.payload);
      window.location.replace('/deal');
    } else {
      addToast('Algo deu errado no seu cadastro. Tente novamente ou entre em contato com suporte pelo chat ao lado.', {
        appearance: 'snack',
        autoDismiss: true,
        autoDismissTimeout: 6500,
      });
    }
  };

  return (
    <AuthBackground className="min-vh-100 bg-auth">
      <Navbar variant="primary" expand="lg" className="p-0 b-0 mb-6">
        <Container fluid className="px-6 c-white">
          <Navbar.Brand href="#" onClick={handleHome}>
            <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top mh-100 my-4 " />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid="lg">
        <Row className="align-items-center">
          <Col className="bg-white p-4" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} style={{ borderRadius: '10px' }}>
            {valid && <CardSignUpStep2 email={email} onSuccess={handleSignup} loading={loading} setLoading={setLoading} />}
            {!valid && (
              <>
                <Skeleton height="30px" className="mb-3" />
                <Skeleton height="30px" className="mb-3" />
                <Skeleton height="30px" className="mb-3" />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </AuthBackground>
  );
};

export default InviteSignup;
