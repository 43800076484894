import CustomIcons from '@/components/CustomIcons';
import { IconWrapper } from '@partners-core/components';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import updateUser from '../../../api/mutation/user/update-user';
import yup from '../../../util/yup';
import FullName from './Personal/FullName';
import UserField from './Personal/UserField';

const Personal = ({ profile }) => {
  const init = {
    name: false,
    first_name: false,
    email: false,
    phone: false,
    whatsapp: false,
    link: false,
    identifier: false,
  };
  const [opened, setOpened] = useState(init);
  const [profileLocal, setProfileLocal] = useState(null);

  useEffect(() => {
    if (profile) {
      setProfileLocal(profile);
    }
  }, [profile]);

  const toggle = (item) => {
    setOpened({ ...init, [item]: !opened[item] });
  };

  return (
    <Row>
      <Col xs="auto" className="pr-0">
        <IconWrapper className="mr-3" width="44px" height="40px">
          <CustomIcons.UserCustom color="#B0AAFF" size="24" />
        </IconWrapper>
      </Col>
      {profileLocal && (
        <Col>
          <h4 className="bold mt-3">
            Informações Pessoais
          </h4>
          <small>
            Forneça detalhes pessoais e informações de contato
          </small>
          <hr />
          <FullName
            id="name"
            opened={opened.name}
            toggle={toggle}
            updateUser={updateUser}
            profile={profileLocal}
            setProfile={setProfileLocal}
          />
          <UserField
            field="email"
            yupShape={{
              email: yup
                .string()
                .min(2)
                .required(),
            }}
            opened={opened?.email}
            label="Email"
            editable={false}
            description="Informe o nome como sua empresa é conhecida."
            toggle={toggle}
            profile={profileLocal}
          />
          <UserField
            field="phone"
            yupShape={{
              phone: yup
                .string()
                .min(10)
                .required(),
            }}
            opened={opened?.phone}
            label="Telefone"
            editable
            toggle={toggle}
            profile={profileLocal}
            setProfile={setProfileLocal}
            isPhone
          />
        </Col>
      )}
    </Row>
  );
};

export default Personal;
