import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const lineColor = '#424261';

export const TimelineRule = styled.div`
  height: 1px;
  background-color: ${lineColor};
  display: flex;
  flex: 1;
  margin-left: -6px;
`;

export const TimelineContainer = styled.div`
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 6px;

`;

export const ContainerTimeline = styled(Container)`
    .icon-gradient {
      display: none;
      transition-duration: 1s;
    }
    .label-timeline { 
      color: transparent;
      transition: 1.2s;
    }
    .lock {
      fill: #424261 !important;
      transition: 1.2s;
    }
    
  :hover{
    .icon-gradient{
      display: block;
    }
    .icon-disabled {
      display: none;
    }
    .label-timeline { 
      color: transparent;
      color: #bdbdbd;
      }
    .lock {
      fill: #bdbdbd !important;
    }

  }
`;

export const RowIcon = styled(Row)`
  min-height: 30px;
`;

export const ColIcon = styled(Col)`
  flex-direction: 'column';
  min-height: 66px;
`;
