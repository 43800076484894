/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type removeUserMutationVariables = {|
  removeUserId: any,
  newUserId: any,
|};
export type removeUserMutationResponse = {|
  +remove_user: {|
    +success: string,
    +payload: ?string,
    +message: ?string,
  |}
|};
export type removeUserMutation = {|
  variables: removeUserMutationVariables,
  response: removeUserMutationResponse,
|};
*/


/*
mutation removeUserMutation(
  $removeUserId: uuid!
  $newUserId: uuid!
) {
  remove_user(remove_user_id: $removeUserId, new_user_id: $newUserId) {
    success
    payload
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "removeUserId",
    "type": "uuid!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newUserId",
    "type": "uuid!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "new_user_id",
        "variableName": "newUserId"
      },
      {
        "kind": "Variable",
        "name": "remove_user_id",
        "variableName": "removeUserId"
      }
    ],
    "concreteType": "ResultStatusOutput",
    "kind": "LinkedField",
    "name": "remove_user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payload",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeUserMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "removeUserMutation",
    "operationKind": "mutation",
    "text": "mutation removeUserMutation(\n  $removeUserId: uuid!\n  $newUserId: uuid!\n) {\n  remove_user(remove_user_id: $removeUserId, new_user_id: $newUserId) {\n    success\n    payload\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7bba145082c1d840d943a528a92ca4fc';

module.exports = node;
