/* eslint-disable no-underscore-dangle */
const DefaultStyle = {
  container: (base, state) => ({
    ...base,
    border: state.isFocused ? null : null,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    color: '#000',
  }),
  input: (base) => ({
    ...base,
    color: '#000',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#7489a8',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: (base) => ({ display: 'none' }),
  indicatorContainer: (base) => ({ ...base, padding: '2px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: 'white',
    },
    fontSize: '0.7rem',
    borderTop: state.data.__isNew__ && '1px solid silver',
  }),
  menu: (base) => ({
    ...base,
    background: '0% 0% no-repeat padding-box padding-box white',
    boxShadow: 'rgba(175, 175, 175, 0.16) 0px 3px 6px',
    borderRadius: '6px',
    zIndex: 9999,
  }),
  singleValue: (base) => ({
    ...base,
    color: '#000',
  }),
  control: (base) => ({
    ...base,
    minHeight: '45px',
    height: '100%',
    background: '#f2edff',
    borderColor: '#f2edff',
    '&:hover': {
      borderColor: '#f2edff',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    background: '#f2edff',
    fontSize: '0.7rem',
    padding: '6px 10px',
    minWidth: '60px',
  }),
};

const PurpleShadowStyle = {
  container: (base, state) => ({
    ...base,
    borderRadius: '6px',
    border: state.isFocused ? null : null,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    color: '#000',
  }),
  input: (base) => ({
    ...base,
    color: '#000',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#7489a8',
  }),
  indicatorSeparator: (base) => ({ display: 'none' }),
  indicatorContainer: (base) => ({ ...base, padding: '2px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: '#FAFAFF',
    },
    fontSize: '0.7rem',
    // color: state.data.__isNew__ && 'purple',
    borderTop: state.data.__isNew__ && '1px solid silver',
  }),
  menu: (base) => ({
    ...base,
    background: '0% 0% no-repeat padding-box padding-box white',
    boxShadow: 'rgba(175, 175, 175, 0.16) 0px 3px 6px',
    borderRadius: '6px',
    zIndex: 9999,
  }),
  singleValue: (base) => ({
    ...base,
    color: '#000',
  }),
  control: (base) => ({
    ...base,
    minHeight: '44px',
    height: '100%',
    background: '#FAFAFF',
    borderColor: 'transparent',
    minWidth: '120px',
    '&:hover': {
      borderColor: '#eff3ff',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    background: '#FAFAFF',
    fontSize: '0.7rem',
    padding: '6px 10px',
    minWidth: '60px',
  }),
};

const FormStyle = {
  container: (base, state) => ({
    ...base,
    border: state.isFocused ? null : null,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    color: '#000',
  }),
  input: (base) => ({
    ...base,
    color: '#000',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#7489a8',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: (base) => ({ display: 'none' }),
  clearIndicator: (base) => ({ ...base, padding: '2px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: 'white',
    },
    fontSize: '0.74375rem',
    // color: state.data.__isNew__ && "purple",
    borderTop: state.data.__isNew__ && '1px solid silver',
  }),
  menu: (base) => ({
    ...base,
    background: '0% 0% no-repeat padding-box padding-box white',
    boxShadow: 'rgba(175, 175, 175, 0.16) 0px 3px 6px',
    borderRadius: '6px',
    zIndex: 9999,
  }),
  singleValue: (base) => ({
    ...base,
    color: '#000',
  }),
  control: (base) => ({
    ...base,
    minHeight: '45px',
    height: '100%',
    background: '#f2edff',
    borderColor: '#f2edff',
    '&:hover': {
      borderColor: '#f2edff',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    background: '#f2edff',
    fontSize: '0.74375rem',
    padding: '8px 10px',
    minWidth: '60px',
  }),
};

const TranslucentStyle = {
  container: (base, state) => ({
    ...base,
    border: state.isFocused ? null : null,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    color: '#000',
  }),
  input: (base) => ({
    ...base,
    color: '#000',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#0003',
    fontWeight: '500',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: (base) => ({ display: 'none' }),
  clearIndicator: (base) => ({ ...base, padding: '2px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: 'white',
    },
    fontSize: '0.74375rem',
    // color: state.data.__isNew__ && "purple",
    borderTop: state.data.__isNew__ && '1px solid silver',
  }),
  menu: (base) => ({
    ...base,
    background: '0% 0% no-repeat padding-box padding-box white',
    boxShadow: 'rgba(175, 175, 175, 0.16) 0px 3px 6px',
    borderRadius: '6px',
    zIndex: 9999,
  }),
  singleValue: (base) => ({
    ...base,
    color: '#2f2c5d',
    fontWeight: '500',
  }),
  control: (base) => ({
    ...base,
    minHeight: '42px',
    height: '100%',
    background: '#f7faff',
    border: '0',
    '&:hover': {
      borderColor: '#f7faff',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    background: '#f7faff',
    fontSize: '0.74375rem',
    padding: '8px 10px',
    minWidth: '60px',
    borderRadius: '6px 0px 0px 6px',
  }),
};

const TransparentStyle = {
  container: (base, state) => ({
    ...base,
    border: state.isFocused ? null : null,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    color: '#000',
  }),
  input: (base) => ({
    ...base,
    color: '#000',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#2f2c5d !important',
    fontWeight: '500',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: (base) => ({ display: 'none' }),
  indicatorContainer: (base) => ({ ...base, color: '2e2d5d', padding: '1px' }),
  clearIndicator: (base) => ({ ...base, padding: '2px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: 'white',
    },
    fontSize: '0.74375rem',
    // color: state.data.__isNew__ && "purple",
    borderTop: state.data.__isNew__ && '1px solid silver',
  }),
  menu: (base) => ({
    ...base,
    background: '0% 0% no-repeat padding-box padding-box white',
    boxShadow: 'rgba(175, 175, 175, 0.16) 0px 3px 6px',
    borderRadius: '6px',
    zIndex: 9999,
  }),
  singleValue: (base) => ({
    ...base,
    color: '#2f2c5d',
    fontWeight: '400',
  }),
  control: (base) => ({
    ...base,
    minHeight: '42px',
    height: '100%',
    background: 'white',
    border: '0',
    '&:hover': {
      borderColor: 'white',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    background: 'white',
    fontSize: '0.74375rem',
    padding: '8px 10px',
    minWidth: '60px',
    borderRadius: '6px 0px 0px 6px',
  }),
};

const DarkStyle = {
  container: (base, state) => ({
    ...base,
    background: '#23224F !important',
    border: state.isFocused ? null : null,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    color: '#000',
  }),
  input: (base) => ({
    ...base,
    color: '#000',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#BEBBBB !important',
    fontWeight: '500',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: (base) => ({ display: 'none' }),
  indicatorContainer: (base) => ({ ...base, color: '2e2d5d', padding: '1px' }),
  clearIndicator: (base) => ({ ...base, padding: '2px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: 'white',
    },
    fontSize: '0.74375rem',
    // color: state.data.__isNew__ && "purple",
    borderTop: state.data.__isNew__ && '1px solid silver',
  }),
  menu: (base) => ({
    ...base,
    background: '0% 0% no-repeat padding-box padding-box white',
    boxShadow: 'rgba(175, 175, 175, 0.16) 0px 3px 6px',
    borderRadius: '6px',
    zIndex: 9999,
  }),
  singleValue: (base) => ({
    ...base,
    color: 'white',
    fontWeight: '400',
  }),
  control: (base) => ({
    ...base,
    minHeight: '42px',
    height: '100%',
    background: '#23224F !important',
    border: '0',
    '&:hover': {
      borderColor: 'white',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    background: '#23224F',
    fontSize: '0.74375rem',
    padding: '8px 10px',
    minWidth: '60px',
    borderRadius: '6px 0px 0px 6px',
  }),
};

export {
  DefaultStyle,
  FormStyle,
  TranslucentStyle,
  TransparentStyle,
  PurpleShadowStyle,
  DarkStyle,
};
