import PageNavigation from '@/modules/partners/components/PageNavigation';
import { Typography } from '@partners-core/components';
import React, { useState } from 'react';
import { Modal, Row, Card } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import { TableProduct } from './TableProduct';

const TableProductModal = ({ showModal, setShowModal, cubejsApi, filterDateTable, filterTable, customerSelected, countProducts, isSupportUser }) => {
  const [filter, setFilter] = useState({ offset: 0, limit: 10 });
  return (
    <Modal show={showModal} onHide={setShowModal} centered size={isMobile ? 'xxl' : 'xl'}>
      {isMobile
      && (
        <Modal.Header closeButton>
          <Modal.Title>{customerSelected['Customer.name']}</Modal.Title>
        </Modal.Header>
      )}

      <Card className={`bg-white ${isMobile ? 'px-3 pt-3' : 'pt-6 px-6'}`} style={{
        overflow: 'auto',
        minHeight: '500px',
      }}
      >
        {!isMobile
        && (
          <>
            <Typography className=" c-dark" variant="h4">
              {customerSelected['Customer.name']}
            </Typography>
            <Row>
              <div className="border-separator pt-2 pb-4" />
            </Row>
          </>
        )}

        <TableProduct
          filterTable={filterTable}
          filterDateTable={filterDateTable}
          cubejsApi={cubejsApi}
          filter={filter}
          isSupportUser={isSupportUser}
        />
        <Row className="justify-content-end mx-0">
          <PageNavigation
            filter={filter}
            setFilter={setFilter}
            countProject={countProducts}
          />
        </Row>
      </Card>
    </Modal>
  );
};
export default TableProductModal;
