import { graphql, requestSubscription } from 'relay-runtime';

import environment from '../../../config/environment';

const subscription = graphql`
  subscription outboxSubscription {
    outbox(
      where: {
        name: {
          _eq: "customer-bind"
        },
        status_enum: {
          _in: [SENT, RUNNING, ERROR]
        }
      }
    ) {
      id
      progress
      meta
      created_at
      status {
        label
        value
      }
    } 
  }
`;

export default function outboxSubscription(onNext, onError, onCompleted) {
  return requestSubscription(
    environment,
    {
      subscription,
      variables: {},
      onNext,
      onError,
      onCompleted,
    },
  );
}
