/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const Shinobi = forwardRef(({ gradient = false, color = 'none', size = 75, fill = 'url(#paint0_linear_1300_428)', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75 75"
    fill="none"
    stroke={color}
    x="0px"
    y="0px"
    {...rest}
  >
    <path d="M71.4,6c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1
      L60,14.8c-6-5.1-13.9-8.2-22.5-8.2C28.9,6.6,21,9.7,15,14.8L4.3,5.9c-0.1,0-0.1-0.1-0.2-0.1C4,5.7,3.9,5.7,3.8,5.8c0,0,0,0-0.1,0
      c0,0-0.1,0.1-0.1,0.1C3.6,5.9,3.6,6,3.6,6c0,0-3.5,13.4,0.3,27.2c-0.5,2.2-0.7,4.4-0.7,6.7c0,18.4,15.4,33.4,34.4,33.4
      c19,0,34.4-15,34.4-33.4c0-2.3-0.2-4.5-0.7-6.7C74.9,19.4,71.4,6,71.4,6z M59.3,19.4c0.3,3.6,0.3,9.6-1.2,15.8
      c-1.9,7.6-6.6,12.5-10.9,15.6c3.5-3.6,6.4-9,6.4-9c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1-0.1-0.2-0.1-0.3c0-0.1-3.2-4.6-8.1-6.4
      c-0.1-0.1-0.4-0.1-0.6-0.1c-0.4,0.1-0.5,0.5-0.5,0.6c-0.1,0.2-3.9,11.4-3.9,11.4l0.5-11.8c0-0.2,0-0.6,0-0.8
      c-0.1-0.3-0.3-0.6-0.4-0.7c-0.1-0.1-2.1-2.1-2.2-2.1c-0.1-0.1-0.3-0.3-0.6-0.3c-0.2,0-0.5,0.2-0.6,0.3c-0.1,0.1-2.1,2-2.2,2.1
      c-0.1,0.1-0.3,0.4-0.4,0.7c-0.1,0.3-0.1,0.7,0,0.8l0.5,11.8c0,0-3.8-11.2-3.9-11.4c0-0.1-0.1-0.5-0.5-0.6c-0.2,0-0.5,0.1-0.6,0.1
      c-4.9,1.8-8.1,6.3-8.1,6.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.4,0.1,0.6c0,0,2.9,5.5,6.4,9c-4.4-3.1-9-8-10.9-15.6
      C15.4,29,15.4,23,15.7,19.4c5.5-5.5,13.3-9,21.8-9C46,10.4,53.8,13.9,59.3,19.4z M37.5,69.6c-16.2,0-29.4-12.3-30.4-27.8
      c1.8,3.4,4.2,6.8,7.5,9.7c10.7,9.9,22.9,12.8,22.9,12.8s12.2-3,22.9-12.8c3.2-2.9,5.6-6.3,7.5-9.7C66.9,57.3,53.7,69.6,37.5,69.6z" fill={fill}
    />
    { gradient
    && (
      <linearGradient id="paint0_linear_1300_428" x1="22" y1="0" x2="22" y2="43" gradientUnits="userSpaceOnUse">
        <stop offset="0.546875" stopColor="#53BFF9" />
        <stop offset="1" stopColor="#415CBB" />
      </linearGradient>
    )}
  </svg>
));

export default Shinobi;
