import { useField } from '@unform/core';
import React, { useRef, useEffect } from 'react';

import Select from '../Select/Select';
import { FormStyle } from '../Select/SelectStyles';

const FormSelect = ({ name, options, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <Select
        defaultValue={
          defaultValue && options.find((option) => option.value === defaultValue)
        }
        ref={selectRef}
        classNamePrefix="react-select"
        placeholder="Selecione..."
        options={options}
        styles={FormStyle}
        {...rest}
      />
      {error && <span className="text-danger font-size-xs ml-2 font-weight-bold">{error}</span>}
    </>
  );
};
export default FormSelect;
