import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import { Link } from 'found';
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import updatePassword from '../../../../api/mutation/auth/update-password';
import yup from '../../../../util/yup';

const schemaValidation = yup.object().shape({
  old_password: yup
    .string()
    .min(6)
    .required(),
  password: yup
    .string()
    .min(6)
    .required()
    .notOneOf([yup.ref('old_password'), null], 'A senha nova não pode ser igual a antiga.'),
  new_password: yup
    .string()
    .min(6)
    .required()
    .oneOf([yup.ref('password'), null], 'A confirmação de senha não confere.'),
});
const Password = ({ id, opened, toggle, user }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      formRef.current.setErrors({});
      await schemaValidation.validate(data, {
        abortEarly: false,
      });
      const update = {
        email: user.email,
        old_password: data.old_password,
        new_password: data.new_password,
      };
      await updatePassword(update);
      setLoading(false);
      toggle(id);
      addToast('Sua senha foi redefinida com sucesso.', {
        autoDismiss: true,
        appearance: 'snack',
        autoDismissTimeout: 6500,
      });
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        validationErrors.old_password = err?.source?.errors[0]?.message;
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (opened) {
      formRef.current.setData({ first_name: user.first_name, last_name: user.last_name });
    }
  }, [opened]);

  return (
    <Row className="mt-5 pb-4 border-bottom">
      <Col>
        <h5 className="bold">
          Senha
        </h5>
        {!opened
          && (
            <div className="font-size-md">
              *******
            </div>
          )}
        {opened
          && (
            <div className="font-size-md">
              <p>A sua senha deve conter no mínimo 6 dígitos.</p>
              <Unform ref={formRef} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Input id="old_password" name="old_password" type="password" placeholder="Senha atual" />
                  <div className="mt-2"><Link to="/forgot_password" className="c-primary font-size-sm">Precisa de uma nova senha?</Link></div>
                </Form.Group>
                <Form.Group>
                  <Form.Input id="password" name="password" type="password" placeholder="Senha nova" />
                </Form.Group>
                <Form.Group>
                  <Form.Input id="new_password" name="new_password" type="password" placeholder="Confirmar senha nova" />
                </Form.Group>
                <Button variant="success" type="submit" loading={loading} block>
                  Salvar
                </Button>
              </Unform>
            </div>
          )}
      </Col>
      <Col xs="auto">
        <Button variant="link" onClick={() => toggle(id)}>
          {opened ? 'Cancelar' : 'Editar'}
        </Button>
      </Col>
    </Row>

  );
};

export default Password;
