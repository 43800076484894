/* eslint-disable camelcase */
import { ReactComponent as BgSelo } from '@/assets/img/bg-selo.svg';
import { ReactComponent as IconMission } from '@/assets/img/icons/icon-mission.svg';
import LogoOrbita from '@/assets/img/icons/logo-orbita-branco.png';
import { ReactComponent as ImgGrupoNegocios01 } from '@/assets/img/img-grupo-negocios-01.svg';
import { ReactComponent as ImgGrupoNegocios02 } from '@/assets/img/img-grupo-negocios-02.svg';
import Skeleton from '@/components/Skeleton';
import { AuthContext } from '@/context/AuthContext';
import ButtonToggle from '@/modules/partners/components/ButtonToggle';
import ButtonToggleBenefit from '@/modules/partners/components/ButtonToggleBenefit';
import { ChartGmv } from '@/modules/partners/components/ChartGmv';
import ChevronUpDown from '@/modules/partners/components/ChevronUpDown';
import FooterDashboard from '@/modules/partners/components/FooterDashboard';
import BonusIcon from '@/modules/partners/components/icons/bonus-icon.png';
import { RONIN, SHINOBI, SHOGUN, DAIMYO, DRAGAO, ROSHI } from '@/modules/partners/components/icons/LevelIcons';
import MissoesIcon from '@/modules/partners/components/icons/missoes-icon.png';
import PremiacoesIcon from '@/modules/partners/components/icons/premiacoes-icon.png';
import PageNavigation from '@/modules/partners/components/PageNavigation';
import ProductBindModal from '@/modules/partners/components/Support/ProductBindModal';
import SupportModal from '@/modules/partners/components/Support/SupportModal';
import { TableCustomer } from '@/modules/partners/components/TableCustomer';
import TableProductModal from '@/modules/partners/components/TableProductModal';
import Timeline from '@/modules/partners/components/Timeline';
import LevelsModal from '@/modules/partners/components/Timeline/LevelsModal';
import authCubeToken from '@/modules/partners/services/auth-cube-token';
import usePartners from '@/modules/partners/store';
import { isSupportUser } from '@/util/utils';
import cubejs from '@cubejs-client/core';
import {
  Typography, DatePicker, TransparentStyle,
  CalendarContainerCustom, PurpleShadowStyle, Button,
} from '@partners-core/components';
import partners from '@partners-core/partners-js';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import { uniq, without } from 'lodash';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Row, Col, Card, Accordion, InputGroup, Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { BiTrendingUp, BiTargetLock } from 'react-icons/bi';
import { FiCopy, FiExternalLink, FiCalendar, FiRefreshCw, FiHelpCircle } from 'react-icons/fi';
import { Tooltip } from 'react-tippy';
import { useToasts } from 'react-toast-notifications';

import {
  IconBlur, SliderBlur, LevelSlider, DatepickerContainer, AccordionBenefit, ScrollableSelect,
  BackgroundBannerTop, CardBenefit, CardLevel, AccordionMissions, IconBlurMobile,
} from './styles';

const icons = {
  RONIN,
  SHINOBI,
  DAIMYO,
  DRAGAO,
  ROSHI,
  SHOGUN,
};

const getIcon = ({ type, size, fill, className, style, gradient }) => {
  if (type) {
    const CompIcon = icons[type];
    return (
      <CompIcon size={size} fill={fill} className={className} style={style} gradient={gradient} />
    );
  }
  return '';
};

const DashboardPartners = () => {
  const { fetchPartnersData, fetchLoadPerksData } = usePartners();
  const { user, setUser } = useContext(AuthContext);
  const { addToast } = useToasts();
  const [displayInfo, setDisplayInfo] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [showProductBindModal, setShowProductBindModal] = useState(false);
  const [showLevelsModal, setShowLevelsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState([]);
  const [levels, setLevels] = useState([]);
  const [perks, setPerks] = useState(null);
  const [myLevel, setMyLevel] = useState(null);
  const [support, setSupport] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [customersTable, setCustomersTable] = useState(null);
  const [products, setProducts] = useState(null);
  const [customerType, setCustomerType] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterCustomers, setFilterCustomers] = useState(null);
  const [filterProducts, setFilterProducts] = useState(null);
  const [filterStartDateTable, setFilterStartDateTable] = useState(null);
  const [filterEndDateTable, setFilterEndDateTable] = useState(null);
  const [filterCustomersTable, setFilterCustomersTable] = useState(null);
  const [filterCustomerType, setFilterCustomerType] = useState(null);
  const [labels, setLabels] = useState(null);
  const [showProductFilter, setShowProductFilter] = useState(false);
  const [showTableProductModal, setShowTableProductModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [transactionsTotal, setTransactionsTotal] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [filter, setFilter] = useState({ offset: 0, limit: 10 });
  const [countCustomers, setCountCustomers] = useState(0);
  const [levelSelected, setLevelSelected] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [customerSelected, setCustomerSelected] = useState(true);
  const [countProducts, setCountProducts] = useState(null);
  const [totalChart, setTotalChart] = useState(null);
  const [totalTable, setTotalTable] = useState(null);
  const [chartBy, setChartBy] = useState('month');
  const [relayChart, setRelayChart] = useState(null);
  const [filterDateChart, setFilterDateChart] = useState(() => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear() - 1, today.getMonth(), 1);
    return [dayjs(firstDay).format('YYYY-MM-DD'), dayjs(today).format('YYYY-MM-DD')];
  });
  const [filterDateTable, setFilterDateTable] = useState(() => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear() - 1, today.getMonth(), 1);
    return [dayjs(firstDay).format('YYYY-MM-DD'), dayjs(today).format('YYYY-MM-DD')];
  });
  const [filterChart, setFilterChart] = useState([]);
  const [filterTable, setFilterTable] = useState([
    //   {
    //   member: 'Transaction.paidAt',
    //   operator: 'beforeDate',
    //   values: ['OwnerCustomer.inactivatedAt'],
    // }
  ]);
  const [filterTableProducts, setFilterTableProducts] = useState([]);
  const [cubejsApi, setCubeJsApi] = useState(null);
  const [keyChart, setKeyChart] = useState(1);
  const [keyTable, setKeyTable] = useState(1);
  const benefitRef = useRef(null);
  const scrollToElement = () => benefitRef.current.scrollIntoView();
  const clientRef = useRef(null);
  const productRef = useRef(null);
  const byRef = useRef(null);

  const handleChangeSelect = (values, setValue, ref) => {
    ref.current.select.blur();
    setValue(values);
    setTimeout(() => {
      ref.current.select.focus();
    }, 350);
  };

  const fetchData = async () => {
    try {
      const partnersData = await fetchPartnersData();
      setPartnerId(partnersData.responseOwner.partner_id);
      loadPerks(partnersData.responseOwner.id, partnersData.responseOwner.level_id, partnersData.responseTotalTransactions?.sum?.value / 100);
      setOwnerId(partnersData.responseOwner.id);
      setSupport(partnersData.newSupports);
      setCustomers(partnersData.customersMap);
      setCustomersTable(partnersData.customersMap);
      setCountCustomers(partnersData.customersMap.length + 1);
      setCustomerType(partnersData.customersTypeMap);
      setTransactionsTotal(partnersData.responseTotalTransactions?.sum?.value / 100);
      setTotalChart(partnersData.responseTotalTransactions?.sum?.value / 100);
      setTotalTable(partnersData.responseTotalTransactions?.sum?.value / 100);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const loadPerks = async (owner_id, levelId, totalTransactions) => {
    const loadPerksData = await fetchLoadPerksData({ owner_id, levelId, totalTransactions });
    const newLabels = {
      [loadPerksData?.newMyLevel?.gmv_min]: formatValue(loadPerksData.newMyLevel?.gmv_min),
      [loadPerksData?.newMyLevel?.next_level?.gmv_min]: formatValue(loadPerksData?.newMyLevel?.next_level?.gmv_min),
    };
    setLevels(loadPerksData?.responseLevels);
    setMyLevel({ ...loadPerksData?.newMyLevel, order: loadPerksData?.indexMyLevel });
    setLabels(newLabels);
    setPerks(loadPerksData?.responsePerks);
    setCollapsed(uniq([...collapsed, loadPerksData?.newMyLevel?.name]));
    setLoading(false);
  };

  const granularityOptions = [
    { value: 'month', label: 'Mês' },
    { value: 'day', label: 'Dia' },
  ];

  const loadCubeToken = async () => {
    let token = '';
    if (user?.owner?.cube_token && new Date() < new Date(user?.owner?.cube_token_expires_at)) {
      token = user.owner.cube_token;
    } else {
      const resp = await authCubeToken();
      token = resp.auth_cube_token.payload.token;
      setUser({
        ...user,
        owner: {
          ...user.owner,
          cube_token: token,
          cube_token_expires_at: resp.auth_cube_token.payload.expires_at,
        },
      });
    }
    const newCubeJsApi = cubejs(
      token,
      { apiUrl: process.env.REACT_APP_CUBE_API_URL },
    );
    setCubeJsApi(newCubeJsApi);
  };

  useEffect(() => {
    fetchData();
    loadCubeToken();
  }, []);

  useEffect(() => {
    updateTotalChart();
  }, [filterCustomers, filterProducts, filterDateChart]);

  useEffect(() => {
    updateTotalTable();
  }, [filterCustomersTable, filterCustomerType, filterDateTable]);

  useEffect(() => {
    if (filterCustomers?.length > 0 && filterProducts?.length > 0) {
      const newFilterChart = [
        {
          member: 'Customer.id',
          operator: 'equals',
          values: filterCustomers.map((c) => c.value),
        },
        {
          member: 'OwnerProduct.productId',
          operator: 'equals',
          values: filterProducts.map((p) => p.value),
        },
      ];
      setFilterChart(newFilterChart);
    } else if (filterCustomers?.length > 0) {
      const newFilterChart = [
        {
          member: 'Customer.id',
          operator: 'equals',
          values: filterCustomers.map((c) => c.value),
        }];
      setFilterChart(newFilterChart);
    } else {
      setFilterChart([]);
    }
  }, [filterCustomers, filterProducts]);

  useEffect(() => {
    if (filterCustomersTable?.length > 0) {
      const newFilterTable = [
        {
          member: 'Customer.id',
          operator: 'equals',
          values: filterCustomersTable.map((c) => c.value),
        }];
      const newCustomerType = customerType.filter((t) => filterCustomersTable.map((c) => c.type).includes(t.value));
      setCustomerType(newCustomerType);
      setFilterTable(newFilterTable);
    } else if (filterCustomerType?.length > 0) {
      const newFilterTable = [
        {
          member: 'CustomerType.name',
          operator: 'equals',
          values: filterCustomerType?.map((c) => c.value),
        }];

      const newCustomers = customersTable.filter((c) => filterCustomerType.map((t) => t.value).includes(c.type));

      setCustomersTable(newCustomers);
      setFilterTable(newFilterTable);
    } else {
      setCustomersTable(customers);
      setFilterTable([]);
    }
  }, [filterCustomersTable, filterCustomerType]);

  const onSelectAccordion = (e) => {
    if (collapsed.includes(e)) {
      setCollapsed(without(collapsed, e));
    } else {
      setCollapsed(uniq([...collapsed, e]));
    }
  };

  const onChangeFilterDate = (dates) => {
    const [start, end] = dates;
    if (start) start.setHours(0, 0, 0, 0);
    if (end) end.setHours(23, 59, 59, 999);
    const newStart = dayjs(start).add(3, 'hour').format('YYYY-MM-DD HH:mm:ss');
    let newEnd = dayjs(end).add(3, 'hour').format('YYYY-MM-DD HH:mm:ss');
    if (!end) { newEnd = dayjs(start).add(3, 'hour').format('YYYY-MM-DD HH:mm:ss'); }
    setFilterDateChart([newStart, newEnd]);
    setFilterStartDate(start);
    setFilterEndDate(end);
  };

  const onChangeFilterDateTable = (dates) => {
    const [start, end] = dates;
    if (start) start.setHours(0, 0, 0, 0);
    if (end) end.setHours(23, 59, 59, 999);
    const newStart = dayjs(start).add(3, 'hour').format('YYYY-MM-DD HH:mm:ss');
    let newEnd = dayjs(end).add(3, 'hour').format('YYYY-MM-DD HH:mm:ss');
    if (!end) { newEnd = dayjs(start).add(3, 'hour').format('YYYY-MM-DD HH:mm:ss'); }
    setFilterDateTable([newStart, newEnd]);
    setFilterStartDateTable(start);
    setFilterEndDateTable(end);
  };

  const formatValue = (value) => {
    if (value > 999 && value < 999000) {
      return `${value / 1000}K`;
    }
    if (value > 999000) {
      return `${value / 1000000}M`;
    }
    return value;
  };

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    addToast('Copiado com sucesso.', {
      appearance: 'snack',
      autoDismiss: true,
      autoDismissTimeout: 6500,
    });
  };

  const handleFilterProducts = async (customersId) => {
    const responseProducts = await partners.product.find({
      where: { customer_id: { _in: customersId } },
      select: {
        id: true,
        name: {
          alias: 'label',
        },
      },
    });
    const productsMap = responseProducts?.map((product) => ({ value: product.id, label: product.label }));
    setProducts(productsMap);
    setShowProductFilter(true);
    setLoadingProducts(false);
  };

  const handleCustomerFilter = (event) => {
    if (!event) {
      setFilterCustomers(null);
      setFilterProducts(null);
      setShowProductFilter(false);
    } else {
      const customersId = [];
      event.map((c) => customersId.push(c.value));
      handleFilterProducts(customersId);
      setFilterCustomers(event);
    }
  };

  const onSuccessProductBind = async () => {
    const responseCustomers = await partners.customer.find({
      select: {
        id: true,
        name: {
          alias: 'label',
        },
      },
    });
    const responseTotalTransactions = await partners.transaction.aggregate({
      where: {
        status_enum: { _in: ['PURCHASE_APPROVED', 'PURCHASE_COMPLETE', 'PURCHASE_REFUNDED'] },
        paid_at: { _gte: `${filterDateTable[0]}`, _lte: `${filterDateTable[1]}` },
      },
      aggregate: {
        sum: {
          value: true,
        },
      },
      distinct_on: 'external_id',
      options: {
        cached: true,
      },
    });
    const customersMap = responseCustomers?.map((customer) => ({ value: customer.id, label: customer.label, type: customer?.type?.name }));
    setCustomers(customersMap);
    setCustomersTable(customersMap);
    setTransactionsTotal(responseTotalTransactions?.sum?.value / 100);
    setTotalChart(responseTotalTransactions?.sum?.value / 100);
    setTotalTable(responseTotalTransactions?.sum?.value / 100);
    setFilterCustomers(null);
  };

  const handleOnClickCustomer = async (customer) => {
    setCustomerSelected(customer);
    const responseCountProducts = await partners.product.aggregate({
      where: {
        customer_id: { _eq: customer['Customer.id'] },
        transactions: {
          status_enum: { _in: ['PURCHASE_APPROVED', 'PURCHASE_COMPLETE', 'PURCHASE_REFUNDED'] },
          paid_at: { _gte: `${filterDateTable[0]}`, _lte: `${filterDateTable[1]}` },
        },
      },
      aggregate: {
        count: true,
      },
      distinct_on: 'external_id',
    });
    setCountProducts(responseCountProducts?.count);
    const newFilterTableProducts = [
      {
        member: 'Customer.id',
        operator: 'equals',
        values: [customer['Customer.id']],
      }];
    setFilterTableProducts(newFilterTableProducts);

    setShowTableProductModal(true);
  };

  const updateTotalChart = async () => {
    const newDateFilter = { _gte: `${filterDateChart[0]}`, _lte: `${filterDateChart[1]}` };
    const newProductFilter = filterProducts?.length > 0 ? { _in: filterProducts.map((p) => `${p.value}`) } : '';
    const newCustomerFilter = filterCustomers?.length > 0 ? { _in: filterCustomers.map((c) => `${c.value}`) } : '';
    const statusFilter = { _in: ['PURCHASE_APPROVED', 'PURCHASE_COMPLETE', 'PURCHASE_REFUNDED'] };
    let totalChartFilter;

    if (filterCustomers?.length > 0 && filterProducts?.length > 0) {
      totalChartFilter = {
        status_enum: statusFilter,
        paid_at: newDateFilter,
        product: { customer_id: newCustomerFilter },
        product_id: newProductFilter,
      };
    } else if (filterCustomers?.length > 0) {
      totalChartFilter = {
        status_enum: statusFilter,
        product: { customer_id: newCustomerFilter },
        paid_at: newDateFilter,
      };
    } else {
      totalChartFilter = {
        status_enum: statusFilter,
        paid_at: newDateFilter,
      };
    }
    const responseTotalChart = await partners.transaction.aggregate({
      where: totalChartFilter,
      distinct_on: 'external_id',
      aggregate: {
        sum: {
          value: true,
        },
      },
      options: {
        cached: true,
      },
    });
    setTotalChart(responseTotalChart?.sum?.value / 100);
  };
  const updateTotalTable = async () => {
    const newDateFilter = { _gte: filterDateTable[0], _lte: filterDateTable[1] };
    const newCustomerFilter = filterCustomersTable?.length > 0 ? { _in: filterCustomersTable.map((c) => `${c.value}`) } : '';
    const statusFilter = { _in: ['PURCHASE_APPROVED', 'PURCHASE_COMPLETE', 'PURCHASE_REFUNDED'] };

    let totalTableFilter;

    if (filterCustomersTable?.length > 0) {
      totalTableFilter = {
        status_enum: statusFilter,
        product: { customer_id: newCustomerFilter },
        paid_at: newDateFilter,
      };
    } else if (filterCustomerType?.length > 0) {
      const newCustomerTypeFilter = { customer: { type_enum: { _in: filterCustomerType.map((t) => `${t.value}`) } } };
      totalTableFilter = {
        status_enum: statusFilter,
        product: newCustomerTypeFilter,
        paid_at: newDateFilter,
      };
    } else {
      totalTableFilter = {
        status_enum: statusFilter,
        paid_at: newDateFilter,
      };
    }
    const responseTotalTable = await partners.transaction.aggregate({
      where: totalTableFilter,
      distinct_on: 'external_id',
      aggregate: {
        sum: {
          value: true,
        },
      },
      options: {
        cached: true,
      },
    });
    setTotalTable(responseTotalTable?.sum?.value / 100);
  };

  const handleRefreshChart = () => {
    setKeyChart(keyChart + 1);
  };

  const handleRefreshTable = () => {
    setKeyTable(keyTable + 1);
  };

  const handleEditing = () => {
    setShowSupportModal(true);
  };

  return (
    <Container fluid>
      <BackgroundBannerTop className={isMobile ? 'banner-mobile' : 'banner-browser'}>
        <Col>
          <Row className="pt-5 px-4 mt-2">
            <Col>
              <Row className={isMobile ? 'justify-content-center pb-3' : 'pl-7 pb-3'}>
                <Typography className="c-white font-weight-bold pl-3 mb-0" variant="h1">
                  {`Bem-vindo(a), ${user?.first_name} ${user?.last_name}`}
                </Typography>
              </Row>
            </Col>
            <Col className="text-right pr-7">
              <Row className={isMobile ? 'justify-content-center align-items-center py-3' : 'justify-content-end align-items-center py-3'}>
                <Typography className="c-white mb-0" variant="h6">
                  SEU ID DE PARCEIRO:
                </Typography>
                <Typography className="c-white mb-0 pl-1 font-weight-bold " variant="h6">
                  {partnerId}
                </Typography>
                <FiCopy size={10} className="c-white ml-2 pointer" onClick={() => copyToClipboard(partnerId)} />
                <Typography className="c-white mb-0 px-2" variant="h6">
                  |
                </Typography>
                <Typography className="c-white pl-1 mb-0 pointer" variant="h6" onClick={() => window.open('https://orbita.eduzz.com/', '_blank')}>
                  Ir para
                  {' '}
                  <img height="9px" src={LogoOrbita} alt="orbita" className="mb-1" />
                  <FiExternalLink size={10} className="c-white ml-2" />
                </Typography>
                {isSupportUser(user?.email) && (
                  <Button variant="blue" size="sm" className="mr-2 ml-3" onClick={() => setShowProductBindModal(true)}>
                    Suporte: Vincular produto
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="border-separator-purple pb-4 pt-2 px-7" />
          </Row>

          <Row className="pl-4">
            <Col xs="12" sm="2" className="pr-3 pl-6 text-center">
              {isMobile
                ? (
                  <Row className="align-items-start justify-content-center">
                    <BgSelo width={180} height={180} style={{ position: 'relative', marginTop: '-40px', marginLeft: '-40px' }} />
                    {loading
                      ? <Skeleton variant="blue" height={20} block />
                      : getIcon({
                        type: myLevel.name,
                        fill: '#FFCB00',
                        size: 50,
                        className: 'mt-4',
                        style: { position: 'absolute', marginLeft: '-40px' },
                      })}
                  </Row>
                )
                : (
                  <Row className="align-items-start justify-content-center">
                    <BgSelo width={180} height={180} style={{ position: 'relative', marginTop: '-40px' }} />
                    {loading
                      ? <Skeleton variant="blue" height={20} block />
                      : getIcon({ type: myLevel.name, fill: '#FFCB00', size: 50, className: 'mt-4', style: { position: 'absolute' } })}
                  </Row>
                )}
            </Col>
            <Col xs="12" sm="4" className="pl-0">
              <Row className={isMobile ? 'justify-content-center' : 'pl-3'}>
                <Typography className="c-dark-gray font-weight-bold" variant="h5">
                  Seu nível de parceiro:
                </Typography>
              </Row>
              <Row className={isMobile ? 'align-items-center py-3 justify-content-center' : 'align-items-center pl-3 py-3 '}>
                <Typography className="c-white m-0 font-weight-bold" variant="h1">
                  {loading
                    ? <Skeleton variant="blue" height={20} width={100} block />
                    : myLevel.label}
                </Typography>
              </Row>
              <Row className={isMobile ? 'pt-3 pb-2 justify-content-center' : 'align-items-center pl-3 py-2'}>
                <Typography className="c-dark-gray m-0" variant="h6">
                  Níveis
                </Typography>
              </Row>
              <Row className={isMobile ? 'py-3 justify-content-center ml-4' : 'py-3 pl-2'}>
                <Col sm="9" xs="11" className={isMobile ? 'text-center' : 'text-left'}>
                  {loading ? <Skeleton variant="blue" height={40} width="100%" block />
                    : (
                      <Timeline
                        myLevel={myLevel}
                        levels={levels}
                        getIcon={getIcon}
                        setShowModal={setShowLevelsModal}
                        setLevelSelected={setLevelSelected}
                      />
                    )}

                </Col>
              </Row>
              {loading
                ? <Skeleton variant="blue" height={15} width="100%" className="mt-2" block />
                : (
                  <Row className={isMobile ? 'pt-3 px-3' : 'align-items-center pt-3'}>
                    <Col sm="11" xs="10" className="p-0" style={{ marginLeft: -10 }}>
                      <SliderBlur />
                      <LevelSlider
                        className="m-2 ml-4"
                        min={myLevel?.gmv_min}
                        max={myLevel?.next_level?.gmv_min || transactionsTotal * 2}
                        value={transactionsTotal}
                        labels={labels}
                        handleLabel={Dinero({ amount: Math.trunc(transactionsTotal * 100) || 0 }).toFormat()}
                      />
                    </Col>
                    <Col className="text-center p-0">
                      {loading ? '' : getIcon({ type: myLevel?.next_level?.name, fill: '#bdbdbd', size: 14 })}
                      <Typography className="c-dark-gray mx-0 mb-0 mt-2" variant="h6" percent="90" tooltip="Próximo nível para alcançar">
                        {loading ? '' : myLevel?.next_level?.label}
                      </Typography>
                    </Col>
                  </Row>
                )}
              <Row className="align-items-center pb-7 pt-5 pl-3">
                <BiTargetLock size={16} color="#FFCB00" />
                <Typography className="c-white pl-2 my-0" fontWeight="500" variant="h5" percent="88">
                  Requisito para atingir o próximo nível:
                  {' '}
                  {!loading && Dinero({ amount: Math.trunc(myLevel?.next_level?.gmv_min * 100) || 0 }).toFormat()}
                </Typography>
              </Row>
            </Col>
            <Col xs="12" sm={{ span: 4, offset: 1 }} className="pr-6">
              <AccordionMissions defaultActiveKey="mission" className="w-100">
                <ButtonToggle block className="float-button text-left py-2" eventKey="mission" onSelect={onSelectAccordion}>
                  <Row className="align-items-center py-2 pl-3">
                    <Col xs="auto" className="p-0">
                      <IconMission className="c-white ml-2 mr-2" width="12" height="24" strokeWidth="0px" />
                    </Col>
                    <Col className="p-0">
                      <Typography className="c-white font-weight-bold m-0" variant="h5" percent="90">
                        Missões para liberar bônus extras
                      </Typography>
                    </Col>
                    <Col xs="auto" className="text-right">
                      <ChevronUpDown chevron="right" size="16" className=" c-white" collapsed={collapsed.includes('mission')} />
                    </Col>
                  </Row>
                </ButtonToggle>
                <AccordionMissions.Collapse eventKey="mission" className="card">
                  <Card.Body>
                    {loading
                      ? (
                        <Skeleton variant="blue" height={20} width="100px" />
                      ) : (
                        <>
                          {
                            perks
                              .filter((p) => p?.type_enum === 'MISSION' && p?.level_id === myLevel?.id)
                              .slice(0, 3)
                              .map((perk, index) => (
                                <Row key={`perk-mission-${index}`} className="align-items-start m-0">
                                  <Col xs="auto" className="p-0">
                                    <Row className="align-items-start m-0">
                                      <BiTargetLock size={14} color="#A5A5A5" />
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Row className="align-items-start m-0">
                                      <Typography className="c-dark-gray " variant="h5" percent="90">
                                        {perk.name}
                                      </Typography>
                                    </Row>
                                  </Col>
                                </Row>
                              ))
                          }
                        </>
                      )}
                    <Row className="justify-content-end pr-4 pt-3">
                      <Typography className="c-white pointer" variant="h6" onClick={scrollToElement}>
                        Ver bônus
                      </Typography>
                    </Row>
                  </Card.Body>
                </AccordionMissions.Collapse>
              </AccordionMissions>
            </Col>
          </Row>
        </Col>
      </BackgroundBannerTop>

      <Row className="bg-soft-purple-light ">
        <Col xs="12" sm={{ span: 10, offset: 1 }}>
          <Col xs="12" className="py-4 px-0">
            <Card className="p-4 bg-white">
              <Typography className="c-dark pt-2 font-weight-bold " variant="h5">
                Grupo de Negócios
              </Typography>
              <Row className="py-4 align-items-end">
                <Col sm={4}>
                  <InputGroup className="input-group-shadow no-gutters">
                    <DatepickerContainer className="col">
                      <DatePicker
                        placeholderText="Filtrar por Período"
                        selectsRange
                        inputReadOnly
                        isCleareable
                        monthsShown={2}
                        shouldCloseOnSelect={false}
                        selected={filterStartDate}
                        startDate={filterStartDate}
                        endDate={filterEndDate}
                        onChange={onChangeFilterDate}
                        calendarContainer={(props) => (<CalendarContainerCustom onChangeFilterDate={onChangeFilterDate} {...props} />)}
                        popperPlacement="bottom-start"
                        bgColor="#FAFAFF"
                      />
                    </DatepickerContainer>
                    <InputGroup.Append>
                      <InputGroup.Text className="bg-soft-purple-light">
                        <FiCalendar size="15" className="c-dark " />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col sm={4}>
                  {loading ? (
                    <Skeleton height={45} width={250} block />
                  )
                    : (
                      <ScrollableSelect
                        placeholder="Filtrar por Cliente"
                        noOptionsMessage={() => 'Nenhum resultado'}
                        isMulti
                        ref={clientRef}
                        value={filterCustomers}
                        onChange={(values) => {
                          handleChangeSelect(values, handleCustomerFilter, clientRef);
                        }}
                        options={customers}
                        styles={PurpleShadowStyle}
                      />
                    )}

                </Col>

                {showProductFilter
                  && (
                    <Col sm={3}>
                      {loadingProducts
                        ? <Skeleton height={45} width={250} block />
                        : (
                          <ScrollableSelect
                            ref={productRef}
                            noOptionsMessage={() => 'Nenhum resultado'}
                            isMulti
                            placeholder="Filtrar por Produto"
                            value={filterProducts}
                            options={products}
                            styles={PurpleShadowStyle}
                            onChange={(values) => {
                              handleChangeSelect(values, setFilterProducts, productRef);
                            }}
                          />
                        )}
                    </Col>
                  )}
                <Col xs="1">
                  <Button className="p-3" variant="light" size="sm" onClick={() => handleRefreshChart()}>
                    <FiRefreshCw className="c-dark" size="18" />
                  </Button>
                </Col>

              </Row>
              {!loading
                && (
                  <Row className="justify-content-end align-items-center pb-2 pr-3">
                    <Col md="4" lg="2">
                      <ScrollableSelect
                        noOptionsMessage={() => 'Nenhum resultado'}
                        ref={byRef}
                        onChange={(values) => {
                          setChartBy(values.value);
                        }}
                        options={granularityOptions}
                        styles={TransparentStyle}
                        placeholder={`Agrupar por: ${granularityOptions.find((g) => g.value === chartBy).label}`}
                      />
                    </Col>
                    <Typography className="c-dark font-weight-bold m-0" variant="h5" percent="90">
                      {`Total ${Dinero({ amount: Math.trunc(totalChart * 100) }).toFormat()}`}
                    </Typography>

                  </Row>
                )}
              <div height={300}>
                {((!loading || cubejsApi) && customers?.length > 0)
                  && (
                    <ChartGmv key={keyChart} filterChart={filterChart} filterDateChart={filterDateChart}
                      cubejsApi={cubejsApi} customers={customers} chartBy={chartBy} relayChart={relayChart} setRelayChart={setRelayChart}
                    />
                  )}

              </div>
              {!loading && customers?.length === 0
                && (
                  <Row>
                    <Col className="text-center">
                      <ImgGrupoNegocios01 className="mt-6 mb-2" />
                      <Row className="justify-content-center">
                        <Typography className="c-dark pt-2 font-weight-bold " variant="h4">
                          Acompanhe o crescimento do seu grupo de negócios.
                        </Typography>
                      </Row>
                      <Row className="justify-content-center mx-3">
                        <Typography className="c-dark pt-2 pb-2" variant="p" percent="80">
                          Informe os produtos que já geram vendas na Eduzz e acompanhe os valores que seu grupo de negócios está movimentando.
                        </Typography>
                      </Row>
                      <Row className="justify-content-center mx-4">
                        <Typography className="c-blue pt-2 pb-6" variant="p" percent="80">
                          Entre em contato com o suporte pelo chat no canto inferior direito.
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                )}
            </Card>
          </Col>
          <Row>
            <Col xs="12">
              <Card className="p-4 bg-white">
                <Typography className="c-dark-gray pt-2 font-weight-regular" variant="h6">
                  MEU GRUPO DE NEGÓCIO
                </Typography>
                <Typography className="c-dark pt-2 font-weight-bold " variant="h5">
                  Total do seu grupo
                </Typography>
                <Row className="align-items-center px-3" onMouseEnter={() => setDisplayInfo('group-total')} onMouseLeave={() => setDisplayInfo(null)}>
                  <Typography className="c-dark pt-2 font-weight-bold mb-0" variant="h2">
                    {loading ? <Skeleton height={20} width={100} block />
                      : Dinero({ amount: Math.trunc(transactionsTotal * 100) }).toFormat()}
                  </Typography>
                  <BiTrendingUp size={20} className="c-purple ml-2" />
                  <Tooltip
                    position="top"
                    className={`my-auto ${displayInfo !== 'group-total' && 'd-none'} ml-3`}
                    html={(
                      <Row className="py-3 align-items-center">
                        <Col className="text-left">
                          Esse valor representa o
                          {' '}
                          <b>total do seu grupo de negócio</b>
                          {' '}
                          levando em consideração transações únicas dos produtores e co-produtores.
                          Ou seja, se um produtor e co-produtor compartilham uma venda, ela será somada apenas uma vez nesse total,
                          porém na listagem cada produtor ou co-produtor terá o valor total do faturamento.
                        </Col>
                      </Row>
                    )}
                  >
                    <FiHelpCircle className="help-circle my-auto" size="17" fill="white" color="#7C00FF" />
                  </Tooltip>
                </Row>
                <Row className="py-4">
                  <Col sm={4}>
                    <InputGroup className="input-group-shadow no-gutters">
                      <DatepickerContainer className="col">
                        <DatePicker
                          selectsRange
                          inputReadOnly
                          isCleareable
                          monthsShown={2}
                          shouldCloseOnSelect={false}
                          selected={filterStartDateTable}
                          startDate={filterStartDateTable}
                          endDate={filterEndDateTable}
                          onChange={onChangeFilterDateTable}
                          calendarContainer={(props) => (<CalendarContainerCustom onChangeFilterDate={onChangeFilterDateTable} {...props} />)}
                          popperPlacement="bottom-start"
                          className="form-control-white"
                          placeholderText="Filtrar por Período"
                          bgColor="#FAFAFF"
                        />
                      </DatepickerContainer>
                      <InputGroup.Append>
                        <InputGroup.Text className="bg-soft-purple-light">
                          <FiCalendar size="15" className="c-dark" />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                  <Col sm={4}>
                    <ScrollableSelect
                      noOptionsMessage={() => 'Nenhum resultado'}
                      isMulti
                      value={filterCustomersTable}
                      onChange={setFilterCustomersTable}
                      options={customersTable}
                      styles={PurpleShadowStyle}
                      placeholder="Filtrar por Cliente"
                    />
                  </Col>
                  <Col sm={3}>
                    <ScrollableSelect
                      noOptionsMessage={() => 'Nenhum resultado'}
                      isMulti
                      value={filterCustomerType}
                      onChange={setFilterCustomerType}
                      options={customerType}
                      styles={PurpleShadowStyle}
                      placeholder="Nível Programa elite"
                    />
                  </Col>
                  <Col xs="1">
                    <Button className="p-3" variant="light" size="sm" onClick={() => handleRefreshTable()}>
                      <FiRefreshCw className="c-dark" size="18" />
                    </Button>
                  </Col>
                </Row>

                {(loading || !cubejsApi) ? ''
                  : (
                    <>
                      <Row className="justify-content-end pb-2 pr-4">
                        <Typography className="c-dark font-weight-bold" variant="h5" percent="90">
                          {`Total ${Dinero({ amount: Math.trunc(totalTable * 100) }).toFormat()}`}
                        </Typography>
                      </Row>
                      <TableCustomer
                        key={keyTable}
                        filterTable={filterTable}
                        filterDateTable={filterDateTable}
                        cubejsApi={cubejsApi}
                        filter={filter}
                        handleOnClickCustomer={handleOnClickCustomer}
                        isSupportUser={isSupportUser(user?.email)}
                      />
                      {customers?.length > 0
                        && (
                          <Row className="justify-content-end mx-0">
                            <PageNavigation
                              filter={filter}
                              setFilter={setFilter}
                              loading={loading}
                              countProject={countCustomers}
                            />
                          </Row>
                        )}

                    </>
                  )}
                <Row />
                {!loading && customers?.length === 0
                  && (
                    <Row>
                      <Col className="text-center">
                        <ImgGrupoNegocios02 className="mt-6" />
                        <Row className="justify-content-center">
                          <Typography className="c-dark pt-2 font-weight-bold " variant="h4">
                            Veja o que cada cliente movimenta no seu grupo de negócios.
                          </Typography>
                        </Row>
                        <Row className="justify-content-center mx-3">
                          <Typography className="c-dark pt-2 pb-4" variant="p" percent="80">
                            Informe seus produtos que já faturam na Eduzz e
                            veja detalhes de como está performando cada cliente do seu grupo de negócios.
                          </Typography>
                        </Row>
                        <Row className="justify-content-center mx-4">
                          <Typography className="c-blue pt-2 pb-4" variant="p" percent="80">
                            Entre em contato com o suporte pelo chat no canto inferior direito.
                          </Typography>
                        </Row>
                      </Col>
                    </Row>
                  )}

              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="py-7 p-4" style={{ background: '#110F31' }}>
                <Col xs="12" md={{ span: 10, offset: 1 }} className="p-0">
                  <Row>
                    <Col md="5" className="mr-4">
                      <Row className="mx-4">
                        <Typography className="c-white pt-2 font-weight-bold" percent="80" variant="h1">
                          Benefícios programa de parceiros
                        </Typography>
                      </Row>
                      <Row className="mx-4">
                        <Typography className="c-white pt-2 pb-4" variant="p" percent="90">
                          Conheça todos os seus benefícios de acordo com o seu nível no programa de Parceiros.
                        </Typography>
                      </Row>
                      <CardBenefit className="p-6 m-0">

                        {isMobile ? (
                          <Row className="justify-content-center">
                            <IconBlurMobile />
                            {loading ? ''
                              : getIcon({ type: myLevel.name, fill: '#FFCB00', size: 80, className: 'mt-3', style: { position: 'absolute' } })}
                          </Row>
                        ) : (
                          <Row className="justify-content-center">
                            <IconBlur />
                            {loading ? ''
                              : getIcon({ type: myLevel.name, fill: '#FFCB00', size: 108, className: 'mt-3', style: { position: 'absolute' } })}
                          </Row>
                        )}

                        <Row className="justify-content-center pt-4">
                          <Typography className="c-white font-weight-bold " variant="h4">
                            Você está no nível
                            {' '}
                          </Typography>
                        </Row>
                        <Row className="justify-content-center pt-2">
                          <Typography className="c-white font-weight-bold " variant="h3">
                            {loading ? '' : myLevel.label}
                          </Typography>
                        </Row>
                        <Row>
                          <div className="border-separator py-3 px-6" />
                        </Row>
                        <Row className="pt-2">
                          <Typography className="c-white text-center" variant="p" percent="90">
                            Confira quais benefícios você pode alcançar no seu nível.
                          </Typography>
                        </Row>
                      </CardBenefit>
                    </Col>
                    <Col>
                      {loading ? '' : (
                        <AccordionBenefit defaultActiveKey={myLevel.name} className="w-100 dropdown-accordion mt-3 c-dark">
                          {levels.map((level, index) => (
                            <div key={`${level.name}-${index}`}>
                              {index < myLevel?.order
                                && (
                                  <ButtonToggleBenefit level={level} index={index} onSelectAccordion={onSelectAccordion}
                                    collapsed={collapsed} getIcon={getIcon}
                                  />
                                )}
                              {index === myLevel.order
                                && (
                                  <ButtonToggleBenefit level={level} index={index} onSelectAccordion={onSelectAccordion}
                                    collapsed={collapsed} getIcon={getIcon} status="myLevel"
                                  />
                                )}
                              {index > myLevel.order
                                && (
                                  <ButtonToggleBenefit level={level} index={index} onSelectAccordion={onSelectAccordion}
                                    collapsed={collapsed} getIcon={getIcon} status="levelLocked" disabled
                                  />
                                )}
                              <Accordion.Collapse eventKey={level.name} className="card mb-4">
                                <Card.Body>
                                  {
                                    perks
                                      .filter((p) => p.type_enum === 'BENEFIT' && p.level_id === level.id)
                                      .map((perk, indexPerk) => (
                                        <a href={perk.link} target="_blank" rel="noreferrer" key={`perk-${indexPerk}`}>
                                          <Row className="align-items-center pr-6 pt-2">
                                            <Typography className="c-white pl-3" variant="h5" percent="90">
                                              ●
                                              {'  '}
                                              {perk.name}
                                            </Typography>
                                          </Row>
                                        </a>
                                      ))
                                  }
                                </Card.Body>
                              </Accordion.Collapse>
                            </div>
                          ))}
                        </AccordionBenefit>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <CardLevel className="p-6" ref={benefitRef}>
                <Row className="pl-3 align-items-center">
                  <Col xs="auto">
                    {loading ? '' : getIcon({ type: myLevel.name, fill: '#2F2C5D', size: 52 })}
                  </Col>
                  <Col>
                    <Typography className="c-dark font-weight-bold m-0" variant="h5">
                      Nível atual
                    </Typography>
                    <Typography className="c-dark pt-2 font-weight-bold m-0" variant="h1">
                      {loading ? '' : myLevel.label}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <div className="border-separator py-4 px-3" />
                </Row>
                <Row className="pt-2">
                  <Col md="12" lg="4">
                    <Card className="p-4" style={{ background: 'rgba(0, 0, 0, 0.13)', minHeight: '400px' }}>
                      <Col xs="4">
                        <Row className="justify-content-center">
                          <img src={MissoesIcon} alt="missoes" height="85px" />
                        </Row>
                      </Col>
                      <Typography className="c-white pt-3 pr-5" variant="h4">
                        Missões
                      </Typography>
                      <Typography className="c-white pt-2 pr-2" variant="p" percent="90">
                        São ações que habilitam e especializam o parceiro.  Completar todas as missões, desbloqueia os Bônus.
                      </Typography>
                      <Row className="align-items-center pt-2">
                        {!loading
                          && (
                            <>
                              {
                                perks
                                  .filter((p) => p.type_enum === 'MISSION' && p.level_id === myLevel.id)
                                  .map((perk, index) => (
                                    <Row key={`mission-${index}`} className="align-items-start pl-4 pr-3 pt-2">
                                      <Col xs="auto" className="p-0">
                                        <Row className="align-items-start m-0">
                                          <BiTargetLock className="c-white " />
                                        </Row>
                                      </Col>
                                      <Col>
                                        <Row className="align-items-start m-0">
                                          <Typography className="c-white pl-2 m-0" variant="h5" percent="90">
                                            {perk.name}
                                          </Typography>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))
                              }
                            </>
                          )}
                      </Row>
                    </Card>
                  </Col>
                  <Col md="12" lg="4">
                    <Card className="p-4" style={{ background: 'rgba(0, 0, 0, 0.13)', minHeight: '400px' }}>
                      <Col xs="4">

                        <Row className="justify-content-center">
                          <img src={BonusIcon} alt="missoes" height="85px" />
                        </Row>
                      </Col>
                      <Typography className="c-white pt-3 pr-5" variant="h4">
                        Bônus
                      </Typography>
                      <Typography className="c-white pt-2 pr-2" variant="p" percent="90">
                        Benefício obtido pelos parceiros que completarem 100% das missões do nível.
                      </Typography>
                      <Row className="align-items-center pt-2">
                        {!loading
                          && (
                            <>
                              {
                                perks
                                  .filter((p) => p.type_enum === 'BONUS' && p.level_id === myLevel.id)
                                  .map((perk, index) => (
                                    <Row key={`bonus-${index}`} className="align-items-start pl-4 pr-3 pt-2">
                                      <Col xs="auto" className="p-0">
                                        <Row className="align-items-start m-0">
                                          <BiTargetLock className="c-white" />
                                        </Row>
                                      </Col>
                                      <Col>
                                        <Row className="align-items-start m-0">
                                          <Typography className="c-white pl-2 m-0" variant="h5" percent="90">
                                            {perk.name}
                                          </Typography>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))
                              }
                            </>
                          )}
                      </Row>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="p-4" style={{ background: 'rgba(0, 0, 0, 0.13)', minHeight: '400px' }}>
                      <Col xs="4">
                        <Row className="justify-content-center">
                          <img src={PremiacoesIcon} alt="missoes" height="85px" />
                        </Row>
                      </Col>
                      <Typography className="c-white pt-3 pr-5" variant="h4">
                        Reconhecimentos
                      </Typography>
                      <Typography className="c-white pt-3 pr-2" variant="p" percent="90">
                        Receba seu reconhecimento nos principais eventos da Eduzz como Parceiro referência.
                      </Typography>
                      <Row className="align-items-center pt-2">
                        {!loading
                          && (
                            <>
                              {
                                perks
                                  .filter((p) => p.type_enum === 'PRIZE' && p.level_id === myLevel.id)
                                  .map((perk, index) => (
                                    <Row key={`prize-${index}`} className="align-items-center pl-4 pr-3 pt-2">
                                      <Col xs="auto" className="p-0 m-0">
                                        <Row className="align-items-start m-0">
                                          <BiTargetLock className="c-white" />
                                        </Row>
                                      </Col>
                                      <Col>
                                        <Row className="align-items-start m-0">
                                          <Typography className="c-white pl-2 m-0" variant="h5" percent="90">
                                            {perk.name}
                                          </Typography>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))
                              }
                            </>
                          )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </CardLevel>
            </Col>
          </Row>
          {/* <Row>
            <Col xs="12">
              {isMobile ? (
                <Row className="m-0 p-0 ">
                  <a href="https://sun.eduzz.com/1624227"
                    target="_blank" rel="noreferrer"
                  >
                    <img width="100%" src={BannerEduzzRiseMobile} alt="banner" className="rounded" />
                  </a>
                </Row>
              ) : (
                <Row className="m-0 p-0 ">
                  <a href="https://sun.eduzz.com/1624227"
                    target="_blank" rel="noreferrer"
                  >
                    <img width="100%" src={BannerEduzzRise} alt="banner" className="rounded" />
                  </a>
                </Row>
              )}
            </Col>
          </Row> */}
          {(!loading && ((support && myLevel.name !== 'RONIN') || isSupportUser(user?.email)))
            && (
              <FooterDashboard support={support} handleEditing={handleEditing} isSupportUser={isSupportUser(user?.email)} />
            )}
        </Col>
      </Row>

      {
        (isSupportUser(user?.email) && showProductBindModal)
        && (
          <ProductBindModal
            showModal={showProductBindModal}
            setShowModal={setShowProductBindModal}
            onSuccess={onSuccessProductBind}
          />
        )
      }
      {showLevelsModal && (
        <LevelsModal
          showModal={showLevelsModal}
          setShowModal={setShowLevelsModal}
          getIcon={getIcon}
          levelSelected={levelSelected}
          perks={perks}
        />
      )}
      {showTableProductModal && (
        <TableProductModal
          showModal={showTableProductModal}
          setShowModal={setShowTableProductModal}
          filterTable={filterTableProducts}
          filterDateTable={filterDateTable}
          cubejsApi={cubejsApi}
          customerSelected={customerSelected}
          countProducts={countProducts}
          isSupportUser={isSupportUser(user?.email)}
        />
      )}

      {showSupportModal && (
        <SupportModal
          showModal={showSupportModal}
          setShowModal={setShowSupportModal}
          support={support}
          setSupport={setSupport}
          ownerId={ownerId}
        />
      )}

    </Container>
  );
};
export default DashboardPartners;
