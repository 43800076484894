import ButtonToggle from '@/modules/partners/components/ButtonToggle';
import ChevronUpDown from '@/modules/partners/components/ChevronUpDown';
import { Typography } from '@partners-core/components';
import Dinero from 'dinero.js';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import { GoTriangleLeft } from 'react-icons/go';

const ButtonToggleBenefit = ({ level, index, onSelectAccordion, collapsed, getIcon, status }) => (
  <ButtonToggle variant="primary" block disabled={status === 'levelLocked'}
    className={collapsed.includes(level.name) ? 'text-left float-button mb-2 active w-100' : 'text-left float-button mb-2 w-100'}
    eventKey={level.name} onSelect={onSelectAccordion}
    tooltip={status === 'levelLocked'
      ? `Alcance o faturamento de ${Dinero({ amount: Math.trunc(level.gmv_min * 100) || 0 }).toFormat()} para desbloquear o nível ${level.label}`
      : null}
  >
    <Row className="align-items-center py-2 pl-3">
      {getIcon({ type: level.name, fill: 'white', size: 18 })}
      <Typography className="c-white pl-4 font-weight-bold m-0" variant="h5" percent="95">
        {`Nível ${index + 1} - ${level.label} `}
      </Typography>
      { status === 'myLevel'
      && (
        <>
          <GoTriangleLeft width="8px" className="c-purple-light pl-2" />
          <Typography className="c-purple-light pl-2 font-weight-bold m-0" variant="h6">
            Seu nível
          </Typography>
        </>
      )}
      {
        status === 'levelLocked'
        && <FaLock fill="#bdbdbd" size={10} color="none" className="ml-2" />
      }

      <Col className="text-right">
        <ChevronUpDown chevron="right" size="16" className="mr-2 c-white"
          collapsed={collapsed.includes(level.name)}
        />
      </Col>
    </Row>
  </ButtonToggle>
);
export default ButtonToggleBenefit;
