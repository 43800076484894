/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const UsersCustom = forwardRef(({ color = 'currentColor', size = 24, fill = '#6c3fd0', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" fill={color} stroke={color} />
    <circle cx="9" cy="7" r="4" fill={color} stroke={color} />
    <path d="M23 21v-2a4 4 0 0 0-3-3.87" stroke={fill} />
    <path d="M16 3.13a4 4 0 0 1 0 7.75" stroke={fill} />
  </svg>
));

UsersCustom.displayName = 'UsersCustom';

export default UsersCustom;
