import { Button } from '@partners-core/components';
import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const Confirmation = (props) => {
  const {
    message = 'Tem certeza que deseja realizar essa ação?',
    placement = 'top',
    trigger = 'click',
    disabled = false,
    onSuccess = () => (new Promise((resolve) => setTimeout(() => { console.log('sucesso!'); resolve(); }, 2000))),
    onFailure = () => (console.log('falha!')),
    children,
  } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSuccess = async () => {
    setLoading(true);
    try {
      await onSuccess(true);
      setShow(false);
    } catch (error) {
      console.log({ error });
    }
    setLoading(false);
  };

  const handleFailure = () => {
    onFailure(false);
    setShow(false);
  };

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      show={show}
      rootClose
      onToggle={(toggle) => setShow(toggle)}
      overlay={(
        <Popover id={`popover-positioned-${message}`}>
          <Popover.Content>
            <div className="font-weight-regular c-dark">{message}</div>
            <div className="mt-3 text-right">
              <Button variant="secondary" size="sm" className="mr-2" onClick={handleFailure}>Não</Button>
              <Button variant="success" size="sm" onClick={handleSuccess} disabled={disabled} loading={loading}>Sim</Button>
            </div>
          </Popover.Content>
        </Popover>
      )}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Confirmation;
