import React from 'react';
import { GoogleApiProvider } from 'react-gapi';

import GoogleButton from './GoogleButton';

const GoogleConnect = (props) => (
  <GoogleApiProvider clientId={process.env.REACT_APP_WEB_GOOGLE_CLIENT}>
    <GoogleButton {...props} />
  </GoogleApiProvider>
);

export default GoogleConnect;
