/* eslint-disable max-len */
/* eslint-disable camelcase */

import { findIndex, cloneDeep, uniqBy } from 'lodash';
import React, { useCallback, useContext, useReducer } from 'react';

const WidgetStateContext = React.createContext();
const WidgetDispatchContext = React.createContext();

const WIDGETS_STORAGE = 'WIDGETS_STORAGE';

const ADD_WIDGET = 'ADD_WIDGET';
const REMOVE_WIDGET = 'REMOVE_WIDGET';
const EDIT_WIDGET = 'EDIT_WIDGET';
const SET_WIDGET_CONFIG = 'SET_WIDGET_CONFIG';
const SET_WIDGET_DATA = 'SET_WIDGET_DATA';
const SET_SHOW_MODAL = 'SET_SHOW_MODAL';

const INIT_ORIGIN_SUBSCRIPTION = 'INIT_ORIGIN_SUBSCRIPTION';
const ADD_ORIGIN_ACTIVITY = 'ADD_ORIGIN_ACTIVITY';
const UPDATE_ORIGIN_ACTIVITY = 'UPDATE_ORIGIN_ACTIVITY';
const REMOVE_ORIGIN_ACTIVITY = 'REMOVE_ORIGIN_ACTIVITY';
const SELECT_ORIGIN = 'SELECT_ORIGIN';
const REMOVE_ORIGIN = 'REMOVE_ORIGIN';
const UPDATE_ORIGIN_STAGES = 'UPDATE_ORIGIN_STAGES';

const UPDATE_DOCK_SIDEBAR = 'UPDATE_DOCK_SIDEBAR';

const ADD_RECENT_LEAD = 'ADD_RECENT_LEAD';
const REMOVE_CURRENT_LEAD = 'REMOVE_CURRENT_LEAD';
const REMOVE_CONTACT_CURRENT_LEAD = 'REMOVE_CONTACT_CURRENT_LEAD';
const PIN_LEAD = 'PIN_LEAD';
const UNPIN_LEAD = 'UNPIN_LEAD';

const modalInit = {
  deal: {
    show: false,
    stage: null,
  },
  dealUser: {
    show: false,
    stage: null,
  },
  project: {
    show: false,
  },
  partners: {
    show: false,
  },
  integrations: {
    show: false,
  },
  webhook: {
    show: false,
  },
  eduzz_partners: {
    show: false,
    edit: false,
  },
  csv: {
    show: false,
  },
  whatsapp: {
    show: false,
  },
  mail: {
    show: false,
  },
  schedule: {
    show: false,
  },
  projects: {
    show: false,
  },
  tax: {
    show: false,
    data: {},
  },
  outbox: {
    show: false,
  },
};

const dockInit = {
  show: true,
};

const initWidget = {
  CONTACT: null,
  CALL: null,
  AUDIO_PLAYER: null,
  ORIGINS: {
    data: [],
    config: {
      selected: null,
      active: true,
      collapsed: true,
      outside_collapse: false,
      order: 2,
    },
  },
  LAST_LEADS: {
    data: {
      leads: [],
      pinned: [],
    },
    config: {
      pinned: false,
      active: true,
      order: 3,
    },
  },
  MODAL: modalInit,
  DOCK_SIDEBAR: dockInit,
};

let localWidget;
try {
  localWidget = JSON.parse(localStorage.getItem(WIDGETS_STORAGE));
} catch (error) {
  localStorage.setItem(WIDGETS_STORAGE, JSON.stringify(initWidget));
}

const loadWidget = localWidget ? { DOCK_SIDEBAR: dockInit, ...localWidget, MODAL: modalInit } : initWidget;

const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    widgetReducer, { widgets: loadWidget },
  );

  return (
    <WidgetStateContext.Provider value={state}>
      <WidgetDispatchContext.Provider value={dispatch}>
        {children}
      </WidgetDispatchContext.Provider>
    </WidgetStateContext.Provider>
  );
};

const addRecentLead = (widget, payload) => {
  const newLead = cloneDeep(payload?.data);
  const currentLeads = cloneDeep(widget?.data?.leads || []);

  if (newLead !== null) {
    currentLeads.unshift(newLead);

    return {
      ...widget.data,
      leads: uniqBy(currentLeads, 'dealId'),
    };
  }

  return widget.data;
};

const removeRecentLead = (widget, payload) => {
  const currentLead = cloneDeep(payload?.data);
  const currentLeads = cloneDeep(widget?.data?.leads || []);

  if (currentLead !== null) {
    const leadIndex = currentLeads.findIndex((lead) => lead.dealId === currentLead.dealId);

    if (leadIndex >= 0) {
      currentLeads.splice(leadIndex, 1);
    }
  }

  return {
    ...widget.data,
    leads: uniqBy(currentLeads, 'dealId'),
  };
};

const removeContactRecentLead = (widget, payload) => {
  const removeLeads = cloneDeep(payload?.data?.ids || []);
  let currentLeads = cloneDeep(widget?.data?.leads || []);

  if (removeLeads !== null) {
    currentLeads = currentLeads.filter((lead) => !removeLeads.includes(lead.id));
  }

  return {
    ...widget.data,
    leads: uniqBy(currentLeads, 'dealId'),
  };
};

const widgetEditActions = (widget, payload) => {
  const updatedWidget = widget;
  if (payload.action === ADD_RECENT_LEAD) {
    updatedWidget.data = addRecentLead(widget, payload);
  } else if (payload.action === REMOVE_CURRENT_LEAD) {
    updatedWidget.data = removeRecentLead(widget, payload);
  } else if (payload.action === REMOVE_CONTACT_CURRENT_LEAD) {
    updatedWidget.data = removeContactRecentLead(widget, payload);
  } else if (payload.action === PIN_LEAD) {
    const pinnedLead = {
      ...payload.data,
    };

    const pinnedLeads = updatedWidget.data.pinned;
    const pinnedIndex = findIndex(pinnedLeads, { dealId: pinnedLead.dealId });
    if (pinnedIndex < 0) {
      updatedWidget.data = {
        ...updatedWidget.data,
        pinned: [...pinnedLeads, pinnedLead],
      };
    }
  } else if (payload.action === UNPIN_LEAD) {
    const pinnedLead = {
      ...payload.data,
    };

    const pinnedLeads = updatedWidget.data.pinned;
    const pinnedIndex = findIndex(pinnedLeads, { dealId: pinnedLead?.dealId });
    if (pinnedIndex >= 0) {
      pinnedLeads.splice(pinnedIndex, 1);
      updatedWidget.data = {
        ...updatedWidget.data,
        pinned: pinnedLeads,
      };
    }
  } else if (payload.action === SELECT_ORIGIN) {
    updatedWidget.config = {
      ...updatedWidget.config,
      selected: payload.data,
    };

    const newData = updatedWidget.data.map((u) => {
      if (u.id === payload?.data?.id) {
        return {
          ...u,
          ...payload.data,
          created: 0,
        };
      }
      return u;
    });
    updatedWidget.data = newData;
  } else if (payload.action === REMOVE_ORIGIN) {
    const newData = updatedWidget.data.filter((d) => d.id !== payload.data.id);
    updatedWidget.data = newData;
  } else if (payload.action === UPDATE_ORIGIN_STAGES) {
    const newData = updatedWidget.data.map((origin) => {
      if (origin.id === payload.data.origin.id) {
        return {
          ...origin,
          settings: {
            ...origin.settings,
            stages: payload.data.stages,
          },
        };
      }
      return origin;
    });
    updatedWidget.data = newData;
  } else if (payload.action === UPDATE_DOCK_SIDEBAR) {
    updatedWidget.show = payload.data;
  }

  return updatedWidget;
};

const widgetReducer = (state, action) => {
  const { widgets } = state;
  const { type, payload } = action;

  const syncWidgets = widgets;
  switch (type) {
    case ADD_WIDGET: {
      const updatedWidget = syncWidgets[payload.type];
      const { data, config } = payload;
      if (updatedWidget && updatedWidget.data && updatedWidget.data.id === data.id) {
        const oldWidgetData = updatedWidget?.data;
        const { contact } = oldWidgetData;
        const lastUpdatedAt = oldWidgetData.updated_at;
        const contactUpdatedAt = contact?.updated_at;
        const organizationUpdatedAt = contact?.organization?.updated_at;
        if (lastUpdatedAt !== data.updated_at || contactUpdatedAt !== data?.contact?.updated_at || organizationUpdatedAt !== data?.contact?.organization?.updated_at) {
          config.last_update_user_id = data.last_update_user_id;
          config.updated = true;
        } else if (lastUpdatedAt === data.updated_at) {
          const { projects } = oldWidgetData;
          let isUpdated = false;
          if (data?.projects && projects.length !== data?.projects.length) {
            isUpdated = true;
          } else if (JSON.stringify(projects) !== JSON.stringify(data?.projects)) {
            isUpdated = true;
          }
          if (isUpdated) {
            config.last_update_user_id = data.last_update_user_id;
            config.updated = true;
          }
        }
      }
      syncWidgets[payload.type] = {
        ...updatedWidget,
        ...payload,
        data,
        config,
      };
      break;
    }
    case REMOVE_WIDGET: {
      syncWidgets[payload.type] = null;
      break;
    }
    case EDIT_WIDGET: {
      const updatedWidget = widgetEditActions(syncWidgets[payload.type], payload);

      syncWidgets[payload.type] = updatedWidget;
      break;
    }
    case SET_WIDGET_CONFIG: {
      const updatedWidget = syncWidgets[payload.type];
      updatedWidget.config = {
        ...updatedWidget?.config,
        ...payload.data,
      };

      syncWidgets[payload.type] = updatedWidget;
      break;
    }
    case SET_WIDGET_DATA: {
      const updatedWidget = syncWidgets[payload.type];
      const newData = payload.data.map((dt) => {
        const oldData = updatedWidget.data.find((ud) => ud.id === dt.id);
        return {
          ...oldData,
          ...dt,
        };
      });
      const selectedOrigin = updatedWidget.config.selected;
      if (selectedOrigin) {
        const updatedSelected = newData.find((origin) => origin.id === selectedOrigin.id);
        updatedWidget.config = {
          ...updatedWidget.config,
          selected: updatedSelected,
        };
      }

      updatedWidget.data = newData;

      syncWidgets[payload.type] = updatedWidget;
      break;
    }
    case SET_SHOW_MODAL: {
      syncWidgets.MODAL[payload.modal].show = payload.show;
      syncWidgets.MODAL[payload.modal].edit = payload.edit || false;
      syncWidgets.MODAL[payload.modal].data = payload.data || null;
      if (payload.modal === 'deal' && payload.stage) {
        syncWidgets.MODAL[payload.modal].stage = payload.stage;
      }

      break;
    }
    case INIT_ORIGIN_SUBSCRIPTION: {
      syncWidgets.MODAL[payload.modal].show = payload.show;
      syncWidgets.MODAL[payload.modal].edit = payload.edit || false;
      syncWidgets.MODAL[payload.modal].data = payload.data || null;
      if (payload.modal === 'deal' && payload.stage) {
        syncWidgets.MODAL[payload.modal].stage = payload.stage;
      }

      break;
    }
    case ADD_ORIGIN_ACTIVITY: {
      const updatedWidget = syncWidgets.ORIGINS;
      const { originId, stepCode, activity } = payload;

      const isSelected = updatedWidget.config.selected.id === originId;

      if (isSelected) {
        updatedWidget.config = {
          ...updatedWidget.config,
          selected: {
            ...updatedWidget.config.selected,
            template_activity: {
              activities: {
                steps: updatedWidget.config.selected.id !== originId
                  ? updatedWidget.config.selected.template_activity.activities.steps
                  : updatedWidget.config.selected.template_activity.activities.steps.map((stepActivity) => (stepActivity.code === stepCode
                    ? ({ ...stepActivity, activities: [...stepActivity.activities, activity] })
                    : stepActivity)),
              },
            },
          },
        };
      }

      updatedWidget.data = updatedWidget.data.map((origin) => ({
        ...origin,
        template_activity: {
          activities: {
            steps: origin.id !== originId
              ? origin.template_activity.activities.steps
              : origin.template_activity.activities.steps.map((stepActivity) => (stepActivity.code === stepCode
                ? ({ ...stepActivity, activities: [...stepActivity.activities, activity] })
                : stepActivity)),
          },
        },
      }));

      syncWidgets.ORIGINS = updatedWidget;
      break;
    }
    case UPDATE_ORIGIN_ACTIVITY: {
      const updatedWidget = syncWidgets.ORIGINS;
      const { originId, stepCode, activity } = payload;

      const isSelected = updatedWidget.config.selected.id === originId;

      if (isSelected) {
        updatedWidget.config = {
          ...updatedWidget.config,
          selected: {
            ...updatedWidget.config.selected,
            template_activity: {
              activities: {
                steps: updatedWidget.config.selected.id !== originId
                  ? updatedWidget.config.selected.template_activity?.activities?.steps
                  : updatedWidget.config.selected.template_activity.activities.steps.map((stepActivity) => (stepActivity.code === stepCode
                    ? ({
                      ...stepActivity,
                      activities: stepActivity.activities.map((ac) => (ac.id === activity.id ? ({ ...ac, ...activity }) : ac)),
                    })
                    : stepActivity)),
              },
            },
          },
        };
      }

      updatedWidget.data = updatedWidget.data.map((origin) => ({
        ...origin,
        template_activity: {
          activities: {
            steps: origin.id !== originId
              ? origin.template_activity?.activities?.steps
              : origin.template_activity.activities.steps.map((stepActivity) => (stepActivity.code === stepCode
                ? ({
                  ...stepActivity,
                  activities: stepActivity.activities.map((ac) => (ac.id === activity.id ? ({ ...ac, ...activity, origin: true, custom: false }) : ac)),
                })
                : stepActivity)),
          },
        },
      }));

      syncWidgets.ORIGINS = updatedWidget;
      break;
    }
    case REMOVE_ORIGIN_ACTIVITY: {
      const updatedWidget = syncWidgets.ORIGINS;
      const { originId, stepCode, activity } = payload;

      const isSelected = updatedWidget.config.selected.id === originId;

      if (isSelected) {
        updatedWidget.config = {
          ...updatedWidget.config,
          selected: {
            ...updatedWidget.config.selected,
            template_activity: {
              activities: {
                steps: updatedWidget.config.selected.id !== originId
                  ? updatedWidget.config.selected.template_activity.activities.steps
                  : updatedWidget.config.selected.template_activity.activities.steps.map((stepActivity) => (stepActivity.code === stepCode
                    ? ({
                      ...stepActivity,
                      activities: stepActivity.activities.filter((ac) => ac.id !== activity.id),
                    })
                    : stepActivity)),
              },
            },
          },
        };
      }

      updatedWidget.data = updatedWidget.data.map((origin) => ({
        ...origin,
        template_activity: {
          activities: {
            steps: origin.id !== originId
              ? origin.template_activity.activities.steps
              : origin.template_activity.activities.steps.map((stepActivity) => (stepActivity.code === stepCode
                ? ({
                  ...stepActivity,
                  activities: stepActivity.activities.filter((ac) => ac.id !== activity.id),
                })
                : stepActivity)),
          },
        },
      }));

      syncWidgets.ORIGINS = updatedWidget;
      break;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }

  try {
    localStorage.setItem(WIDGETS_STORAGE, JSON.stringify(syncWidgets));
  } catch (e) {
    if (e.code === 22) {
      console.log('LocalStorage FULL');
    }
  }

  return {
    widgets: syncWidgets,
  };
};

const useWidgetState = () => {
  const state = useContext(WidgetStateContext);

  return state;
};

const useWidgetDispatch = () => {
  const dispatch = useContext(WidgetDispatchContext);

  const addWidget = useCallback(
    (payload) => dispatch({ type: ADD_WIDGET, payload }),
    [],
  );
  const removeWidget = useCallback(
    (payload) => dispatch({ type: REMOVE_WIDGET, payload }),
    [],
  );

  const editWidget = useCallback(
    (payload) => dispatch({ type: EDIT_WIDGET, payload }),
    [],
  );

  const setWidgetConfig = useCallback(
    (payload) => dispatch({ type: SET_WIDGET_CONFIG, payload }),
    [],
  );

  const setWidgetData = useCallback(
    (payload) => dispatch({ type: SET_WIDGET_DATA, payload }),
    [],
  );

  const setShowModal = useCallback(
    (payload) => dispatch({ type: SET_SHOW_MODAL, payload }),
    [],
  );

  const initOriginSubscription = useCallback(
    (payload) => dispatch({ type: INIT_ORIGIN_SUBSCRIPTION, payload }),
    [],
  );

  const addOriginActivity = useCallback(
    (payload) => dispatch({ type: ADD_ORIGIN_ACTIVITY, payload }),
    [],
  );

  const updateOriginActivity = useCallback(
    (payload) => dispatch({ type: UPDATE_ORIGIN_ACTIVITY, payload }),
    [],
  );

  const removeOriginActivity = useCallback(
    (payload) => dispatch({ type: REMOVE_ORIGIN_ACTIVITY, payload }),
    [],
  );

  const openTaxForm = (data) => {
    setShowModal({
      modal: 'tax',
      show: true,
      data,
    });
  };

  const closeTaxForm = () => {
    setShowModal({
      modal: 'tax',
      show: false,
    });
  };

  return {
    addWidget,
    removeWidget,
    editWidget,
    setWidgetConfig,
    setWidgetData,
    setShowModal,
    initOriginSubscription,
    addOriginActivity,
    updateOriginActivity,
    removeOriginActivity,
    openTaxForm,
    closeTaxForm,
  };
};

export {
  SidebarProvider,
  useWidgetState,
  useWidgetDispatch,
};
