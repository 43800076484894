/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { AuthContext } from '@/context/AuthContext';
import { useWidgetState, useWidgetDispatch } from '@/context/SidebarContext';
import { Typography } from '@partners-core/components';
import React, { memo, useContext } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Modal, ProgressBar, Row } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';

const OutboxModal = () => {
  const { outboxes } = useContext(AuthContext);
  const { setShowModal } = useWidgetDispatch();
  const state = useWidgetState();
  const { MODAL: modal } = state.widgets;

  const close = () => {
    setShowModal({ type: 'MODAL', modal: 'outbox', show: false });
  };

  return (
    <Modal show={modal?.outbox?.show} onHide={close}>
      <Card className="bg-white mb-0">
        <Card.Header>
          <h4 className="card-header-title font-weight-regular c-dark">
            Vinculações em andamento
          </h4>
          <FiX onClick={close} className="c-dark pointer" size="18" />
        </Card.Header>
        <Card.Body className="mb-0">
          <ListGroup className="list-group-flush my-n3 mx-4">
            {outboxes.map((outbox) => (
              <ListGroupItem className="list-group-item mb-2" key={outbox.id}>
                <Row>
                  <Col>

                    <Typography variant="h5" fontWeight="400" percent="95" className="mb-2 c-dark uppercase">
                      Infoprodutor:
                      {' '}
                      {outbox.meta.customer}
                      {' - '}
                      <b>
                        {outbox.status.label}
                      </b>

                    </Typography>

                    <ProgressBar
                      variant="purple"
                      now={outbox.progress}
                      label={`${outbox.progress.toFixed(0)}%`}
                      animated
                      striped
                    />

                  </Col>
                </Row>
              </ListGroupItem>
            ))}

          </ListGroup>

        </Card.Body>
      </Card>
    </Modal>
  );
};

export default memo(OutboxModal);
