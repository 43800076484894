import { commitMutation, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

const mutation = graphql`
  mutation authCubeTokenMutation {
    auth_cube_token {
      success
      payload
      message
    }
  }
`;

export default function authCubeToken() {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        onCompleted: (resp, error) => {
          if (!error) {
            resolve(resp);
          } else {
            reject(error);
          }
        },
        onError: (error) => {
          reject(error);
        },
      },
    );
  });
}
