import { commitMutation, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

const mutation = graphql`
    mutation createPartnerMutation($data: jsonb!) {
      create_partner(data: $data) {
        success
        message
        payload
      }
    }
`;

export default function create(data) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        variables: {
          data,
        },
        onCompleted: (resp, error) => {
          if (!error) {
            resolve(resp);
          } else {
            reject(error);
          }
        },
        onError: (error) => {
          reject(error);
        },
      },
    );
  });
}
