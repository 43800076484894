import { fetchQuery, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

export const query = graphql`
    query login_loginQuery(
      $email: String
      $password: String
      $credential: String
      $auth_type: AuthSocialType!
    ){
      login(
        data: {
          email: $email
          password: $password
          credential: $credential
          auth_type: $auth_type
        }
      ) {
        id
        has_google_verify
        first_name
        last_name
        email
        avatar
        confirmed_email
        roles
        token
        owner_id
        owner_name
        owner_type
        information
        permissions
      }
    }
`;

const login = (payload) => fetchQuery(environment, query, payload, { force: true });
export default login;
