const state = {
  baseURL: '',

  authorization: {
    key: 'Authorization',
    value: '',
  },

  metadata: {
    tables: [],
    actions: [],
  },

  logger: true,
};

export default state;
