/* eslint-disable camelcase */
import partners from '@partners-core/partners-js';
import dayjs from 'dayjs';

const usePartners = () => {
  const fetchPartnersData = async () => {
    const responseOwner = await partners.owner.findOne({
      select: {
        id: true,
        level_id: true,
        partner_id: true,
        supports: {
          support: {
            id: true,
            name: true,
            email: true,
            avatar: true,
            phone: true,
            type: {
              label: true,
              name: true,
            },
          },
        },
      },
    });
    const responseCustomers = await partners.customer.find({
      select: {
        id: true,
        name: {
          alias: 'label',
        },
        type: {
          label: true,
          name: {
            alias: 'value',
          },
        },
      },
    });
    const today = new Date();
    const firstDay = new Date(today.getFullYear() - 1, today.getMonth(), 1);
    const responseTotalTransactions = await partners.transaction.aggregate({
      where: {
        status_enum: { _in: ['PURCHASE_APPROVED', 'PURCHASE_COMPLETE', 'PURCHASE_REFUNDED'] },
        paid_at: { _gte: `${dayjs(firstDay).format('YYYY-MM-DD')} 00:00:00`, _lte: `${dayjs(today).format('YYYY-MM-DD')} 23:59:59` },
      },
      aggregate: {
        sum: {
          value: true,
        },
      },
      distinct_on: 'external_id',
      options: {
        cached: true,
      },
    });
    const customersMap = responseCustomers?.map((customer) => ({ value: customer.id, label: customer.label, type: customer?.type?.value }));
    let customersTypeMap = responseCustomers?.filter((customer) => customer.type !== null)
      .map((customer) => (customer.type));

    customersTypeMap = customersTypeMap.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    const newSupports = {};
    responseOwner.supports.forEach((s) => (
      newSupports[s.support.type.name] = {
        id: s.support.id,
        avatar: s.support.avatar,
        email: s.support.email,
        name: s.support.name,
        phone: s.support.phone,
      }
    ));
    return { responseOwner, newSupports, customersMap, customersTypeMap, responseTotalTransactions };
  };

  const fetchLoadPerksData = async ({ ownerId, levelId, totalTransactions }) => {
    const responseLevels = await partners.level.find({
      order_by: { gmv_min: 'asc' },
      select: {
        avatar: true,
        gmv_min: true,
        id: true,
        name: true,
        label: true,
        descriptive: true,
        next_level: {
          name: true,
          avatar: true,
          gmv_min: true,
          label: true,
        },
      },
    });
    const responsePerks = await partners.perk.find({
      where: { active: { _eq: true } },
      select: {
        name: true,
        type_enum: true,
        level_id: true,
        link: true,
        active: true,
      },
    });
    const oldMyLevel = [...responseLevels].reverse().find((l) => levelId === l.id);
    const newMyLevel = [...responseLevels].reverse().find((l) => totalTransactions >= l.gmv_min);

    if (newMyLevel && levelId !== newMyLevel?.id && oldMyLevel?.gmv_min < newMyLevel?.gmv_min) {
      partners.owner.updateByPk({
        pk_columns: {
          id: ownerId,
        },
        _set: {
          level_id: newMyLevel.id,
        },
      });
    }

    const indexMyLevel = responseLevels.indexOf(newMyLevel);

    return { indexMyLevel, responsePerks, responseLevels, newMyLevel };
  };

  const deleteOwnerSupport = async ({ where }) => {
    const result = await partners.ownerSupport.deleteOne({
      where,
      select: {
        id: true,
      },
    });
    return result;
  };

  const updateOwnerSupport = async ({ where, _set, select }) => {
    const result = await partners.ownerSupport.updateOne({
      where,
      _set,
      select,
    });
    return result;
  };

  const insertOwnerSupport = async ({ object, select }) => {
    const result = await partners.ownerSupport.insertOne({
      object,
      select,
    });
    return result;
  };

  const insertSupport = async ({ object }) => {
    const result = await partners.support.insertOne({
      object,
      select: {
        id: true,
      },
    });
    return result;
  };

  const fetchSupportsData = async (type) => {
    const result = await partners.support.find({
      where: { type_enum: { _eq: type } },
      select: {
        id: true,
        name: true,
        phone: true,
        email: true,
      },
    });
    return result;
  };

  return {
    fetchPartnersData,
    fetchLoadPerksData,
    fetchSupportsData,
    deleteOwnerSupport,
    updateOwnerSupport,
    insertOwnerSupport,
    insertSupport,
  };
};

export default usePartners;
