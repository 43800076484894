import { ReactComponent as GoogleIcon } from '@/assets/img/social/google.svg';
import Skeleton from '@/components/Skeleton';
import { Button } from '@partners-core/components';
import React from 'react';
import { useGoogleApi } from 'react-gapi';

const GoogleButton = ({ onSuccess, loading, onFailure, forceConcent, variant = 'social', className, text = 'Google' }) => {
  const scopes = [
    'email',
    'profile',
    'openid',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/gmail.send',
    'https://www.googleapis.com/auth/calendar.events',
  ];

  function handleSuccess(response) {
    if (onSuccess) onSuccess(response);
  }

  function handleFailure(response) {
    if (onFailure) onFailure(response);
  }

  const gapi = useGoogleApi({
    scopes,
  });

  const auth = gapi?.auth2.getAuthInstance();

  const authGoogle = () => {
    if (forceConcent) {
      auth.grantOfflineAccess({ redirect_uri: 'postmessage', prompt: 'consent' })
        .then(signInOfflineCallback)
        .catch(handleFailure);
    } else {
      auth.signIn()
        .then(signInCallback);
    }
  };

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  function signInCallback(authResult) {
    handleSuccess({ code: authResult.uc.access_token });
  }

  function signInOfflineCallback(authResult) {
    handleSuccess({ code: authResult.code });
  }

  return (
    <div>
      {
        !auth
          ? <Skeleton height={40} block className="mb-1" />
          : (
            <Button variant={variant} onClick={authGoogle} block className={className} loading={loading}>
              <GoogleIcon className="mr-3" width={24} />
              <span className="font-size-sm bold">
                {auth?.isSignedIn.get()
                  ? `Conectado como ${auth.currentUser.get().getBasicProfile().getName()}`
                  : 'Conectar com o Google'}
              </span>

            </Button>
          )
      }

    </div>
  );
};

export default GoogleButton;
