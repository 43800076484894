import React from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components';

const StyledChev = styled(FiChevronDown)`
  transition: transform .2s;
  &.collapsed{
    transform: rotate(-180deg);
  }
`;

const StyledChevRight = styled(FiChevronRight)`
  transition: transform .2s;
  &.collapsed{
    transform: rotate(90deg);
  }
`;

const ChevronUpDown = ({ collapsed, chevron = 'down', size = '16', className, ...rest }) => (
  <>
    {chevron === 'down'
      && <StyledChev className={`${collapsed ? 'collapsed' : ''} ${className}`} size={size} {...rest} />}
    {chevron === 'right'
      && <StyledChevRight className={`${collapsed ? 'collapsed' : ''} ${className}`} size={size} {...rest} />}
  </>

);

export default ChevronUpDown;
