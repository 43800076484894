import React from 'react';
import { Button as ButtonRB, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

const RenderButton = ({ loading, children, ...props }) => (
  <ButtonRB {...props}>
    {loading ? <Spinner animation="grow" size={props.size === 'sm' ? 'xs' : 'sm'} /> : children}
  </ButtonRB>
);

const Button = ({ loading, children, tooltip, tooltipPosition = 'bottom', tooltipVariant = 'primary', ...rest }) => (

  <>
    {tooltip

      ? (
        <OverlayTrigger
          key={tooltipPosition}
          placement={tooltipPosition}
          overlay={(
            <Tooltip className={`tooltip-${tooltipVariant}`}>
              {tooltip}
            </Tooltip>
          )}
        >
          <span className="d-inline-block">
            <RenderButton loading={loading} style={{ pointerEvents: rest.disabled && 'none' }} {...rest}>
              {children}
            </RenderButton>
          </span>
        </OverlayTrigger>
      )
      : (
        <RenderButton loading={loading} {...rest}>
          {children}
        </RenderButton>
      )}

  </>

);

export default Button;
