import { Button, Form, Input, Checkbox } from '@partners-core/components';
import partners from '@partners-core/partners-js';
import React, { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { Row, Col, Card, Modal, Alert } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

export const stageOptions = [
  { value: 'BASE', label: 'Base' },
  { value: 'PROSPECTION', label: 'Prospecção' },
  { value: 'CONNECTION', label: 'Conexão' },
  { value: 'NO_SHOW', label: 'No-show' },
  { value: 'MEETING', label: 'Reunião' },
  { value: 'PLANNING', label: 'Proposta' },
  { value: 'NEGOTIATION', label: 'Negociação' },
  { value: 'CLOSING', label: 'Fechado' },
];

const ProductBindModal = ({ showModal, setShowModal, onSuccess }) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const [productIds, setProductIds] = useState('');
  const [bindReturn, setBindReturn] = useState(null);
  const [selectAll, setSelectAll] = useState(true);
  const [customerId, setCustomerId] = useState();

  const [retroativeDays, setRetroativeDays] = useState(0);

  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true);

      try {
        const responseData = await partners.owner.supportBindProduct({
          customer_id: customerId,
          products: productIds.split(',').filter((s) => s !== ''),
          select_all: selectAll,
          retroative_days: retroativeDays,
          select: {
            success: true,
            message: true,
            payload: true,
          },
        });

        if (responseData.success) {
          onSuccess();
          setBindReturn(responseData);
        } else {
          addToast(responseData.message, {
            appearance: 'snack',
            autoDismiss: true,
            autoDismissTimeout: 6500,
          });
          setShowModal(false);
        }

        setLoading(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setLoading(false);
      }
    }
  };

  const handleChangeProductIds = (e) => {
    if (e.target.value) {
      setProductIds(e.target.value.replace(/[^\d,]+/g, ''));
    } else {
      setProductIds('');
    }
  };

  return (
    <Modal show={showModal} onHide={setShowModal} centered>
      <Card className="modal-card">
        <Card.Header>
          <h4 className="card-header-title font-weight-regular c-dark">
            Vincular produtos a um parceiro
          </h4>
          <FiX onClick={() => setShowModal(false)} className="c-dark pointer" size="18" />
        </Card.Header>
        <Card.Body>

          <Row className="align-items-center justify-content-center">
            {!bindReturn
              && (
                <Col>

                  <Form.Group>
                    <Form.Label className="font-size-md font-weight-normal c-dark">
                      ID do produtor
                      {' '}
                      <Button variant="link" className="p-0 c-red" tooltip="campo obrigatório">*</Button>
                    </Form.Label>
                    <Input name="customerId" type="number" placeholder="Ex: 4323231"
                      className="form-control-translucent form-control-md c-dark alive bold"
                      value={customerId} onChange={(e) => setCustomerId(e.target.value)}
                    />
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="font-size-md font-weight-normal c-dark">
                      Produtos que deseja vincular com essa Agência
                      {' '}
                      <Button variant="link" className="p-0 c-red" tooltip="campo obrigatório">*</Button>
                    </Form.Label>
                    {!selectAll
                      && (
                        <TextareaAutosize
                          className="form-control form-control-translucent font-size-sm-2 p-3 alive"
                          placeholder="Adicione os ID's separados por virgula. Ex: 423231,482823,929203"
                          value={productIds}
                          onChange={handleChangeProductIds}
                          disabled={selectAll}
                        />
                      )}

                    <Checkbox
                      id="transferEnabled"
                      name="transferEnabled"
                      // ref={checkboxRef}
                      className="font-size-md mt-2"
                      label="Selecionar todos os produtos"
                      value={selectAll}
                      onChange={(e) => setSelectAll(e.target.checked)}
                    />
                  </Form.Group> */}

                  <Form.Group>
                    <Form.Label className="font-size-md font-weight-normal c-dark">
                      Quantidade de dias retroativo de transações
                    </Form.Label>
                    <Input min={0} max={365} name="retroativeDays"
                      placeholder="de 0 à 365" type="number"
                      className="form-control-translucent form-control-md c-dark alive bold"
                      value={retroativeDays} onChange={(e) => {
                        if (!e.target.value || (e.target.value > 0 && e.target.value < 366)) {
                          setRetroativeDays(e.target.value);
                        }
                      }}
                    />
                  </Form.Group>

                  <Row>
                    <Col className="text-right">
                      <Button variant="success" onClick={handleSubmit} size="md"
                        loading={loading} className="mr-3"
                      >
                        Vincular produto(s)
                      </Button>
                    </Col>
                  </Row>

                </Col>
              )}

            {bindReturn
              && (
                <Col>
                  <Alert variant="success" className="py-3">
                    <Row className="align-items-center text-left">
                      <Col>
                        <b>
                          As vinculações de produtos e transações estão em andamento.
                          Você pode acompanhar pelo botão "vinculações em andamento" no menu superior
                        </b>
                      </Col>
                    </Row>
                  </Alert>

                  <Row>
                    <Col className="text-right mt-4">
                      <Button variant="secondary" size="md" className="mr-3" onClick={() => setShowModal(false)}>
                        Fechar
                      </Button>
                    </Col>
                  </Row>

                </Col>
              )}

          </Row>

        </Card.Body>
      </Card>

    </Modal>
  );
};

export default ProductBindModal;
