import login from '@/api/query/auth/login';
import { setHasGoogleVerify } from '@/util/user-config';
import yup from '@/util/yup';
import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import { useRouter } from 'found';
import React, { useContext, useRef, useState } from 'react';
import { Card, Alert } from 'react-bootstrap';

import auth from '../../config/auth';
import { AuthContext } from '../../context/AuthContext';
import CardForgetPassword from './CardForgetPassword';

const schemaValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .required(),
});
function CardSignIn({ forgot }) {
  const formRef = useRef(null);
  const { router } = useRouter();
  const { getUser, setUser, setOwner, logout } = useContext(AuthContext);
  const [showFormForgetPassword, setShowFormForgetPassword] = useState(!!forgot);
  const [message, setMessage] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);

  const onLogin = async (payload, authType) => {
    logout();
    const res = await login({
      ...payload,
      auth_type: authType,
    });
    auth.setToken(res.login.token);
    const user = await getUser(res?.login?.id);
    setUser({
      ...user,
      token: res?.login?.token,
    });
    setOwner(user?.owner);
    setHasGoogleVerify(res.login.has_google_verify);
    router.push('/partners/eduzz');
  };

  const handleSubmit = async (payload) => {
    setLocalLoading(true);
    setMessage(null);
    try {
      formRef.current.setErrors({});
      await schemaValidation.validate(payload, {
        abortEarly: false,
      });
      await onLogin(payload, 'LOCAL');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else setMessage(err?.source?.errors[0]?.message);
    }
    setLocalLoading(false);
  };

  return (
    <Card className="mx-xl-7 p-4 p-xl-6 bg-white">
      <Card.Body className="p-0">
        {!showFormForgetPassword
          ? (
            <>
              <h4 className="text-center mb-4 bold c-dark">Acesso Partners</h4>

              <Unform ref={formRef} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Input name="email" type="email" placeholder="Email" />
                </Form.Group>

                <Form.Group>
                  <Form.Input name="password" type="password" placeholder="Senha" />
                </Form.Group>

                {message && (
                  <Alert variant="danger-outline" onClose={() => setMessage(null)} dismissible>
                    <b>
                      {message}
                      .
                    </b>
                    <div>Verifique as informações fornecidas.</div>
                  </Alert>
                )}

                <Button loading={localLoading} variant="primary" type="submit" block
                  style={{ backgroundColor: '#0D47A1', border: '1px solid #0D47A1' }}
                >
                  Continuar
                </Button>

                <p className="font-size-sm mt-4 text-center">
                  <Button variant="link" onClick={() => setShowFormForgetPassword(true)}>
                    Esqueceu sua senha?
                  </Button>
                </p>
              </Unform>
            </>
          )
          : (
            <CardForgetPassword onBack={() => setShowFormForgetPassword(false)} />
          )}
      </Card.Body>
    </Card>
  );
}

export default CardSignIn;
