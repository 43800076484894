import pt from 'date-fns/locale/pt';
import dayjs from 'dayjs';
import React, { forwardRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FaChevronDown } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

import Button from '../Button/Button';
import Input from '../Input/Input';
import { Typography } from '../Typography';

pt.options.weekStartsOn = 0;

registerLocale('pt', pt);
const dateFormat = 'dd/MM/yyyy';
const dateFormatRange = 'DD MMM, YYYY';

export const ButtonStyled = styled(Button)`
  height: 35px;
  padding: 0;
  padding-left: 8px;
  padding-right: 10px;
  background-color: transparent;
  border-color: #fff;
  color:#3e3a6f;
  transition: none;
  border-radius: 0.3125rem !important;
  font-size: 0.7rem;
  line-height: 0px;

  &:hover {
    background-color: #EFF3FF !important;
    transition: none;
    border: 1px solid #EFF3FF;
    color: #3e3a6f;
  }

  &:active {
    border: 1px solid #2f2c5d;
    background-color: #2f2c5d !important;
    color: white;
    box-shadow: none !important;
  }

  &:focus {
    border: 1px solid #2f2c5d;
    background-color: #2f2c5d !important;
    color: white;
    box-shadow: none !important; 
  }
`;

const CustomInput = forwardRef(({ value, selectsRange, startDate, endDate, placeholderText, bgColor = 'white', ...rest }, ref) => {
  let customValue = value;
  let style;
  if (selectsRange) {
    if (startDate) {
      customValue = dayjs(startDate).format(dateFormatRange);
      if (endDate) {
        customValue += ` - ${dayjs(endDate).format(dateFormatRange)}`;
      }
    } else {
      style = { color: '#8193af', backgroundColor: bgColor };
      customValue = placeholderText;
    }
  }

  return <Input ref={ref} style={style} value={customValue} {...rest} />;
});

const PopoverInput = forwardRef(({ value, label, selectsRange, startDate, endDate, placeholderText, resetDateScheduleFilter, ...rest }) => {
  let customValue = value;
  const newStartDate = startDate;
  if (selectsRange) {
    if (startDate) {
      customValue = dayjs(startDate).format(dateFormatRange);
      if (endDate) {
        customValue += ` - ${dayjs(endDate).format(dateFormatRange)}`;
      }
    } else {
      customValue = placeholderText;
    }
  }

  return (
    <ButtonStyled
      className="py-0 btn-popover-select"
      {...rest}
    >
      <Row className="m-0 p-0 align-items-center">
        <Col className={`p-0 ${'text-right'}`}>
          {customValue || label || 'Data'}
        </Col>
        {newStartDate
          && (
            <Typography className="c-dark hover-blue text-muted mb-0" onClick={resetDateScheduleFilter}>
              <FiX size="14" className="ml-3" />
            </Typography>
          )}
        <FaChevronDown size="11" color="#7489a8" className="ml-3" />
      </Row>
    </ButtonStyled>
  );
});

const DatePicker = forwardRef((
  {
    name,
    label,
    startDate,
    endDate,
    selectsRange,
    inputReadOnly,
    placeholderText,
    isPopover,
    bgColor,
    resetDateScheduleFilter,
    ...rest
  },
  ref,
) => (
  <ReactDatePicker
    locale="pt"
    dateFormat={dateFormat}
    ref={ref}
    customInput={
      isPopover
        ? (
          <PopoverInput
            startDate={startDate}
            endDate={endDate}
            placeholderText={placeholderText}
            readOnly={inputReadOnly}
            selectsRange
            bgColor={bgColor}
            resetDateScheduleFilter={resetDateScheduleFilter}
            label={label}
          />
        ) : (
          <CustomInput
            startDate={startDate}
            endDate={endDate}
            placeholderText={placeholderText}
            readOnly={inputReadOnly}
            selectsRange
            bgColor={bgColor}
            resetDateScheduleFilter={resetDateScheduleFilter}
          />
        )
    }
    selectsRange={selectsRange}
    startDate={startDate}
    endDate={endDate}
    {...rest}
  />
));
export default DatePicker;
