
export const roleOptions = [
  {
    value: 'Sócio(a)',
    label: 'Sócio(a)',
  },
  {
    value: 'Diretor(a)',
    label: 'Diretor(a)',
  },

  {
    value: 'Gerente',
    label: 'Gerente',
  },
  {
    value: 'Coordenador',
    label: 'Coordenador',
  },
  {
    value: 'Analista',
    label: 'Analista',
  },
  {
    value: 'Estagiário',
    label: 'Estagiário',
  },
  {
    value: 'Outro',
    label: 'Outro',
  },
];

export const clientTypeOptions = [
  { value: 'B2C', label: 'Vendemos para o consumidor final - B2C' },
  { value: 'B2B', label: 'Vendemos para empresas - B2B' },
  { value: 'B2G', label: 'Vendemos para o governo - B2G' },
];

export const heardOptions = [
  { value: 'Amigo', label: 'Amigo' },
  { value: 'Cursos', label: 'Cursos' },
  { value: 'Eduzz', label: 'Eduzz' },
  { value: 'Google', label: 'Google' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Portal Administradores', label: 'Portal Administradores' },
  { value: 'Reportagens', label: 'Reportagens' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Outro', label: 'Outro' },
];

export const benefitOptions = [
  { value: 'Vender mais', label: 'Vender mais' },
  { value: 'Melhorar experiência dos meus clientes', label: 'Melhorar experiência dos meus clientes' },
  { value: 'Ter mais controle dos indicadores do meu negócio', label: 'Ter mais controle dos indicadores do meu negócio' },
  { value: 'Profissionalizar a gestão da minha empresa', label: 'Profissionalizar a gestão da minha empresa' },
  { value: 'Ganhar produtividade para minha empresa', label: 'Ganhar produtividade para minha empresa' },
  { value: 'Fazer networking', label: 'Fazer networking' },
  { value: 'Outro', label: 'Outro' },
];

export const teamOptions = [
  { value: 'Trabalho com equipe fixa', label: 'Trabalho com equipe fixa' },
  { value: 'Trabalho com equipe fixa, mas também contrato freelancers', label: 'Trabalho com equipe fixa, mas também contrato freelancers' },
  { value: 'Somente contrato freelancers para as demandas', label: 'Somente contrato freelancers para as demandas' },
  { value: 'Trabalho sozinho', label: 'Trabalho sozinho' },
];

export const expertizeLevelOptions = [
  { value: 'Iniciante: Sou novo no mercado', label: 'Iniciante: Sou novo no mercado' },
  { value: 'Intermediário: Meu negócio online traz um bom retorno financeiro', label: 'Intermediário: Meu negócio online traz um bom retorno financeiro' },
  { value: 'Avançado: Já fiz lançamentos grandes e gerencio grandes contas', label: 'Avançado: Já fiz lançamentos grandes e gerencio grandes contas' },
  { value: 'Corporativo: Expandi meu negócio e somos autoridade no mercado', label: 'Corporativo: Expandi meu negócio e somos autoridade no mercado' },
];

export const expertizeLevelClintOptions = [
  { value: 'Iniciante: Sou novo no mercado', label: 'Iniciante: Sou novo no mercado' },
  { value: 'Intermediário: Meu negócio online traz um bom retorno financeiro', label: 'Intermediário: Meu negócio online traz um bom retorno financeiro' },
  { value: 'Avançado: Já tenho ótimos cases e atendo grandes clientes', label: 'Avançado: Já tenho ótimos cases e atendo grandes clientes' },
];

export const productSellOptions = [
  { value: 'Infoprodutos', label: 'Infoprodutos', key: 'INFOPRODUCTS', order: 2 },
  { value: 'Produtos físicos', label: 'Produtos físicos', key: 'PHYSICAL', order: 0 },
  { value: 'Eventos presenciais', label: 'Eventos presenciais', key: 'EVENTS', order: 3 },
  { value: 'Serviços', label: 'Serviços (consultorias, mentorias, treinamentos...)', key: 'SERVICES', order: 1 },
  { value: 'Software/Aplicativo', label: 'Software/Aplicativo', key: 'SOFTWARE', order: 4 },
];

export const productSellClientOptions = [
  ...productSellOptions,
  { value: 'none', label: 'Nenhuma das alternativas anteriores', key: 'none', order: 99 },
];

export const productSellClintOptions = [
  { value: 'Infoprodutos', label: 'Infoprodutos', key: 'INFOPRODUCTS' },
  { value: 'Produtos físicos', label: 'Produtos físicos', key: 'PHYSICAL' },
  { value: 'Eventos presenciais', label: 'Eventos presenciais', key: 'EVENTS' },
  { value: 'Serviços', label: 'Serviços (consultorias, treinamentos, restaurantes, construção, saúde, contadores, agências, etc.)', key: 'SERVICES', },
  { value: 'Software/Aplicativo', label: 'Software/Aplicativo', key: 'SOFTWARE' },
];

export const indicationOptions = [
  { value: 'Ana Tex', label: 'Ana Tex' },
  { value: 'Maxsuell Inicius', label: 'Maxsuell Inicius' },
  { value: 'Victor Ventura', label: 'Victor Ventura' },
  { value: 'Natanael Oliveira', label: 'Natanael Oliveira' },
  { value: 'Eduzz', label: 'Eduzz' },
  { value: 'Clint', label: 'Clint' },
  { value: 'Outro', label: 'Outro' },
];

export const infoServiceOptions = [
  { value: 'Assessoria de imprensa', label: 'Assessoria de imprensa' },
  { value: 'Branding e posicionamento', label: 'Branding e posicionamento' },
  { value: 'Copywriting', label: 'Copywriting' },
  { value: 'Comunicação offline', label: 'Comunicação offline (rádio, tv, mídia impressa, outdoor, etc.)' },
  { value: 'Consultoria/Mentoria em marketing digital', label: 'Consultoria/Mentoria em marketing digital' },
  { value: 'Consultoria/Mentoria em vendas', label: 'Consultoria/Mentoria em vendas' },
  { value: 'Consultoria/Mentoria em atendimento e experiência do cliente', label: 'Consultoria/Mentoria em atendimento e experiência do cliente' },
  { value: 'Desenvolvimento de sites e landing pages', label: 'Desenvolvimento de sites e landing pages' },
  { value: 'Design gráfico', label: 'Design gráfico' },
  { value: 'E-mail marketing', label: 'E-mail marketing' },
  { value: 'Eventos presenciais', label: 'Eventos presenciais' },
  { value: 'Gestão de parceiros e afiliados', label: 'Gestão de parceiros e afiliados' },
  { value: 'Gestão de redes sociais', label: 'Gestão de redes sociais (social media)' },
  { value: 'Inbound marketing', label: 'Inbound marketing' },
  { value: 'Outboud marketing ', label: 'Outboud marketing (prospecção ativa)' },
  { value: 'Produção de conteúdo online', label: 'Produção de conteúdo online (blog posts, e-books, infográficos, etc.)' },
  { value: 'Produção/Edição de vídeos', label: 'Produção/Edição de vídeos' },
  { value: 'SEO', label: 'SEO (tráfego orgânico)' },
  { value: 'Tráfego pago Facebook/Instagram Ads', label: 'Tráfego pago Facebook/Instagram Ads' },
  { value: 'Tráfego pago Google Ads', label: 'Tráfego pago Google Ads' },
  { value: 'Tráfego pago LinkedIn/Ads', label: 'Tráfego pago LinkedIn/Ads' },
  { value: 'Vendas Foco em lançamento', label: 'Vendas Foco em lançamento' },
  { value: 'Vendas Foco no perpétuo', label: 'Vendas Foco no perpétuo' },
  { value: 'Outros', label: 'Outros' },
];


const specialist = [
  { value: 'Marketing Digital', label: 'Marketing Digital' },
  { value: 'Coaching', label: 'Coaching' },
  { value: 'Alimentação em geral', label: 'Alimentação em geral' },
  { value: 'Consultorias e Treinamentos', label: 'Consultorias e Treinamentos' },
  { value: 'Contábil', label: 'Contábil' },
  { value: 'Engenharia e Indústria', label: 'Engenharia e Indústria' },
  { value: 'Espiritualidade', label: 'Espiritualidade' },
  { value: 'Eventos', label: 'Eventos' },
  { value: 'E-commerce', label: 'E-commerce' },
  { value: 'Financeiro', label: 'Financeiro' },
  { value: 'Gastronomia', label: 'Gastronomia' },
  { value: 'Jurídico', label: 'Jurídico' },
  { value: 'Imobiliário', label: 'Imobiliário' },
  { value: 'Investimentos', label: 'Investimentos' },
  { value: 'Mídia e Comunicação', label: 'Mídia e Comunicação' },
  { value: 'Negócios e business', label: 'Negócios e business' },
  { value: 'Ongs', label: 'Ongs' },
  { value: 'Saúde e Estética', label: 'Saúde e Estética' },
  { value: 'Serviços em RH', label: 'Serviços em RH' },
  { value: 'Softwares', label: 'Softwares' },
  { value: 'Startups', label: 'Startups' },
  { value: 'Trader', label: 'Trader' },
  { value: 'Turismo e Lazer', label: 'Turismo e Lazer' },
  { value: 'Varejo', label: 'Varejo' },
  { value: 'Outros', label: 'Outros' },
];

export const specialistOptions = [
  { value: 'Atendo clientes em qualquer segmento', label: 'Atendo clientes em qualquer segmento' },
  ...specialist,
];

export const specialistClientOptions = [
  ...specialist,
];

export const coproductionOptions = [
  { value: 'Não atuo com co-produção', label: 'Não atuo com co-produção', key: 'NO_COPRODUCTION', step: 1 },
  { value: 'Atuo somente com co-produção', label: 'Atuo somente com co-produção', key: 'ONLY_COPRODUCTION', step: 2 },
  { value: 'Em alguns casos atuo com co-produção', label: 'Em alguns casos atuo com co-produção', key: 'SOME_COPRODUCTION', step: 3 },
];

export const coproductionClintOptions = [
  { value: 'Não atuo com projeto variável', label: 'Não atuo com projeto variável', key: 'NO_COPRODUCTION', step: 1 },
  { value: 'Atuo somente com projeto variável', label: 'Atuo somente com projeto variável', key: 'ONLY_COPRODUCTION', step: 2 },
  { value: 'Em alguns casos atuo com projeto variável', label: 'Em alguns casos atuo com projeto variável', key: 'SOME_COPRODUCTION', step: 3 },
];

export const partnerPaymentOptions = [
  {
    key: 'RECURRENCE',
    value: 'Pagamento mensal / recorrente',
    text: 'Pagamento mensal / recorrente',
    matches: ['NO_COPRODUCTION', 'SOME_COPRODUCTION'],
  },
  {
    key: 'SINGLE',
    value: 'Pagamento fixo / pontual',
    text: 'Pagamento fixo / pontual',
    matches: ['NO_COPRODUCTION', 'SOME_COPRODUCTION'],
  },
  {
    key: 'VARIABLE',
    value: 'Pagamento variável / co-produção',
    text: 'Pagamento variável / co-produção',
    matches: ['ONLY_COPRODUCTION', 'SOME_COPRODUCTION'],
  },
  {
    key: 'DONT_KNOW',
    value: 'Ainda não sei',
    text: 'Ainda não sei',
    matches: ['ONLY_COPRODUCTION', 'NO_COPRODUCTION', 'SOME_COPRODUCTION'],
  },
];


export const coproductionCharge = [
  { value: '10%', label: '10%' },
  { value: '20%', label: '20%' },
  { value: '30%', label: '30%' },
  { value: '40%', label: '40%' },
  { value: '50%', label: '50%' },
  { value: '60%', label: '60%' },
  { value: '70%', label: '70%' },
  { value: '80%', label: '80%' },
  { value: '90%', label: '90%' },
]

export const coproductionChargeOptions = [
  ...coproductionCharge,
];
export const coproductionChargeClientOptions = [
  ...coproductionCharge,
  { value: 'Ainda não sei, estou disposto a negociar', label: 'Ainda não sei, estou disposto a negociar' },
];

export const recurrenceOptions = [
  { value: 'Até R$1.000,00/mês', label: 'Até R$1.000,00/mês' },
  { value: 'R$1.000,00/mês a R$2.500,00/mês', label: 'R$1.000,00/mês a R$2.500,00/mês' },
  { value: 'R$2.500,00/mês a R$5.000,00/mês', label: 'R$2.500,00/mês a R$5.000,00/mês' },
  { value: 'R$5.000,00/mês a R$10.000,00/mês', label: 'R$5.000,00/mês a R$10.000,00/mês' },
  { value: 'R$10.000,00/mês a R$25.000,00/mês', label: 'R$10.000,00/mês a R$25.000,00/mês' },
  { value: 'R$25.000,00/mês a R$50.000,00/mês', label: 'R$25.000,00/mês a R$50.000,00/mês' },
  { value: 'Acima de R$50.000,00/mês', label: 'Acima de R$50.000,00/mês' },
]
export const recurrenceTicketOptions = [
  ...recurrenceOptions,
  { value: 'Não atendo clientes que me pagam de forma recorrente', label: 'Não atendo clientes que me pagam de forma recorrente' },
];

export const recurrenceTicketClientOptions = [
  ...recurrenceOptions,
  { value: 'Ainda não sei, estou disposto a negociar.', label: 'Ainda não sei, estou disposto a negociar.', key: 'none' },
];

export const singleOptions = [
  { value: 'Até R$1.000,00', label: 'Até R$1.000,00', key: "UNTIL_1000" },
  { value: 'R$1.000,00 a R$2.500,00', label: 'R$1.000,00 a R$2.500,00', key: "1000_TO_2500" },
  { value: 'R$2.500,00 a R$5.000,00', label: 'R$2.500,00 a R$5.000,00', key: "2500_TO_5000" },
  { value: 'R$5.000,00 a R$10.000,00', label: 'R$5.000,00 a R$10.000,00', key: "5000_10000" },
  { value: 'R$10.000,00 a R$25.000,00', label: 'R$10.000,00 a R$25.000,00', key: "10000_25000" },
  { value: 'R$25.000,00 a R$50.000,00', label: 'R$25.000,00 a R$50.000,00', key: "25000_TO_50000" },
  { value: 'Acima de R$50.000,00', label: 'Acima de R$50.000,00', key: "OVER_50000" },
];

export const singleTicketOptions = [
  ...singleOptions,
  { value: 'Não atendo clientes que me pagam de forma pontual.', label: 'Não atendo clientes que me pagam de forma pontual.', key: 'none' },
];
export const singleTicketClientOptions = [
  ...singleOptions,
  { value: 'Ainda não sei, estou disposto a negociar.', label: 'Ainda não sei, estou disposto a negociar.', key: 'none' },
];


export const eduzzEliteOptions = [
  { value: 'White Belt', label: 'White Belt', key: "WHITE_BELT" },
  { value: 'Red Belt', label: 'Red Belt', key: "RED_BELT" },
  { value: 'Orange Belt', label: 'Orange Belt', key: "ORANGE_BELT" },
  { value: 'Green Belt', label: 'Green Belt', key: "GREEN_BELT" },
  { value: 'Black Belt', label: 'Black Belt', key: "BLACK_BELT" },
  { value: 'Black Belt +', label: 'Black Belt +', key: "BLACK_BELT_PLUS" },
]
export const eduzzEliteClientOptions = [
  ...eduzzEliteOptions,
  { value: 'Ainda não faço parte', label: 'Ainda não faço parte', key: "NOT_PART" },
];

export const fitCategoryOptions = [
  { value: 'Sou um produtor digital', label: 'Sou um produtor digital', key: "INFOPRODUCER" },
  { value: 'Sou um afiliado e revendo produtos digitais', label: 'Sou um afiliado e revendo produtos digitais', key: "AFFILIATE" },
  { value: 'Sou uma agência ou "eugência"', label: 'Sou uma agência ou "eugência"', key: "AGENCY" },
  { value: 'Tenho um negócio físico', label: 'Tenho um negócio físico', key: "PHYSICAL" },
];
