import { Button, Typography } from '@partners-core/components';
import { Navbar, NavDropdown, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const NavbarHeader = styled(Navbar)`
  z-index: 1033 !important;
  .nav-item{
    .text{
      display: none;
    }
    .logo{
      display: flex;
    }
    :hover{
      .text{
        display: flex;
      }
      .logo{
        display: none;
      }
    }
  }
`;

export const NavbarBottom = styled(Navbar)`
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1031;
`;

export const HideBalance = styled(Button)`
  background: #f7f4ff;
  border-radius: 6px;
  width: 70px;
  height: 20px;
  border: none;
`;

export const MenuTitle = styled(Typography)`
  letter-spacing: 2px;
`;

export const NavDropdownMore = styled(NavDropdown)`
  .dropdown-menu {
    border: none !important;
  }
`;

export const MenuRow = styled(Row)`
  display: flex;
  min-width: 600px; 
  border: none;
`;

export const CampaignRow = styled(Row)`
  display: flex;
  min-width: 230; 
  border: none;
`;

export const ButtonPlugin = styled(Row)`
  background: #EDF3FF;
  border-radius: 301.667px;
  border: none;
`;
