import React, { useState, forwardRef, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Combobox from 'react-widgets/Combobox';
import 'react-widgets/styles.css';

const Input = forwardRef((
  { name, label, mask, onChange = null, onSelect = null, className, labelClassName, floatLabel, combobox = false, ...props }, ref,
) => {
  const [active, setActive] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    if (onChange) onChange(e);

    setActive(value !== '');
  };

  const handleChangeValue = (value) => {
    if (onChange) onChange(value);
    if (value instanceof Object && onSelect) {
      onSelect();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (ref?.current?.value) {
        setActive(!!ref?.current?.value);
      }
    }, 200);
  }, []);

  return (

    <>
      {mask && !combobox
        && (
          <>
            {floatLabel

              ? (
                <div className="form-control-float">
                  <InputMask
                    mask={mask}
                    name={name}
                    className={`form-control ${className || ''} ${active ? 'active' : ''}`}
                    onChange={handleChange}
                    ref={ref}
                    {...props}
                  />
                  <label htmlFor={name} className={labelClassName || ''}>
                    {floatLabel}
                  </label>
                </div>

              )

              : (
                <>
                  <InputMask
                    mask={mask}
                    name={name}
                    className={`form-control ${className || ''} ${active ? 'active' : ''}`}
                    onChange={handleChange}
                    ref={ref}
                    {...props}
                  />
                </>

              )}
          </>
        )}

      {(!mask && !floatLabel && !combobox)
        && (
          <input
            className={`form-control ${className || ''} ${active ? 'active' : ''}`}
            onChange={handleChange}
            name={name}
            ref={ref}
            {...props}
          />
        )}

      {(!mask && floatLabel && !combobox)
        && (
          <div className="form-control-float">
            <input
              className={`form-control ${className || ''} ${active ? 'active' : ''}`}
              onChange={handleChange}
              name={name}
              ref={ref}
              {...props}
            />
            <label htmlFor={name} className={labelClassName || ''}>
              {floatLabel}
            </label>
          </div>
        )}

      {(!mask && combobox)
        && (
          <Combobox
            className={`${className || ''} ${active ? 'active' : ''}`}
            onChange={handleChangeValue}
            name={name}
            ref={ref}
            hideCaret
            hideEmptyPopup
            {...props}
          />
        )}

    </>

  );
});

export default Input;
