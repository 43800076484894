import partners from '@partners-core/partners-js';

import storage from '../util/storage';

let USER = {};
let TOKEN = null;

if (!TOKEN) {
  const user = storage.get(storage.enums.AUTH_USER);
  if (user) {
    USER = user;
    TOKEN = user.token;
  }
}

function setUser(user) {
  window.auth.USER = user;
  window.auth.TOKEN = user.token;
  storage.set(storage.enums.AUTH_USER, user);
  configureClintJs(user.token);
}

function setToken(token) {
  window.auth.TOKEN = token;
  configureClintJs(token);
}

function isAuthenticated() {
  return window.auth.TOKEN !== null;
}

function configureClintJs(token) {
  partners.configure({
    baseURL: process.env.REACT_APP_WEB_API_URL,
    logger: false,
  });
  partners.authenticate(`Bearer ${token}`);
}

function logout() {
  window.auth.USER = {};
  window.auth.TOKEN = null;
  storage.set(storage.enums.AUTH_USER, {});
  localStorage.clear();
  window.localStorage.clear();
}

const auth = { USER, TOKEN, setUser, setToken, logout, isAuthenticated };
export default window.auth = auth;
