import * as yup from 'yup';
/* eslint-disable no-template-curly-in-string */
yup.setLocale({
  mixed: {
    default: 'Não válido',
    required: 'Campo obrigatório',
    oneOf: 'Necessário ser um dos seguintes valores: ${values}',
    notOneOf: 'Não deve ser um dos seguintes valores: ${values}',
    defined: 'Necessário ser definido',
  },
  string: {
    length: 'Necessário ter exatamente ${length} caracteres',
    min: 'Necessário ter pelo menos ${min} caracteres',
    max: 'Necessário ter no máximo ${max} caracteres',
    matches: 'Necessário corresponder ao seguinte: "${regex}"',
    email: 'Necessário ser um email válido',
    url: 'Necessário ser um URL válido',
    uuid: 'Necessário ser um UUID válido',
    trim: 'Necessário ser uma string aparada',
    lowercase: 'Necessário ser uma string minúscula',
    uppercase: 'Necessário ser uma string maiúscula',
  },
  number: {
    min: 'Necessário ser maior ou igual a ${min}',
    max: 'Necessário ser menor ou igual a ${max}',
    lessThan: 'Necessário ser menor que ${less}',
    moreThan: 'Necessário ser maior que ${more}',
    notEqual: 'Não deve ser igual a ${notEqual}',
    positive: 'Necessário ser um número positivo',
    negative: 'Necessário ser um número negativo',
    integer: 'Necessário ser um inteiro',
  },
  date: {
    min: 'Necessário ser posterior a ${min}',
    max: 'Necessário ser anterior a ${max}',
  },
  object: {
    noUnknown: 'Chaves não especificadas: ${unknown}',
  },
  array: {
    min: 'Necessário ter pelo menos ${min} itens',
    max: 'Necessário ter menos ou igual a ${max} itens',
  },
});

export default yup;
