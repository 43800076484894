/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updatePasswordMutationVariables = {|
  email: string,
  old_password: string,
  new_password: string,
|};
export type updatePasswordMutationResponse = {|
  +update_password: ?{|
    +isChanged: boolean
  |}
|};
export type updatePasswordMutation = {|
  variables: updatePasswordMutationVariables,
  response: updatePasswordMutationResponse,
|};
*/


/*
mutation updatePasswordMutation(
  $email: String!
  $old_password: String!
  $new_password: String!
) {
  update_password(email: $email, old_password: $old_password, new_password: $new_password) {
    isChanged
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "old_password",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "new_password",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "new_password",
        "variableName": "new_password"
      },
      {
        "kind": "Variable",
        "name": "old_password",
        "variableName": "old_password"
      }
    ],
    "concreteType": "ChangePasswordOutput",
    "kind": "LinkedField",
    "name": "update_password",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isChanged",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updatePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation updatePasswordMutation(\n  $email: String!\n  $old_password: String!\n  $new_password: String!\n) {\n  update_password(email: $email, old_password: $old_password, new_password: $new_password) {\n    isChanged\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3648bd54416f0334d94f5cd18f184e21';

module.exports = node;
