/* eslint-disable import-helpers/order-imports */
import { Form as Unform } from '@unform/web';
import { Widget } from '@uploadcare/react-widget';
import { cloneDeep, times } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Modal, Table } from 'react-bootstrap';
import styled from 'styled-components';

import {
  specialistOptions, infoServiceOptions, coproductionOptions, expertizeLevelOptions,
  recurrenceTicketOptions,
  singleTicketOptions,
  teamOptions,
} from '@partners-core/commons/src/options';
import { Button, Form, TranslucentStyle } from '@partners-core/components';
import { AuthContext } from '@/context/AuthContext';
import {
  productSellOptions,
  coproductionChargeOptions,
  indicationOptions,
} from '@/util/utils';
import yup from '@/util/yup';
import { ReactComponent as EduzzLogo } from '@/assets/img/brand/eduzz-logo.svg';
import updateOwner from '@/api/mutation/owner/update-owner';

const schemaValidation = yup.object().shape({
  team: yup
    .string()
    .required(),
  expertize_level: yup
    .string()
    .required(),
  product_sell: yup
    .string()
    .required(),
  specialties: yup
    .string()
    .required(),
  services: yup
    .string()
    .required(),
});

const schemaValidationCharge = yup.object().shape({
  coproduction: yup
    .string()
    .required(),
});

const FormGroupYellow = styled(Form.Group)`
  .react-select__multi-value{
    background-color: hsl(43deg 91% 61%);
    color: white;
  }
  .react-select__multi-value__label{
    color: white;
    font-weight: 400;
  }
`;

const EduzzModal = ({ showModal, setShowModal, ownerId, userId, firstName, qualification, onComplete, edit }) => {
  const formRef = useRef(null);
  const { user, setUser } = useContext(AuthContext);
  const formRefCharge = useRef(null);
  const [step, setStep] = useState(edit ? 1 : 0);
  const [chargeStep, setChargeStep] = useState(0);
  const [partnerData, setPartnerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState(qualification?.eduzz?.attachments);

  const [servicesOther, setServicesOther] = useState(false);
  const [specialtiesOther, setSpecialtiesOther] = useState(false);
  const [indicationOther, setIndicationOther] = useState(false);
  const fillData = qualification?.eduzz?.fill_data;
  const widgetApi = useRef();

  const addAttachment = (data) => {
    setAttachments({
      uuid: data.uuid,
      count: data.count,
      name: data.name,
      files: times(data.count).map((i) => ({ url: `${data.cdnUrl}nth/${i}/` })),
    });
  };

  const handleFileChange = (group) => {
    addAttachment(group);
  };

  useEffect(() => {
    setServicesOther(!!qualification?.eduzz?.services?.find((o) => o === 'Outros'));
    setSpecialtiesOther(!!qualification?.eduzz?.specialties?.find((o) => o === 'Outros'));
    setIndicationOther(qualification?.eduzz?.indication === 'Outro');
    const coprod = coproductionOptions.find((o) => o.value === qualification?.eduzz?.coproduction);
    setChargeStep(coprod?.step || 0);
  }, []);

  const handleSubmit = async (data) => {
    const validationErrors = {};
    formRef.current.setErrors({});
    if (!loading) {
      setLoading(true);

      try {
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
        setPartnerData(data);

        setStep(2);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
        }
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleSubmitCharge = async (data) => {
    const validationErrors = {};
    formRefCharge.current.setErrors({});

    if (!loading) {
      setLoading(true);

      try {
        await schemaValidationCharge.validate(data, {
          abortEarly: false,
        });

        let valid = true;

        if ((chargeStep === 2 || chargeStep === 3) && !data.coproduction_charge) {
          validationErrors.coproduction_charge = 'Campo obrigatório';
          valid = false;
        }

        if (chargeStep === 1 || chargeStep === 3) {
          if (!data.recurrence_ticket) {
            validationErrors.recurrence_ticket = 'Campo obrigatório';
            valid = false;
          }

          if (!data.single_ticket) {
            validationErrors.single_ticket = 'Campo obrigatório';
            valid = false;
          }
        }

        if (valid) {
          const users = qualification?.eduzz?.users ? cloneDeep(qualification?.eduzz?.users) : [];
          users.push(userId);
          const newQualification = {
            eduzz: {
              ...partnerData,
              ...data,
              attachments,
              users,
              fill_data: true,
            },
          };
          const ownerData = {
            id: ownerId,
            data: {},
            append: {
              qualification: newQualification,
            },
          };

          setUser({
            ...user,
            owner: {
              ...user.owner,
              qualification: newQualification,
            },
          });

          await updateOwner(ownerData);

          if (edit) {
            finalize();
          } else {
            setStep(3);
          }
        } else {
          formRefCharge.current.setErrors(validationErrors);
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
        }
        formRefCharge.current.setErrors(validationErrors);
      }
    }
  };

  const finalize = async () => {
    if (!edit && step === 4 && fillData) {
      const users = qualification.eduzz?.users ? cloneDeep(qualification.eduzz?.users) : [];
      users.push(userId);
      const newQualification = {
        eduzz: {
          ...qualification.eduzz,
          users,
        },
      };
      const ownerData = {
        id: ownerId,
        data: {},
        append: {
          qualification: newQualification,
        },
      };

      setUser({
        ...user,
        owner: {
          ...user.owner,
          qualification: newQualification,
        },
      });

      await updateOwner(ownerData);
    }

    setLoading(true);
    setShowModal();
    onComplete();
  };

  const handleChangeServices = (options) => {
    setServicesOther(options ? options.find((o) => o.value === 'Outros') : false);
  };

  const handleChangeSpecialties = (options) => {
    setSpecialtiesOther(options ? options.find((o) => o.value === 'Outros') : false);
  };

  const handleChangeIndication = (option) => {
    setIndicationOther(option.value === 'Outro');
  };

  const getDefaultValues = (values) => values?.map((v) => ({ value: v, label: v }));

  return (
    <Modal show={showModal} onHide={setShowModal} centered size={step === 4 ? 'xl' : 'lg'}>
      <Card className="modal-card">
        <Card.Body>
          {step === 0

            && (
              <Row className="align-items-center justify-content-center">
                <Col xs="12" lg="10">
                  <div className="header">
                    <div className="header-body b-0">
                      <div className="display-4 text-center">

                        <EduzzLogo width={120} />
                      </div>
                      <h1 className="header-title c-dark text-center mt-4 bold mt-md-5">
                        Parabéns
                        {' '}
                        {firstName}
                        !
                      </h1>
                      <p className="header-title c-dark text-center mt-4 font-weight-regular">
                        A origem Eduzz foi ativada para o seu negócio.
                        A partir de agora, você receberá por aqui indicações de clientes que precisam de ajuda de prestadores de serviços como você.
                      </p>
                    </div>
                  </div>

                </Col>
                <Col xs="12" lg="10" className="text-right mt-3 mb-6">
                  <Button variant="warning" style={{ color: 'white' }} type="submit" block onClick={() => {
                    if (fillData && !edit) {
                      setStep(3);
                    } else {
                      setStep(1);
                    }
                  }}
                  >
                    Começar agora
                  </Button>
                </Col>
              </Row>
            )}
          {step === 1
            && (

              <Unform ref={formRef} onSubmit={handleSubmit}
                initialData={{
                  name: qualification?.eduzz?.name,
                  team: qualification?.eduzz?.team,
                  expertize_level: qualification?.eduzz?.expertize_level,
                  product_sell: qualification?.eduzz?.product_sell,
                  specialties: qualification?.eduzz?.specialties,
                  other_specialties: qualification?.eduzz?.other_specialties,
                  services: qualification?.eduzz?.services,
                  other_services: qualification?.eduzz?.other_services,
                  indication: qualification?.eduzz?.indication,
                  other_indication: qualification?.eduzz?.other_indication,
                }}
              >

                <Row className="align-items-center justify-content-center">
                  <Col xs="12" lg="10">
                    <div className="header">
                      <div className="header-body b-0">
                        <div className="display-4 text-center">
                          <EduzzLogo width={100} />
                        </div>
                        <p className="header-title c-dark text-center mt-4 font-weight-regular">
                          Para que possamos direcionar clientes que estejam mais próximos do perfil do seu negócio, precisamos de algumas informações.
                        </p>
                      </div>
                    </div>

                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Qual seu formato de trabalho?
                      </Form.Label>
                      <Form.Select
                        name="team"
                        isClearable
                        noOptionsMessage={() => 'Nada encontrado...'}
                        options={teamOptions}
                        menuPortalTarget={document.body}
                        styles={TranslucentStyle}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Qual o seu nível de experiência com vendas online?
                      </Form.Label>
                      <Form.Select
                        name="expertize_level"
                        isClearable
                        noOptionsMessage={() => 'Nada encontrado...'}
                        menuPortalTarget={document.body}
                        options={expertizeLevelOptions}
                        styles={TranslucentStyle}
                      />
                    </Form.Group>
                    <FormGroupYellow className="mt-3">
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Que tipo de produto você ajuda a vender?
                      </Form.Label>
                      <Form.Select
                        name="product_sell"
                        isMulti
                        isClearable
                        defaultValue={getDefaultValues(qualification?.eduzz?.product_sell)}
                        noOptionsMessage={() => 'Nada encontrado...'}
                        closeMenuOnSelect={false}
                        menuPortalTarget={document.body}
                        options={productSellOptions}
                        styles={TranslucentStyle}
                      />
                    </FormGroupYellow>
                    <FormGroupYellow className="mt-3">
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Você é especializado em algum mercado?
                      </Form.Label>
                      <Form.Select
                        name="specialties"
                        defaultValue={getDefaultValues(qualification?.eduzz?.specialties)}
                        isMulti
                        isClearable
                        noOptionsMessage={() => 'Nada encontrado...'}
                        closeMenuOnSelect={false}
                        menuPortalTarget={document.body}
                        options={specialistOptions}
                        onChange={handleChangeSpecialties}
                        styles={TranslucentStyle}
                      />
                    </FormGroupYellow>
                    {specialtiesOther
                      && (
                        <Form.Group>
                          <Form.Label className="font-size-md font-weight-normal c-dark">
                            Qual
                            {' '}
                            <b>outro(s)</b>
                            {' '}
                            mercados você é especializado?
                          </Form.Label>
                          <Form.Input
                            name="other_specialties"
                            placeholder="Escreva sua resposta aqui..."
                            className="form-control-translucent form-control-md c-dark alive bold"
                          />
                        </Form.Group>
                      )}

                    <FormGroupYellow className="mt-3">
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Qual tipo de serviço você presta?
                      </Form.Label>
                      <Form.Select
                        name="services"
                        isMulti
                        defaultValue={getDefaultValues(qualification?.eduzz?.services)}
                        isClearable
                        noOptionsMessage={() => 'Nada encontrado...'}
                        closeMenuOnSelect={false}
                        menuPortalTarget={document.body}
                        options={infoServiceOptions}
                        onChange={handleChangeServices}
                        styles={TranslucentStyle}
                      />
                    </FormGroupYellow>
                    {servicesOther
                      && (
                        <Form.Group>
                          <Form.Label className="font-size-md font-weight-normal c-dark">
                            Qual
                            {' '}
                            <b>outro(s)</b>
                            {' '}
                            serviço você presta?
                          </Form.Label>
                          <Form.Input
                            name="other_services"
                            placeholder="Escreva sua resposta aqui..."
                            className="form-control-translucent form-control-md c-dark alive bold"
                          />
                        </Form.Group>
                      )}

                    <Form.Group className="mt-3">
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Quem te indicou?
                      </Form.Label>
                      <Form.Select
                        name="indication"
                        isClearable
                        noOptionsMessage={() => 'Nada encontrado...'}
                        menuPortalTarget={document.body}
                        options={indicationOptions}
                        onChange={handleChangeIndication}
                        styles={TranslucentStyle}
                      />
                    </Form.Group>
                    {indicationOther
                      && (
                        <Form.Group>
                          <Form.Label className="font-size-md font-weight-normal c-dark">
                            Qual
                            {' '}
                            <b>outro(s)?</b>
                          </Form.Label>
                          <Form.Input
                            name="other_indication"
                            placeholder="Escreva sua resposta aqui..."
                            className="form-control-translucent form-control-md c-dark alive bold"
                          />
                        </Form.Group>
                      )}

                    <Form.Group className="mt-3">
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Importe seus certificados
                      </Form.Label>
                      <div>
                        <Widget
                          value={attachments?.uuid}
                          ref={widgetApi}
                          onChange={handleFileChange}
                          multiple
                          publicKey="139b945cf0001a8ab8c0"
                          locale="pt"
                          tabs="file url gdrive"
                        />
                        <Button variant="secondary" className="text-left c-purple font-size-sm" onClick={() => widgetApi.current.openDialog()}>
                          {attachments?.name || 'Adicionar arquivos'}
                        </Button>
                        {attachments
                          && (
                            <Button variant="link" className="font-size-sm"
                              onClick={() => {
                                setAttachments(null);
                              }}
                            >
                              {' '}
                              remover
                            </Button>
                          )}

                      </div>

                    </Form.Group>

                  </Col>
                  <Col xs="12" lg="10" className="text-right mt-4 mb-6">
                    <Button variant="warning" style={{ color: 'white' }} type="submit" block loading={loading}>
                      Salvar e continuar
                    </Button>
                  </Col>

                </Row>

              </Unform>
            )}

          {step === 2
            && (

              <Unform ref={formRefCharge} onSubmit={handleSubmitCharge}
                initialData={{
                  coproduction: qualification?.eduzz?.coproduction,
                  coproduction_charge: qualification?.eduzz?.coproduction_charge,
                  recurrence_ticket: qualification?.eduzz?.recurrence_ticket,
                  single_ticket: qualification?.eduzz?.single_ticket,
                }}
              >

                <Row className="align-items-center justify-content-center">
                  <Col xs="12" lg="10">
                    <div className="header">
                      <div className="header-body b-0">
                        <div className="display-4 text-center">
                          <EduzzLogo width={100} />
                        </div>
                        <p className="header-title c-dark text-center mt-4 font-weight-regular">
                          Agora, precisamos saber um pouco com relação a forma como você cobra dos seus clientes.
                        </p>
                      </div>
                    </div>

                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-dark">
                        Você atua com projetos pagos mediante sucesso em vendas / co-produção?
                      </Form.Label>
                      <Form.Select
                        name="coproduction"
                        isClearable
                        noOptionsMessage={() => 'Nada encontrado...'}
                        options={coproductionOptions}
                        menuPortalTarget={document.body}
                        styles={TranslucentStyle}
                        onChange={(option) => (option ? setChargeStep(option.step) : setChargeStep(0))}
                      />
                    </Form.Group>

                    {(chargeStep === 2 || chargeStep === 3)

                      && (
                        <Form.Group>
                          <Form.Label className="font-size-md font-weight-normal c-dark">
                            Em média, quanto você costuma cobrar em projetos de co-produção?
                          </Form.Label>
                          <Form.Select
                            name="coproduction_charge"
                            isClearable
                            noOptionsMessage={() => 'Nada encontrado...'}
                            options={coproductionChargeOptions}
                            menuPortalTarget={document.body}
                            styles={TranslucentStyle}
                            onChange={() => formRefCharge.current.setErrors({})}

                          />
                        </Form.Group>
                      )}

                    {(chargeStep === 1 || chargeStep === 3)

                      && (
                        <>
                          <Form.Group>
                            <Form.Label className="font-size-md font-weight-normal c-dark">
                              Quanto em média você recebe por clientes recorrentes?
                            </Form.Label>
                            <Form.Select
                              name="recurrence_ticket"
                              isClearable
                              noOptionsMessage={() => 'Nada encontrado...'}
                              options={recurrenceTicketOptions}
                              menuPortalTarget={document.body}
                              styles={TranslucentStyle}
                              onChange={() => formRefCharge.current.setErrors({})}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="font-size-md font-weight-normal c-dark">
                              Quanto em média você recebe por projetos pontuais?
                            </Form.Label>
                            <Form.Select
                              name="single_ticket"
                              isClearable
                              noOptionsMessage={() => 'Nada encontrado...'}
                              options={singleTicketOptions}
                              menuPortalTarget={document.body}
                              styles={TranslucentStyle}
                              onChange={() => formRefCharge.current.setErrors({})}
                            />
                          </Form.Group>
                        </>
                      )}
                  </Col>
                  <Col xs="12" lg="10" className="text-right mt-4 mb-6">
                    <Button variant="warning" style={{ color: 'white' }} type="submit" block loading={loading}>
                      Salvar e continuar
                    </Button>
                  </Col>
                </Row>
              </Unform>
            )}

          {step === 3
            && (
              <Row className="align-items-center justify-content-center">
                <Col xs="12" lg="10">
                  <div className="header">
                    <div className="header-body b-0">
                      <div className="display-4 text-center">
                        <EduzzLogo width={100} />
                      </div>
                      <h1 className="header-title c-dark text-center mt-4 bold mb-4">
                        Política da origem Eduzz
                      </h1>
                    </div>
                    <p>
                      O sucesso da Origem Eduzz depende da boa experiência dos clientes que estão buscando especialistas como você.
                      Portanto, esperamos que todos que fazem parte sigam as diretrizes a seguir.
                    </p>
                    <h3 className="header-title c-dark mt-4 bold mb-3">
                      Tempo de resposta
                    </h3>
                    <p>
                      Sempre que uma oportunidade de negócio chegar através da Eduzz, você tem até 24 horas úteis para avançá-la no seu funil de vendas.
                    </p>
                    <h3 className="header-title c-dark mt-4 bold mb-3">
                      Oportunidade sem interesse
                    </h3>
                    <p>
                      Caso a oportunidade de negócio indicada não seja do seu interesse, deverá mudar seu status para PERDIDO.
                      Você deve fazer essa qualificação o mais rápido possível, para que o cliente possa ter um
                      novo direcionamento para outro parceiro interessado. Isso ajuda a comunidade e consequentemente ajuda você.
                    </p>
                    <h3 className="header-title c-dark mt-4 bold mb-3">
                      Processo de prospecção
                    </h3>
                    <p>
                      Caso tenha interesse em fazer negócio com o cliente indicado, visando garantir qualidade e a segurança de todos,
                      você deverá efetuar todo o processo de prospecção através da Clint,
                      incluindo e-mails, ligações e registrando as atividades de Whatsapp na plataforma.
                    </p>
                    <h3 className="header-title c-dark mt-4 bold mb-3">
                      Atividades obrigatórias
                    </h3>
                    <p>
                      <ul className="mt-3">
                        <li>A apresentação de propostas para os clientes indicados pela Eduzz deve ser feita sempre através da plataforma da Clint.</li>
                        <li>
                          A contratação e cobrança da proposta contratada, deve acontecer sempre através da plataforma da Clint.
                          Assim que o cliente efetuar o pagamento, o dinheiro será transferido para a sua conta bancária.
                        </li>
                      </ul>

                    </p>
                    <h3 className="header-title c-dark mt-4 bold mb-3">
                      Contratações e renovações
                    </h3>
                    <p>
                      Como este cliente foi indicado pela Eduzz, enquanto ele atuar em parceria com a sua empresa,
                      mesmo em casos de renovação de contrato ou parcerias futuras,
                      obrigatoriamente a proposta com serviços e os pagamentos efetuados pelo cliente, devem estar ativos na plataforma.
                    </p>
                  </div>

                </Col>
                <Col xs="12" lg="10" className="text-right mt-3 mb-6">
                  <Button variant="warning" style={{ color: 'white' }} type="submit" block onClick={() => setStep(4)}>
                    Eu aceito a política da origem Eduzz
                  </Button>
                </Col>
              </Row>
            )}

          {step === 4
            && (
              <Row className="align-items-center justify-content-center">
                <Col xs="12" lg="10">
                  <div className="header">
                    <div className="header-body b-0">
                      <div className="display-4 text-center">
                        <EduzzLogo width={100} />
                      </div>
                      <h1 className="header-title c-dark text-center mt-4 bold mb-2">
                        Taxas de intermediação
                      </h1>
                    </div>
                  </div>
                  <p className="mb-5">
                    Você poderá optar por cobrar ou não dos seus clientes através da plataforma da Clint. Para configurar o que mais fizer sentido para você, acesse Opções de pagamento dentro do módulo de proposta.
                    Dando poder de escolha para o seu cliente:

                    Ao selecionar os itens cartão de crédito, boleto e pix, você estará dando poder de escolha sobre a forma de pagamento para o seu cliente.
                    Desta forma, ao fazer a contratação da sua proposta, o cliente poderá escolher se prefere efetuar o pagamento via cartão de crédito, boleto ou pix.

                  </p>
                  <p className="mb-3">
                    <b>Recebimento via pix ou boleto:</b>
                    A taxa cobrada por transação via Pix ou Boleto é de 1,99% e será abatida no momento da realização da transação.
                    Recebimentos via cartão de crédito:
                  </p>
                  <p className="mb-3">
                    <b>Recebimento via cartão de crédito:</b>
                    As taxas cobradas em operações via cartão de crédito são de 3,99% à vista,
                    de 4,19% de 2 a 6 parcelas e 4,39% para 7 parcelas ou mais,
                    e uma taxa fixa por transação de R$1,20 que serão abatidas no momento da transação.
                  </p>
                  {/* <Table responsive className="table-nowrap table-sm">
                    <thead>
                      <tr>
                        <th>Volume transacionado pelo cliente</th>
                        <th>Taxa de intermediação</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td><p className="no-margin">Até R$5.000</p></td>
                        <td><p className="no-margin">15%</p></td>
                      </tr>
                      <tr>
                        <td><p className="no-margin">Entre R$ 5.000 e R$50.000</p></td>
                        <td><p className="no-margin">10%</p></td>
                      </tr>
                      <tr>
                        <td><p className="no-margin">Acima de R$50.000</p></td>
                        <td><p className="no-margin">5%</p></td>
                      </tr>
                    </tbody>
                  </Table> */}

                </Col>
                <Col xs="12" lg="10" className="text-right mt-3 mb-6">
                  <Button variant="warning" style={{ color: 'white' }} type="submit" block onClick={finalize}>
                    Estou ciente sobre as taxas de intermediação
                  </Button>
                </Col>
              </Row>
            )}

        </Card.Body>
      </Card>

    </Modal>
  );
};

export default EduzzModal;
