import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import Textarea from '../Input/Textarea';

const FormTextarea = ({ name, label, mask, ...rest }) => {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: textareaRef.current,
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Textarea
        id={fieldName}
        name={name}
        ref={textareaRef}
        defaultValue={defaultValue}
        onFocus={clearError}
        {...rest}
      />
      {error && <span className="text-danger font-size-xs ml-2 font-weight-bold">{error}</span>}
    </>
  );
};

export default FormTextarea;
