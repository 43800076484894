/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import { parsePhoneNumber } from 'react-phone-number-input';

export const isSupportUser = (email) => email && email.includes('suporte') && email.includes('@eduzz.com');
export const sleep = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Format a phone number, adding 9 digit when it's a mobile phone
 * @param {string} phone
 * @returns {string}
 */
const normalizePhone = (phone, removeCountry = false) => {
  const phoneReplaced = String(phone).replace(/[^0-9+]/g, '');
  const regexNinthDigit = /^((?:\+?[0-9]{2})?[-\s\.]?[(]?[0-9]{2}[)]?[-\s\.]?)([6-9]{1}[0-9]{3}[-\s\.]?[0-9]{4})$/;
  const numberWithoutNinthDigit = regexNinthDigit.exec(phoneReplaced);
  if (numberWithoutNinthDigit && numberWithoutNinthDigit.length >= 3) {
    return `${numberWithoutNinthDigit[1]}9${numberWithoutNinthDigit[2]}`;
  }
  if (removeCountry) {
    return phoneReplaced.replace(/[^0-9+]/g, '').replace(/^(?:\+?5{2})?/, '');
  }
  return phoneReplaced;
};
export const clearNumber = (value = '') => value.replace(/\D+/g, '');

export const validatePhone = (phone) => {
  const clearedPhone = clearNumber(phone);
  const numberWithoutNinthDigit = normalizePhone(clearedPhone);
  if (numberWithoutNinthDigit !== clearedPhone) {
    return false;
  }
  const numberParsed = parsePhoneNumber(clearedPhone, 'BR');
  if (!numberParsed) {
    return false;
  }
  return numberParsed.isValid();
};

export const segmentOptions = [
  { value: 'Produtor', label: 'Produtor' },
  { value: 'Co-produtor', label: 'Co-produtor' },
  { value: 'Afiliado', label: 'Afiliado' },
  { value: 'Agência de lançamento', label: 'Agência de lançamento' },
  { value: 'Agência de marketing', label: 'Agência de marketing' },
  { value: 'Freelancer digital', label: 'Freelancer digital' },
  { value: 'Vendo serviços', label: 'Vendo serviços' },
  { value: 'Vendo produtos', label: 'Vendo produtos' },
  { value: 'Vendo serviços e produtos', label: 'Vendo serviços e produtos' },
  // { value: 'Agências/Consultorias', label: 'Agências/Consultorias' },
  // { value: 'Agronegócios', label: 'Agronegócios' },
  // { value: 'Arquitetura e Urbanismo', label: 'Arquitetura e Urbanismo' },
  // { value: 'Bares e Restaurantes', label: 'Bares e Restaurantes' },
  // { value: 'Comércio Atacadista', label: 'Comércio Atacadista' },
  // { value: 'Comércio Varejista', label: 'Comércio Varejista' },
  // { value: 'Construção Civil', label: 'Construção Civil' },
  // { value: 'Contábil, Financeiro e Jurídico', label: 'Contábil, Financeiro e Jurídico' },
  // { value: 'E-commerce', label: 'E-commerce' },
  // { value: 'Educação', label: 'Educação' },
  // { value: 'Estética', label: 'Estética' },
  // { value: 'Eventos', label: 'Eventos' },
  // { value: 'Fitness', label: 'Fitness' },
  // { value: 'Franquias', label: 'Franquias' },
  // { value: 'Imobiliário', label: 'Imobiliário' },
  // { value: 'Indústria', label: 'Indústria' },
  // { value: 'Infoprodutores', label: 'Infoprodutores' },
  // { value: 'Moda e vestuário', label: 'Moda e vestuário' },
  // { value: 'Saúde', label: 'Saúde' },
  // { value: 'Seguros', label: 'Seguros' },
  // { value: 'Software e Tecnologia', label: 'Software e Tecnologia' },
  // { value: 'Startups', label: 'Startups' },
  // { value: 'Transportes', label: 'Transportes' },
  // { value: 'Turismo e Hotelaria', label: 'Turismo e Hotelaria' },
  { value: 'Outros', label: 'Outros' },
];

export const billingOptions = [
  { value: 'Até R$30 mil/mês', label: 'Até R$30 mil/mês' },
  { value: 'Entre R$30mil e R$100mil/mês', label: 'Entre R$30mil e R$100mil/mês' },
  { value: 'Entre R$100mil e R$250mil/mês', label: 'Entre R$100mil e R$250mil/mês' },
  { value: 'Entre R$250mil e 500mil/mês', label: 'Entre R$250mil e 500mil/mês' },
  { value: 'Entre R$500mil e R$1milhão/mês', label: 'Entre R$500mil e R$1milhão/mês' },
  { value: 'Acima de R$1milhão/mês', label: 'Acima de R$1milhão/mês' },
];

export const roleOptions = [
  {
    value: 'Sócio/Diretor',
    label: 'Sócio/Diretor',
  },
  {
    value: 'Gerente/Coordenador',
    label: 'Gerente/Coordenador',
  },

  {
    value: 'Assistente/Estagiário',
    label: 'Assistente/Estagiário',
  },
];

export const employeesOptions = [
  { value: 'Atuo sozinho', label: 'Atuo sozinho' },
  { value: 'Entre 2 e 5 pessoas', label: 'Entre 2 e 5 pessoas' },
  { value: 'Entre 5 e 10 pessoas', label: 'Entre 5 e 10 pessoas' },
  { value: 'Entre 10 e 25 pessoas', label: 'Entre 10 e 25 pessoas' },
  { value: 'Entre 25 e 50 pessoas', label: 'Entre 25 e 50 pessoas' },
  { value: 'Entre 50 e 100 pessoas', label: 'Entre 50 e 100 pessoas' },
  { value: 'Acima de 100 pessoas', label: 'Acima de 100 pessoas' },
];

export const productSellOptions = [
  { value: 'Infoprodutos', label: 'Infoprodutos' },
  { value: 'Produtos físicos', label: 'Produtos físicos' },
  { value: 'Eventos presenciais', label: 'Eventos presenciais' },
  { value: 'Serviços', label: 'Serviços (consultorias, mentorias, treinamentos...)' },
  { value: 'Software/Aplicativo', label: 'Software/Aplicativo' },
];

export const indicationOptions = [
  { value: 'Ana Tex', label: 'Ana Tex' },
  { value: 'Maxsuell Inicius', label: 'Maxsuell Inicius' },
  { value: 'Victor Ventura', label: 'Victor Ventura' },
  { value: 'Natanael Oliveira', label: 'Natanael Oliveira' },
  { value: 'Eduzz', label: 'Eduzz' },
  { value: 'Clint', label: 'Clint' },
  { value: 'Outro', label: 'Outro' },
];

export const coproductionChargeOptions = [
  { value: '10%', label: '10%' },
  { value: '20%', label: '20%' },
  { value: '30%', label: '30%' },
  { value: '40%', label: '40%' },
  { value: '50%', label: '50%' },
  { value: '60%', label: '60%' },
  { value: '70%', label: '70%' },
  { value: '80%', label: '80%' },
  { value: '90%', label: '90%' },
];
