/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const Daimyio = forwardRef(({ gradient = false, color = 'none', size = 75, fill = 'url(#paint0_linear_1300_425)', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75 75"
    fill="none"
    stroke={color}
    x="0px"
    y="0px"
    {...rest}
  >
    <path d="M69.1,9.8c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1L58.5,18c-1.5-1.3-3.1-2.4-4.7-3.4c1.7-6.7,0-13,0-13c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0.1L48.1,6c-0.2,0.2-0.3,0.3-0.3,0.4c0,0.1,0.4,2.4,0.2,5.5c-3.3-1.1-6.8-1.8-10.5-1.8c-3.7,0-7.2,0.6-10.5,1.8c-0.2-3,0.2-5.4,0.2-5.5c0-0.2-0.1-0.2-0.3-0.4l-5.3-4.5c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1
c0,0,0,0.1,0,0.1c0,0-1.6,6.4,0,13c-1.7,1-3.3,2.1-4.7,3.4L6.6,9.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3,0c0,0,0,0-0.1,0
C6,9.5,6,9.6,6,9.6c0,0.1-0.1,0.1-0.1,0.2c0,0-3.2,12.7,0.2,25.8c-0.4,2-0.6,4.2-0.6,6.3c0,17.5,14.4,31.7,32,31.7
c17.6,0,32-14.2,32-31.7c0-2.2-0.2-4.3-0.6-6.3C72.3,22.4,69.1,9.8,69.1,9.8z M37.5,13.8c3.5,0,6.9,0.6,10,1.8c0,0.1,0,0.2-0.1,0.2
c-2,8-9.9,9.9-9.9,9.9s-7.9-1.9-9.9-9.9c0-0.1,0-0.2-0.1-0.2C30.6,14.5,34,13.8,37.5,13.8z M26.5,23.8c5.2,4.8,11,6.3,11,6.3
s5.9-1.5,11-6.3c1.8-1.7,3.1-3.7,4-5.7c1.9,1.2,3.7,2.6,5.2,4.2c0.2,3.4,0.3,9.2-1.2,15.1c-1.8,7.2-6.1,11.9-10.2,14.8
c3.3-3.4,5.9-8.6,5.9-8.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1-0.1-0.2-0.1-0.3c0-0.1-3-4.3-7.6-6c-0.1,0-0.4-0.1-0.6-0.1
c-0.3,0.1-0.4,0.5-0.5,0.6C43.7,37.4,40.1,48,40.1,48l0.5-11.2c0-0.2,0-0.6,0-0.8c-0.1-0.3-0.3-0.6-0.4-0.6c-0.1-0.1-2-1.9-2.1-2
c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2,0-0.4,0.2-0.5,0.3c-0.1,0.1-1.9,1.9-2.1,2c-0.1,0.1-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.6,0,0.8L34.9,48
c0,0-3.6-10.6-3.7-10.8c0-0.1-0.1-0.5-0.5-0.6c-0.2,0-0.5,0-0.6,0.1c-4.6,1.7-7.5,6-7.6,6c-0.1,0.1-0.1,0.2-0.1,0.3
c0,0.2,0,0.4,0.1,0.6c0,0,2.7,5.2,5.9,8.6c-4.1-2.9-8.4-7.5-10.2-14.8c-1.5-5.9-1.4-11.7-1.2-15.1c1.6-1.6,3.3-3,5.2-4.2
C23.3,20.2,24.6,22.1,26.5,23.8z M37.5,69.9c-15,0-27.3-11.6-28.3-26.2c1.7,3.3,3.9,6.4,6.9,9.2c10,9.3,21.3,12.2,21.3,12.2
s11.3-2.8,21.3-12.2c3-2.8,5.2-5.9,6.9-9.2C64.8,58.3,52.5,69.9,37.5,69.9z" fill={fill}
    />
    { gradient
    && (
      <linearGradient id="paint0_linear_1300_425" x1="23" y1="0" x2="23" y2="51" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6CFFB0" />
        <stop offset="0.859375" stopColor="#3FA857" />
      </linearGradient>
    )}
  </svg>
));

export default Daimyio;
