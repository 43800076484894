import BgPartnersSignUp from '@/assets/img/bg-partners-sign-up.jpeg';
import { ReactComponent as EduzzLogo } from '@/assets/img/brand/eduzz-logo.svg';
import { ReactComponent as EduzzProgramaParceiros } from '@/assets/img/brand/eduzz-programa-parceiros.svg';
import { ReactComponent as SelosJornada } from '@/assets/img/selos-jornada.svg';
import auth from '@/config/auth';
import create from '@/modules/partners/services/create';
import { validatePhone } from '@/util/utils';
import yup from '@/util/yup';
import { Button, Form, Input, Typography, DarkStyle } from '@partners-core/components';
import partners from '@partners-core/partners-js';
import { Form as Unform } from '@unform/web';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FiCopy, FiHelpCircle } from 'react-icons/fi';
import { Tooltip } from 'react-tippy';
import { useToasts } from 'react-toast-notifications';

import { AuthContext } from '../../../context/AuthContext';

const schemaValidation = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  company_name: yup.string().required(),
  password: yup.string().min(6).required(),
  password_repeat: yup.string().min(6).required().oneOf([yup.ref('password'), null], 'A confirmação de senha não confere'),
  phone: yup.string().required('O telefone é obrigatório').test('validatePhone', 'Telefone precisa ser um número válido', validatePhone),
});

const updateOwnerInformation = async ({ ownerId, information }) => {
  await partners.owner.update({
    where: { id: { _eq: ownerId } },
    _append: { information },
  });
};

const SignupPartner = () => {
  const formRef = useRef(null);

  const { setUser, user, getUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [copySuccess, setCopySuccess] = useState('');
  const [step, setStep] = useState(0);
  const [meta, setMeta] = useState({});
  const [partnerId, setPartnerId] = useState(null);
  const { addToast } = useToasts();
  const inputRef = useRef(null);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const source = query.get('utm_source');
    const medium = query.get('utm_medium');
    const campaign = query.get('utm_campaign');
    const content = query.get('utm_content');

    setMeta({
      utm_source: source,
      utm_medium: medium,
      utm_campaign: campaign,
      utm_content: content,
    });
  }, []);

  const copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copiado!');
  };

  const handleSubmit = async (data) => {
    const validationErrors = {};
    formRef.current.setErrors({});

    if (!loading) {
      setLoading(true);
      try {
        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const newData = {
          ...data,
          token: newToken,
          meta,
        };

        const resp = await create(newData);
        const responseData = resp.create_partner;
        if (responseData.success) {
          setPartnerId(responseData.payload.partner_id);

          auth.setToken(responseData.payload.user.token);
          const newUser = await getUser(responseData.payload.user.id);
          setUser({
            ...newUser,
            token: responseData.payload.user.token,
          });

          setStep(1);
        } else {
          addToast(resp.create_partner.message, {
            appearance: 'snack',
            autoDismiss: true,
            autoDismissTimeout: 6500,
          });
        }
        setLoading(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
        }
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleContinueStep = async () => {
    if (!loading) {
      setLoading(true);

      try {
        const newData = formRef.current.getData();
        await updateOwnerInformation({
          ownerId: user.owner.id,
          information: {
            ...user.owner.information,
            ...newData,
          },
        });

        // await partners.owner.updateDealPartner({ owner_id: user.owner.id, new_data: newData });

        setUser({
          ...user,
          owner: {
            ...user.owner,
            information: {
              ...user.owner.information,
              ...newData,
            },
          },
        });
        window.location.replace('/partners/eduzz');
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  };

  const gmvOptions = [
    { value: '0 até 9.9K', label: '0 até 9.9K' },
    { value: '10k até 100k', label: '10k até 100k' },
    { value: '101k até 360k', label: '101k até 360k' },
    { value: '360k até 1M', label: '360k até 1M' },
    { value: '1.01M até 5M', label: '1.01M até 5M' },
    { value: '5M até 10M', label: '5M até 10M' },
    { value: 'Acima de 10M', label: 'Acima de 10M' },
  ];

  const typeOptions = [
    { value: 'Agência', label: 'Agência' },
    { value: 'Coprodutor', label: 'Coprodutor' },
    { value: 'Gestor de tráfego', label: 'Gestor de tráfego' },
    { value: 'Produtor', label: 'Produtor' },
    { value: 'Afiliado', label: 'Afiliado' },
  ];

  const CardSignUp = () => (
    <Col xs="12" lg="6">
      <Card className={isMobile ? 'px-4' : 'px-4 mt-6'}
        style={{
          background: 'rgba(0, 0, 0, 0.49)',
          border: '1px solid #567BFF',
          boxShadow: '0px 4px 114px #465FBA',
        }}
      >
        <Row className="align-items-center justify-content-center">
          <Row className=" mt-5 mb-4">
            <EduzzProgramaParceiros />
          </Row>
          <Row>
            <Typography className="c-white text-center" variant="h1" percent="110" fontWeight="bold">Programa de parceiros Eduzz</Typography>
          </Row>
          <Row className="m-0 px-5">
            <Typography className="c-white text-center" variant="p">
              Crie sua conta para acompanhar seu desempenho no programa de parceiros da Eduzz.
            </Typography>
          </Row>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col xs="12" lg="10">
            <Unform id="informacoes" ref={formRef} onSubmit={handleSubmit} className="mb-6">
              {step === 0
                && (
                  <>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Seu nome
                      </Form.Label>
                      <Form.Input
                        name="name"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        E-mail
                      </Form.Label>
                      <Form.Input
                        name="email"
                        type="email"
                        placeholder="De preferência seu email de cadastro na Eduzz"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Nome da empresa ou do seu grupo de negócio
                        <Tooltip
                          position="top"
                          html={(
                            <Row className="py-3 align-items-center">
                              <Col xs="auto" className="text-center">
                                <FiHelpCircle className="help-circle ml-3" size="20" fill="#f2edff" color="#7C00FF" />
                              </Col>
                              <Col className="text-left">
                                Informe o nome como sua empresa é conhecida pelos seus clientes, seja você uma agência, consultor ou especialista.
                              </Col>
                            </Row>
                          )}
                        >
                          <FiHelpCircle className="help-circle ml-3" size="17" fill="#f2edff" color="#7C00FF" />
                        </Tooltip>
                      </Form.Label>
                      <Form.Input
                        name="company_name"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Número WhatsApp
                      </Form.Label>
                      <Form.Input name="phone" mask="(99) 99999-9999"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Senha
                      </Form.Label>
                      <Form.Input
                        name="password"
                        type="password"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Confirmar senha
                      </Form.Label>
                      <Form.Input
                        name="password_repeat"
                        type="password"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>

                    <Form.Row className="mt-3">
                      <Button variant="yellow" type="submit" block loading={loading}>
                        Continuar
                      </Button>
                    </Form.Row>
                  </>
                )}

              {step === 1
                && (
                  <>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Como você se define?
                      </Form.Label>
                      <Form.Select
                        name="company_type"
                        className="form-control-dark form-control-md c-white alive bold"
                        options={typeOptions}
                        styles={DarkStyle}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Quantos clientes você atende?
                      </Form.Label>
                      <Form.Input
                        name="company_customers"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Quantos transacionam pela Eduzz?
                      </Form.Label>
                      <Form.Input
                        name="company_customers_eduzz"
                        className="form-control-dark form-control-md c-white alive bold"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md font-weight-normal c-white">
                        Aproximadamente, qual é a faixa de faturamento somada de todos os seus clientes, nos últimos 12 meses? (Grupo de negócios)
                      </Form.Label>
                      <Form.Select
                        name="company_gmv"
                        className="form-control-dark form-control-md c-white alive bold"
                        options={gmvOptions}
                        styles={DarkStyle}
                      />
                    </Form.Group>

                    <Form.Row className="mt-3">
                      <Button variant="yellow" loading={loading} onClick={handleContinueStep} block>
                        Finalizar cadastro
                      </Button>
                    </Form.Row>
                  </>
                )}

            </Unform>
          </Col>
        </Row>
      </Card>
    </Col>
  );

  const HeaderSignUp = () => (
    <Col className="pl-4 ">
      <Row className="align-items-end h-100 w-100">
        <Col className={isMobile ? 'pb-3' : 'pb-9'}>
          <SelosJornada width={isMobile ? '100%' : null} />
          <Typography className={`${isMobile ? 'text-center' : null} c-white`} variant="h1" fontWeight="bold" percent="200">
            Jornada do Dragão
          </Typography>
          <Typography className={`${isMobile ? 'text-center' : 'pr-6'} c-white`} variant="p" percent="200">
            Acompanhe sua evolução e explore todo seu potencial.
          </Typography>
        </Col>
      </Row>
    </Col>
  );

  return (
    <Container className={isMobile ? 'px-0 pt-6' : 'px-0 d-flex'}
      style={{ minHeight: `${isMobile ? null : '985px'}`, backgroundColor: `${isMobile ? '#171331' : null}` }}
    >
      <Row className={isMobile ? null : 'w-100 m-0'}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      >
        <img src={BgPartnersSignUp} alt="bg" width={isMobile ? null : '100%'} />
      </Row>
      {!isMobile
        ? (
          <Row style={{ minHeight: '850px' }}>
            <CardSignUp />
            <HeaderSignUp />
          </Row>
        ) : (
          <Col>
            <HeaderSignUp />
            <CardSignUp />
          </Col>
        )}

      {step === 2

        && (
          <>
            <Row className="align-items-center justify-content-center">
              <Col xs="12" lg="8" xl="6">
                <div className="header mt-md-4">
                  <div className="header-body b-0">
                    <div className="display-4 text-center mb-5">
                      <div className="my-3 px-4">
                        <EduzzLogo height="36" />

                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-center c-dark mb-3 mt-4">
              <Col xs="12" lg="8">
                <Typography variant="h1" percent="125" fontWeight="500" className="mb-4"> Cadastro concluído!</Typography>
                <Typography variant="h2">Agora você faz parte do Programa de parceiros da Eduzz</Typography>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-center c-dark mb-3">
              <Col xs="12" lg="8">
                <Typography variant="h5">Esse é seu ID de parceiro. Use para vincular os produtos de seus clientes.</Typography>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-center c-dark" noGutters>
              <Col xs="12" lg="2">
                <Input
                  name="partnerId"
                  ref={inputRef}
                  className="form-control-translucent alive bold c-dark"
                  value={partnerId}
                  readOnly
                />
              </Col>
              <Col xs="2">
                <Button variant="blue" onClick={copyToClipboard} size="md">
                  <FiCopy className="mr-2" />
                  {copySuccess ? 'Copiado!' : 'Copiar link'}
                </Button>
              </Col>
            </Row>

            <hr className="my-6" />

            <Row className="align-items-center justify-content-center text-center c-dark mb-3 mt-4">
              <Col xs="12" lg="8">
                <Typography variant="h2" fontWeight="bold">O que deseja fazer agora?</Typography>
              </Col>

            </Row>
            <Row className="align-items-center justify-content-center text-center c-dark mb-3 pointer hover-underline">
              <Col xs="12" lg="8">
                <Typography onClick={() => alert('redirect orbita produtos')} variant="h5">
                  Vincular o ID de parceiro nos produtos de seus clientes.

                </Typography>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-center c-dark mb-3 pointer hover-underline">
              <Col xs="12" lg="8">
                <Typography onClick={() => window.location.replace('/partners/eduzz')} variant="h5">
                  Acessar Dashboard de acompanhamentos de clientes na Clint.
                </Typography>
              </Col>
            </Row>

          </>

        )}
    </Container>
  );
};
export default SignupPartner;
