import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`  
  mutation changePasswordMutation(
    $email: String!,
    $token: String!,
    $password: String!){
    change_password(
      email: $email,
      token: $token,
      password: $password
    ) {
      isChanged
    }
  }
`;

const changePassword = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default changePassword;
