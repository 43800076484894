/* eslint-disable no-console */
import { ReactComponent as FixingBugs } from '@/assets/img/icons/fixing_bugs.svg';
import Skeleton from '@/components/Skeleton';
import { QueryRenderer } from '@cubejs-client/react';
import { Typography } from '@partners-core/components';
import Dinero from 'dinero.js';
import React from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tippy';
import { useDeepCompareMemo } from 'use-deep-compare';

const keyTable = 1;

const formatTableData = (columns, data) => {
  function flatten() {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => ({ ...memo, [column.dataIndex]: column }), {});

  function formatValue(value, { type } = {}) {
    if (value === undefined) {
      return value;
    }

    if (type === 'boolean') {
      if (typeof value === 'boolean') {
        return value.toString();
      } if (typeof value === 'number') {
        return Boolean(value).toString();
      }

      return value;
    }

    if (type === 'number' && format === 'percent') {
      return [parseFloat(value).toFixed(2), '%'].join('');
    }

    return value?.toString() || '';
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => [dataIndex, formatValue(value, typeByIndex[dataIndex])]),
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, pivotConfig, isSupportUser }) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);

  return (
    <>
      <Table responsive className="table-sm" size="sm">
        <thead>
          <tr>
            <th colSpan="2">
              <Col className="m-0 p-0">ID</Col>
            </th>
            <th colSpan="2">
              <Col className="m-0 p-0">PRODUTO</Col>
            </th>
            <th>
              <Tooltip title="Valor total de vendas  menos o valor de vendas reembolsadas.">
                <Col className="m-0 p-0 text-center">VALOR</Col>
              </Tooltip>
            </th>
            <th><Col className="m-0 p-0 text-center">RECEITA LÍQUIDA</Col></th>
            <th><Col className="m-0 p-0 text-center">RECEITA CO-PRODUTORES</Col></th>
            <th>
              <Tooltip title=" Número total  de vendas.">
                <Col className="m-0 p-0 text-center">VENDAS</Col>
              </Tooltip>
            </th>
            {/* <th><Col className="m-0 p-0 text-center">CHARGEBACK</Col></th> */}
            <th>
              <Tooltip title="Número total de vendas reembolsadas.">
                <Col className="m-0 p-0 text-center">VENDAS REEMBOLSADAS</Col>
              </Tooltip>
            </th>
            <th>
              <Tooltip title="Valor total de vendas reembolsadas.">
                <Col className="m-0 p-0 text-center">VALOR REEMBOLSO</Col>
              </Tooltip>
            </th>
            <th>
              <Tooltip title="Porcentagem de vendas reembolsadas sobre o valor total">
                <Col className="m-0 p-0 text-center">%REEMBOLSO</Col>
              </Tooltip>
            </th>
            <th>
              <Tooltip title="Status do produto">
                <Col className="m-0 p-0 text-center">STATUS</Col>
              </Tooltip>
            </th>

          </tr>
        </thead>
        <tbody>
          {dataSource.map((item) => (
            <tr key={`table-row-${item['Product.id']}`}>
              { isSupportUser && (
                <td colSpan="2">
                  <Typography className="c-dark m-0" variant="h5" percent="90">
                    {item['Product.externalId']}
                  </Typography>
                </td>
              )}
              <td colSpan="2">
                <Typography className="c-dark m-0" variant="h5" percent="90">
                  {item['Product.name']}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                  {Dinero({ amount: Math.trunc(item['Transaction.value']) }).toFormat()}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center pointer font-bold" variant="h5" percent="90">
                  {Dinero({ amount: Math.trunc(item['Transaction.netIncome']) }).toFormat()}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center pointer font-bold" variant="h5" percent="90">
                  {Dinero({ amount: Math.trunc(item['Transaction.coproductionAmount']) }).toFormat()}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90" tooltip={item['CustomerType.label']}>
                  {item['Transaction.count']}
                </Typography>
              </td>
              {/* <td>
              <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                {((item['Transaction.chargebackCount'] / item['Transaction.count'])).toFixed(0)}
                %
              </Typography>
            </td> */}
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                  {item['Transaction.refundedCount']}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                  {Dinero({ amount: Math.trunc(item['Transaction.refundedValue']) }).toFormat()}
                </Typography>
              </td>

              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                  {((item['Transaction.refundedCount'] / item['Transaction.count']) * 100).toFixed(0)}
                  %
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                  <span className={`${item['Product.active'] === 'true' ? 'c-greener' : 'c-gray'} mr-2`}>●</span>
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const renderChart = ({ resultSet, error, pivotConfig, isSupportUser }) => {
  if (error) {
    console.error(error);
    return (
      <Row>
        <Col className="text-center">
          <FixingBugs width={100} height={100} className="mt-4 mb-2" />
          <Row className="justify-content-center">
            <Typography className="c-dark pt-2 font-weight-bold " variant="h4">
              Ops, ocorreu um erro inesperado
            </Typography>
          </Row>
          <Row className="justify-content-center mx-4">
            <Typography className="c-blue pt-2 pb-4" variant="p" percent="80">
              Entre em contato com suporte se esse problema persistir
            </Typography>
          </Row>
        </Col>
      </Row>
    );
  }

  if (!resultSet) {
    return (
      <Table responsive className="table-sm" size="sm">
        <thead>
          <tr>
            <th>PRODUTO</th>
            <th><Col className="m-0 p-0 text-center">VALOR</Col></th>
            <th><Col className="m-0 p-0 text-center">RECEITA LÍQUIDA</Col></th>
            <th><Col className="m-0 p-0 text-center">RECEITA CO-PRODUTORES</Col></th>
            <th><Col className="m-0 p-0 text-center">VENDAS</Col></th>
            <th><Col className="m-0 p-0 text-center">VENDAS REEMBOLSADAS</Col></th>
            <th><Col className="m-0 p-0 text-center">VALOR REEMBOLSO</Col></th>
            <th><Col className="m-0 p-0 text-center">%REEMBOLSO</Col></th>
            {/* <th><Col className="m-0 p-0 text-center">PRODUTO ATIVO</Col></th> */}
            <th><Col className="m-0 p-0 text-center">%REEMBOLSO</Col></th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3].map((item, index) => (
            <tr key={`row-table-${index}`}>
              <td><Skeleton height={20} width="100px" key={item} /></td>
              <td><Skeleton height={20} width="100px" key={item} /></td>
              <td><Skeleton height={20} width="100px" key={item} /></td>
              <td><Skeleton height={20} width="100px" key={item} /></td>
              <td><Skeleton height={20} width="100px" key={item} /></td>
              <td><Skeleton height={20} width="100px" key={item} /></td>
              {/* <td><Skeleton height={20} width="100px" key={item} /></td> */}
              <td><Skeleton height={20} width="100px" key={item} /></td>

            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <TableRenderer resultSet={resultSet} pivotConfig={pivotConfig} isSupportUser={isSupportUser} />
  );
};

export const TableProduct = ({ filterDateTable, filterTable, cubejsApi, filter, isSupportUser }) => (
  <QueryRenderer key={keyTable}
    query={{
      measures: [
        'Transaction.value',
        'TransactionStatus.count',
        'Product.count',
        'Transaction.chargebackCount',
        'Transaction.refundedCount',
        'Transaction.refundedValue',
        'Transaction.count',
        'Transaction.netIncome',
        'Transaction.coproductionAmount',
      ],
      timeDimensions: [
        {
          dimension: 'Transaction.paidAt',
          dateRange: filterDateTable,
        },
      ],
      order: {
        'Transaction.value': 'desc',
      },
      dimensions: [
        'Product.id',
        'Product.name',
        'Product.active',
        'Product.externalId',
      ],
      filters: filterTable,
      limit: filter.limit,
      offset: filter.offset,
    }}
    cubejsApi={cubejsApi}
    resetResultSetOnChange={false}
    render={(props) => renderChart({
      ...props,
      chartType: 'table',
      pivotConfig: {
        x: [
          'Product.name',
          'Product.active',
          'Product.externalId',
        ],
        y: [
          'measures',
        ],
        fillMissingDates: true,
        joinDateRange: false,
        limit: filter.limit,
        offset: filter.offset,
      },
      isSupportUser,
    })}
  />
);
