import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const FormCurrency = ({ name, onChange, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  const onFocusExecution = () => {
    clearError();
  };

  return (
    <>
      <IntlCurrencyInput
        ref={inputRef}
        name={name}
        currency="BRL"
        config={currencyConfig}
        className="form-control-translucent form-control-md c-dark alive bold"
        value={defaultValue}
        onChange={(event, value) => {
          event.preventDefault();
          if (onChange) {
            onChange(value);
          }
        }}
        onFocus={onFocusExecution}
        {...rest}
      />
      {error && <span className="text-danger font-size-xs ml-2 mt-2 font-weight-bold">{error}</span>}
    </>
  );
};

export default FormCurrency;
