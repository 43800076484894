import { commitMutation, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

const mutation = graphql`
    mutation removeUserMutation($removeUserId: uuid!, $newUserId: uuid!) {
      remove_user(remove_user_id: $removeUserId, new_user_id: $newUserId) {
        success
        payload
        message
      }
    }
`;

export default function removeUser(variables) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        updater: (store) => {
          store.delete(variables.id);
        },
        onCompleted: (resp, error) => {
          if (!error) {
            resolve(resp);
          } else {
            reject(error);
          }
        },
        onError: (error) => {
          reject(error);
        },
      },
    );
  });
}
