import React from 'react';
import styled from 'styled-components';

// import { Container } from './styles';
const Wrapper = styled.div`
  background:${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => (props.roundedCircle ? '50%' : '6px')};
  padding: 0.1rem;
  border: 1px solid ${(props) => props.borderColor};
`;

const IconWrapper = ({ width, height, backgroundColor = ' #f6f2ff', borderColor = '#eeecff', roundedCircle = false, ...props }) => (
  <div className="d-inline-block">
    <Wrapper width={width} height={height} backgroundColor={backgroundColor} borderColor={borderColor} roundedCircle={roundedCircle} {...props} />
  </div>
);

export default IconWrapper;
