import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    :hover{
      svg{
        fill: ${(props) => props.fillColor};
        stroke: ${(props) => props.strokeColor};
      }
    }
`;

const IconHover = ({ children, fillColor = '#6c19de', strokeColor = '#6c19de' }) => (
  <Wrapper fillColor={fillColor} strokeColor={strokeColor}>
    {children}
  </Wrapper>
);

export default IconHover;
