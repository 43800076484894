import en from './en';
import es from './es';
import ptBR from './ptBR';

const messages = {
  ...en,
  ...ptBR,
  ...es,
};

export default messages;
