import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

import { DefaultStyle } from './SelectStyles';

const StyledReactSelect = styled(ReactSelect)`
  .react-select__value-container {
    min-width: ${(props) => props.minWidth || 'initial'}
  }
`;
const Select = forwardRef(({ name, options, styles, minWidth, ...rest }, ref) => (
  <StyledReactSelect
    classNamePrefix="react-select"
    placeholder="Selecione..."
    options={options}
    minWidth={minWidth}
    styles={styles || DefaultStyle}
    ref={ref}
    loadingMessage={() => 'Carregando...'}
    noOptionsMessage={() => 'Nenhum resultado'}
    {...rest}
  />
));

export default Select;
