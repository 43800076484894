import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`  
  mutation sendForgetPasswordMutation(
    $email: String!){
    send_forget_password(
      email: $email
    ) {
      isSend
    }
  }
`;

const sendForgetPassword = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default sendForgetPassword;
