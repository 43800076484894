/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */

import axios from 'axios';
import {
  get,
  isPlainObject,
  omit,
  isString,
  first,
  transform,
  isObject,
  keyBy,
  isArray,
  isBoolean,
} from 'lodash';

import { state } from './config';

const isProduction = process.env.NODE_ENV === 'production';

const isFalsy = (value) => value === false;

const reservedWords = ['where', 'limit', 'offset', 'order_by', 'distinct_on'];

const withReturning = (action) => {
  const actions = ['insert', 'update', 'delete'];
  return actions.includes(action);
};

const withAggregate = (action) => action === 'aggregate';

const makeKey = ({ action, actionName }) => {
  const key = withReturning(action)
    ? `${actionName}.returning`
    : withAggregate(action)
      ? `${actionName}.aggregate`
      : actionName;

  return key;
};

const makeValue = ({ data, key }) => {
  const value = get(data, key);

  return value === null
    ? undefined
    : value;
};

const makeRequest = async ({
  method,
  query,
  variables,
  key,
  single = false,
  multi = false,
  custom = false,
}) => {
  if (state.logger && !isProduction) {
    console.log(query);
  }

  const headers = {
    'Content-Type': 'application/json',
  };

  if (state.authorization.value) {
    headers[state.authorization.key] = state.authorization.value;
  }

  const { data } = await axios.request(state.baseURL, {
    method: 'POST',
    headers,
    data: JSON.stringify({
      query,
      variables,
    }),
  });

  if (data.errors) {
    if (isProduction) {
      console.error(JSON.stringify({ Error: method, variables }));
    } else {
      console.dir({ Error: method, variables }, { depth: null });
    }
    throw new Error(data.errors[0].message);
  }

  const value = data.data;

  if (custom) {
    return value;
  }

  if (multi) {
    return Object.keys(value).map((_, index) => {
      const { key } = multi[index];
      return makeValue({ data: value, key });
    });
  }

  if (single) {
    return first(
      makeValue({ data: value, key }),
    );
  }

  return makeValue({ data: value, key });
};

const makeActionName = ({ module, action }) => {
  const actions = {
    find: module,
    findByPk: `${module}_by_pk`,
    insert: `insert_${module}`,
    insertOne: `insert_${module}_one`,
    update: `update_${module}`,
    updateByPk: `update_${module}_by_pk`,
    delete: `delete_${module}`,
    deleteByPk: `delete_${module}_by_pk`,
    aggregate: `${module}_aggregate`,
  };

  const actionName = actions[action];

  const table = state.metadata?.tables?.find(({ table }) => table === module);

  return {
    actionName: actionName || action,
    isCustom: !actionName,
    isEnum: table?.enum,
  };
};

const makeParameters = ({ actionName, module, variables }) => {
  const parameters = {
    id: 'uuid!',
    pk_columns: `${module}_pk_columns_input!`,
    object: `${module}_insert_input!`,
    objects: `[${module}_insert_input!]!`,
    where: `${module}_bool_exp!`,
    _set: `${module}_set_input!`,
    _append: `${module}_append_input!`,
    _prepend: `${module}_prepend_input!`,
    _inc: `${module}_inc_input!`,
    order_by: `[${module}_order_by!]`,
    distinct_on: `[${module}_select_column!]`,
    limit: 'Int!',
    offset: 'Int!',
    on_conflict: variables.on_conflict && JSON.stringify(variables.on_conflict).replace(/"/gi, ''),
  };

  const getParameter = ({ key }) => {
    const parameter = parameters[key];
    if (parameter) {
      return parameter;
    }

    const action = state.metadata?.actions
      ?.find((action) => action.name === actionName)?.inputs.find((input) => input.name === key);

    return action?.type;
  };

  const mount = (variables, callback) => Object.entries(variables).reduce((result, [key, value]) => ([
    result,
    callback({ key, value }),
  ]).join(''), '').slice(0, -2);

  const parametersKeys = mount(omit(variables, ['on_conflict']), ({ key }) => `$${key}${actionName.toUpperCase()}: ${getParameter({ key })}, `);
  const parametersValues = mount(variables,
    ({ key }) => `${key}: ${['on_conflict'].includes(key) ? getParameter({ key }) : `$${key}${actionName.toUpperCase()}`}, `);

  return {
    parametersKeys: parametersKeys ? `(${parametersKeys})` : '',
    parametersValues: parametersValues ? `(${parametersValues})` : '',
  };
};

const makeVariables = ({ actionName, variables }) => Object.fromEntries(
  Object
    .entries(omit(variables, ['on_conflict']))
    .map(([key, value]) => ([`${key}${actionName.toUpperCase()}`, value])),
);

const makePath = (key, { path: value }) => {
  const [field, ...paths] = value.split('.');

  const path = paths.join('.');

  return {
    key,
    value: `${field}(path: "${path}")`,
  };
};

const makeRename = (key, { alias }) => ({
  key: alias,
  value: key,
});

const omitDeep = (obj, keysToOmit) => {
  const keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]); // create an index object of the keys that should be omitted

  const omitFromObject = (obj) => transform(obj, (result, value, key) => {
    const object = result; // transform to a new object

    if (key in keysToOmitIndex && !isBoolean(object[key])) {
      return;
    }

    object[key] = isObject(value) ? omitFromObject(value) : value;
  });

  return omitFromObject(obj);
};

const findNested = (obj, keys, parent, memo) => {
  const proto = Object.prototype;
  const ts = proto.toString;
  const hasOwn = proto.hasOwnProperty.bind(obj);

  if (ts.call(memo) !== '[object Object]') memo = {};

  for (const i in obj) {
    if (hasOwn(i)) {
      const foundKey = keys.find((k) => k === i);
      if (foundKey) {
        if (ts.call(memo[parent]) !== '[object Array]') memo[parent] = [];
        const isObject = isPlainObject(obj[i]) || isArray(obj[i]);
        const value = isObject ? JSON.stringify({ ...obj[i] }) : obj[i];
        if (!isBoolean(value)) {
          memo[parent].push(
            `${[foundKey]}: ${value}`,
          );
        }
      } else if (ts.call(obj[i]) === '[object Array]' || ts.call(obj[i]) === '[object Object]') {
        findNested(obj[i], keys, i, memo);
      }
    }
  }

  return memo;
};

const makeReturning = ({ action, select }) => {
  const returning = (props, { parentIsNested = false } = {}) => {
    const fields = Object.entries(props).filter(([key, value]) => key !== 'nested' && !!value);

    return fields.reduce((result, [key, value]) => {
      const isNested = value.hasOwnProperty('nested');
      const isValueObject = isPlainObject(value);
      const isPath = isValueObject && value.hasOwnProperty('path');
      const isRename = isValueObject && value.hasOwnProperty('alias');
      const isObject = isValueObject && !isPath && !isRename;
      const isSimple = !isNested && !isValueObject && !isPath && !isRename;

      const path = isPath && makePath(key, value);
      const rename = isRename && makeRename(key, value);

      const nestedParameters = isNested && returning(value.nested, { parentIsNested: true });
      const nested = isNested && `(${nestedParameters.substring(2, nestedParameters.length - 2)})`;

      let formatted = '';

      if (isPath) {
        formatted = `${path.key}: ${path.value} `;
      } else if (isRename) {
        formatted = `${rename.key}: ${rename.value} `;
      } else if (isNested) {
        formatted = `${key}${nested}`;
      } else if (isSimple) {
        formatted = parentIsNested ? `${key}: ${isString(value) ? `"${value}"` : value}` : `${key} `;
      } else if (isObject) {
        formatted = parentIsNested ? `${key}:` : key;
      }

      return [
        result,
        isObject ? formatted : '',
        isObject ? returning(value, { parentIsNested }) : formatted,
      ].join('');
    }, '{ ').concat(' }');
  };

  const mountReturningQuery = (newSelect, withAction = false) => {
    if (!withAction) { // checks if it is an action query where following won't work;
      const foundNested = findNested(newSelect, reservedWords); // finds nested objects that can increase graphql query. eg: where, filter, limit

      let returningObject;
      if (foundNested) {
        const keys = Object.keys(foundNested);
        newSelect = omitDeep(newSelect, reservedWords); // omits reserved words from hasura returning objects

        returningObject = returning(newSelect); // gets returning objects, without reserved words

        keys.forEach((key) => {
          // join objects and removes quotes for hasura query string
          const whereParsed = foundNested[key].join(',').replace(/"(\w+)"\s*:/g, '$1:').replace(/"(asc|desc)"\s*/g, '$1');
          const parsedQuery = `${key}(${whereParsed} )`;
          returningObject = returningObject.replace(key, parsedQuery); // replaces string to form nested query. ex: deals to deals( where:...)
        });

        return returningObject;
      }
    }

    return returning(newSelect);
  };

  return withReturning(action)
    ? `{ returning ${mountReturningQuery(select, true)} }`
    : mountReturningQuery(select);
};

const makeMethod = ({ method, subscription }) => (subscription ? 'subscription' : method);

const makeSelect = ({
  module,
  actionName,
  select,
  aggregate,
  isCustom = false,
  isEnum = false,
}) => {
  const parsedSelect = aggregate || isCustom || isEnum ? omit(select, 'id') : select;

  if (aggregate) {
    parsedSelect.aggregate = aggregate;
  }

  const isEmpty = !Object.keys(parsedSelect).length;

  let defaultSelect = {};
  if (isCustom) {
    const action = state.metadata?.actions?.find((action) => action.name === actionName);
    defaultSelect = Object.fromEntries(
      action?.outputs.map((output) => ([output.name, true])) || [],
    );
  }

  if (isEnum) {
    const table = state.metadata?.tables?.find((table) => table.table === module);
    defaultSelect = Object.fromEntries(
      table?.schema.map((field) => ([field, true])) || [],
    );
  }

  return (isCustom || isEnum) && isEmpty
    ? defaultSelect
    : parsedSelect;
};

const makeQuery = (module) => (method) => (action) => ({
  select = { id: true }, aggregate, options: queryOptions, ...variables
} = {}, options = {}) => {
  const { actionName, isCustom, isEnum } = makeActionName({ module, action });
  const formattedMethod = makeMethod({ method, subscription: options.subscription });
  const formattedVariables = makeVariables({ actionName, variables });
  const formattedSelect = makeSelect({
    module,
    actionName,
    select,
    aggregate,
    isCustom,
    isEnum,
  });
  const { parametersKeys, parametersValues } = makeParameters({ actionName, module, variables });
  const returning = makeReturning({ action, select: formattedSelect });
  const key = makeKey({ action, actionName });

  const baseQuery = `${actionName}${parametersValues}${returning}`;

  let cached = '';
  if (queryOptions?.cached === true) {
    cached = '@cached';
  } else if (queryOptions?.cached?.refresh) {
    cached = '@cached(refresh: true)';
  } else if (queryOptions?.cached?.ttl) {
    cached = `@cached(ttl:${queryOptions.cached?.ttl})`;
  }

  const fullQuery = `
    ${formattedMethod} Query${parametersKeys} ${cached} {
      ${baseQuery}
    }
  `;

  if (options.multi) {
    const make = ({ query, parametersKeys }) => `
      ${method} Query${parametersKeys} ${cached} {
        ${query}
      }
    `;

    return {
      query: baseQuery,
      variables: formattedVariables,
      parametersKeys,
      key,
      make,
      single: options.single,
    };
  }

  if (options.subscription || isFalsy(options.execute)) {
    return {
      query: fullQuery,
      variables: formattedVariables,
    };
  }

  return makeRequest({
    method: `${formattedMethod} ${actionName}`,
    query: fullQuery,
    variables: formattedVariables,
    key,
    single: options.single,
  });
};

const makeRepository = (module) => {
  const moduleQuery = makeQuery(module);

  const query = moduleQuery('query');
  const mutation = moduleQuery('mutation');

  const find = query('find');
  const findOne = (props, options = {}) => find(props, { ...options, single: true });

  const update = mutation('update');
  const updateOne = (props, options = {}) => update(props, { ...options, single: true });

  const asDelete = mutation('delete');
  const deleteOne = (props, options = {}) => asDelete(props, { ...options, single: true });

  const aggregate = query('aggregate');

  return {
    query,
    mutation,
    find,
    findOne,
    findByPk: query('findByPk'),
    insert: mutation('insert'),
    insertOne: mutation('insertOne'),
    update,
    updateOne,
    updateByPk: mutation('updateByPk'),
    delete: asDelete,
    deleteOne,
    deleteByPk: mutation('deleteByPk'),
    aggregate,
  };
};

const action = makeQuery('action');

const query = action('query');
const mutation = action('mutation');

export { query, mutation, makeRequest };

export default makeRepository;
