import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`
  mutation inviteSignupMutation (
    $user_id: String!,
    $email: String!,
    $first_name: String!,
    $last_name: String!,
    $password: String!,
    $phone: String!,
  ) {
    invite_signup(user_id: $user_id, first_name: $first_name, last_name: $last_name, password: $password, email: $email, phone: $phone) {
      success
      message
      payload
    }
  }
  
  
`;

const inviteSignup = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default inviteSignup;
