import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`  
  mutation googleVinculateMutation(
    $credential: String!
  ){
    google_vinculate(credential: $credential){
      success
    }
  }
`;

const googleVinculate = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default googleVinculate;
