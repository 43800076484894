import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`  
  mutation updatePasswordMutation(
    $email: String!,
    $old_password: String!,
    $new_password: String!){
    update_password(
      email: $email,
      old_password: $old_password,
      new_password: $new_password
    ) {
      isChanged
    }
  }
`;

const updatePassword = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default updatePassword;
