import { commitMutation, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

const mutation = graphql`
    mutation updateOwnerByPkMutation($id: uuid!, $data: owner_set_input, $append: owner_append_input) {
      update_owner_by_pk(pk_columns: {id: $id}, _set: $data, _append: $append) {
        name
      }
    }
`;

export default function updateOwner(variables) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (resp, error) => {
          if (!error) {
            resolve(resp);
          } else {
            reject(error);
          }
        },
        onError: (error) => {
          reject(error);
        },
      },
    );
  });
}
