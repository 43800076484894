/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type googleVinculateMutationVariables = {|
  credential: string
|};
export type googleVinculateMutationResponse = {|
  +google_vinculate: {|
    +success: boolean
  |}
|};
export type googleVinculateMutation = {|
  variables: googleVinculateMutationVariables,
  response: googleVinculateMutationResponse,
|};
*/


/*
mutation googleVinculateMutation(
  $credential: String!
) {
  google_vinculate(credential: $credential) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "credential",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "credential",
        "variableName": "credential"
      }
    ],
    "concreteType": "GoogleVinculateOutput",
    "kind": "LinkedField",
    "name": "google_vinculate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "googleVinculateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "googleVinculateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "googleVinculateMutation",
    "operationKind": "mutation",
    "text": "mutation googleVinculateMutation(\n  $credential: String!\n) {\n  google_vinculate(credential: $credential) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f02fbd13a92bf0bce0c96d04a7e0cfa2';

module.exports = node;
