export const USER_CONFIG = 'USER_CONFIG';

const getUserConfig = () => (localStorage.getItem(USER_CONFIG) ? JSON.parse(localStorage.getItem(USER_CONFIG)) : {});

const setUserConfig = (value) => localStorage.setItem(USER_CONFIG, JSON.stringify({ ...getUserConfig(), ...value }));

export const setHasOrigin = (value) => setUserConfig({
  hasOrigin: value,
});

export const hasOrigin = () => getUserConfig()?.hasOrigin;

export const setHasGoogleVerify = (value) => setUserConfig({
  hasGoogleVerify: value,
});

export const hasGoogleVerify = () => getUserConfig()?.hasGoogleVerify;
