/* eslint-disable global-require */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import Confirmation from '@/components/Confirmation';
import CustomIcons from '@/components/CustomIcons';
import { AuthContext } from '@/context/AuthContext';
import { isSupportUser } from '@/util/utils';
import { Avatar, IconWrapper, Button, Typography, Select } from '@partners-core/components';
import partners from '@partners-core/partners-js';
import React, { useContext, useState } from 'react';
import { Row, Col, Card, Modal, Dropdown, Spinner } from 'react-bootstrap';
import { BiMailSend } from 'react-icons/bi';
import { FaUserSlash } from 'react-icons/fa';
import { FiMoreVertical, FiTrash2, FiX } from 'react-icons/fi';
import { HiLink } from 'react-icons/hi';
import { RiShieldUserLine } from 'react-icons/ri';
import { Tooltip } from 'react-tippy';
import { useToasts } from 'react-toast-notifications';

import inviteUser from '../../../api/mutation/auth/invite-user';
import removeUser from '../../../api/mutation/user/remove-user';
import UserInviteForm from './UserInviteForm';

const Users = ({ profile, setActiveKey }) => {
  const { user: userAuth } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(profile?.owner?.users);
  const [selectedUser, setSelectedUser] = useState(null);
  const [removeUserId, setRemoveUserId] = useState(null);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const { addToast } = useToasts();

  const removeSelectedUser = async () => {
    setLoadingRemove(true);

    const resp = await removeUser({ newUserId: selectedUser.value, removeUserId });
    if (resp?.remove_user?.success) {
      setUsers(users.filter((u) => u.id !== removeUserId));
      addToast('Usuário foi removido com sucesso.', {
        appearance: 'snack',
        autoDismiss: true,
        autoDismissTimeout: 6500,
        dismissText: 'Fechar',
      });
      setLoadingRemove(false);
      setShowRemoveModal(false);
    } else {
      addToast('Ocorreu um erro inesperado ao remover usuário. Tente novamente e caso o erro persista entre em contato com suporte.', {
        appearance: 'snack',
        autoDismiss: true,
        autoDismissTimeout: 6500,
        dismissText: 'Fechar',
      });
      setLoadingRemove(false);
      setShowRemoveModal(false);
    }
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      className="btn btn-link p-0 c-dark"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <FiMoreVertical className="dropdown-ellipses" size="18" strokeWidth="1px" />
    </button>
  ));

  const resendInvite = async (email) => {
    setLoading(true);
    const invitation = {
      email,
      owner_id: profile.owner.id,
      resend: true,
    };

    await inviteUser(invitation);

    addToast(`Convite reenviado para ${email}`, {
      appearance: 'snack',
      autoDismiss: true,
      autoDismissTimeout: 6500,
    });
    setLoading(false);
  };

  const onSubmit = (newUser) => {
    setShowModal(false);
    setUsers([...users, newUser]);
  };

  const handleChangeUser = (option) => {
    setSelectedUser(option);
  };

  const selectRemoveUser = async (user) => {
    setRemoveUserId(user.id);
    setShowRemoveModal(true);
  };

  const handleUserLocal = ({ id, meta }) => {
    const usersFormated = users.map((user) => {
      if (user.id === id) {
        return { ...user, meta };
      }
      return user;
    });
    setUsers(usersFormated);
  };

  const handleChangeUserMeta = async ({ id, meta }) => {
    await partners.user.update({
      where: {
        id: { _eq: id },
      },
      _set: {
        meta,
      },
    });
  };

  const handleUserAdminPermission = async ({ id, meta, isAdmin }) => {
    const metaParsed = { ...meta, admin: isAdmin };
    await handleChangeUserMeta({ id, meta: metaParsed });
    handleUserLocal({ id, meta: metaParsed });
    addToast('As permissões foram atualizadas com sucesso!', {
      appearance: 'snack',
      autoDismiss: true,
      autoDismissTimeout: 6500,
    });
  };

  const handleHasOptions = (user) => {
    if ((user.id !== profile.id && !user?.meta?.master && (userAuth?.meta?.master || userAuth?.meta?.admin))) {
      return true;
    }

    return false;
  };

  const copyToClipboard = (user) => {
    const link = `${process.env.REACT_APP_SERVICE_HOST_CLIENT_WEB}/invite/${user.email}/${user.confirm_email_token}`;
    const elem = document.createElement('textarea');
    elem.value = link;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    addToast('Link do convite copiado com sucesso!', {
      appearance: 'snack',
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
  };

  return (
    <>
      <Row>
        <Col xs="auto" className="pr-0">
          <IconWrapper className="mr-3" width="44px" height="40px">
            <CustomIcons.UsersCustom color="#B0AAFF" size="24" />
          </IconWrapper>
        </Col>
        <Col>
          <h4 className="bold mt-3">
            Usuários
          </h4>
          <small>
            Aqui você pode adicionar e editar os usuários.
          </small>
          <hr />

          <Card className="mt-5">
            <Card.Header className="bg-white pl-0">
              <h5 className="card-header-title bold">
                Membros
              </h5>
              <Button variant="purple" size="sm" onClick={() => setShowModal(true)}>
                Adicionar
              </Button>
            </Card.Header>
            <Card.Body className="px-0 bg-white py-3">
              <div className="list-group list-group-flush my-n3">
                {users.filter((u) => !isSupportUser(u.email)).map((user, i) => {
                  const hasOptions = handleHasOptions(user);
                  return (
                    <div className="list-group-item" key={user.id || i}>
                      <Row className="align-items-center b-0">
                        <Col xs="auto">
                          <Avatar
                            src={!user?.avatar && require('../../../assets/img/avatar.png')}
                            rounded
                            title={`${user.first_name}${user.last_name ? ` ${user.last_name}` : ''}`}
                            size="sm"
                          />
                        </Col>
                        <Col xs={5} className="ml-n2">
                          <h5 className="mb-1">
                            {user.first_name}
                            {' '}
                            {user.last_name}
                            {!!user?.meta?.master && <span className="ml-2 badge bg-white ms-auto border">Usuário principal</span>}
                            {!!user?.meta?.admin && <span className="ml-2 badge bg-blue ms-auto text-white">Admin</span>}
                          </h5>

                          <p className="small-75 text-muted mb-0">
                            <span className="d-block text-reset text-truncate">{user.email}</span>
                          </p>
                        </Col>
                        <Col xs="auto" className="ml-auto mr-n3">
                          {(user.invited && !user.confirmed_email && user.id !== profile.id)
                            && (
                              <div className="d-flex flex-row text-center">
                                <h5 className="mb-0 c-dark mr-2" style={{ marginTop: 4 }}>Convite enviado</h5>
                                <Tooltip position="bottom" title="Reenviar convite">
                                  {loading
                                    ? <Spinner className="mr-2" size="sm" animation="border" />
                                    : <BiMailSend cursor="pointer" className="mr-2" size={18} onClick={() => resendInvite(user.email)} />}
                                </Tooltip>
                                <Tooltip position="bottom" title="Copiar link do convite">
                                  <HiLink cursor="pointer" size={18} onClick={() => copyToClipboard(user)} />
                                </Tooltip>
                              </div>
                            )}
                        </Col>

                        <Col xs="auto">
                          <Dropdown drop="left">
                            {hasOptions && <Dropdown.Toggle as={CustomToggle} />}
                            <Dropdown.Menu size="sm" title="">

                              {(!user?.meta?.admin && !user?.meta?.master && userAuth.meta?.admin) && (
                                <Row className="ml-2 px-2 my-2 pointer">
                                  <Confirmation
                                    placement="bottom"
                                    message="Tem certeza que deseja tornar como administrador este usuário?"
                                    onSuccess={() => handleUserAdminPermission({ id: user.id, meta: user.meta, isAdmin: true })}
                                  >
                                    <Button
                                      variant="link"
                                      className="p-0 font-weight-regular"
                                    >
                                      <RiShieldUserLine size="14" className="mr-2 c-dark" />

                                      Tornar admin
                                    </Button>
                                  </Confirmation>
                                </Row>
                              )}

                              {(user?.meta?.admin && !user?.meta?.master && userAuth?.meta?.master) && (
                                <Row className="ml-2 px-2 my-2 pointer">
                                  <Confirmation
                                    placement="bottom"
                                    message="Tem certeza que deseja remover como administrador este usuário?"
                                    onSuccess={() => handleUserAdminPermission({ id: user.id, meta: user.meta, isAdmin: false })}
                                  >
                                    <Button
                                      variant="link"
                                      className="p-0 font-weight-regular"
                                    >
                                      <FaUserSlash size="14" className="mr-2 c-dark" />
                                      Remover admin
                                    </Button>
                                  </Confirmation>
                                </Row>
                              )}

                              {(user?.id !== profile?.id && !user?.meta?.master && userAuth?.meta?.admin) && (
                                <Row className="ml-2 px-2 my-2 pointer">
                                  <Confirmation
                                    placement="bottom"
                                    message="Tem certeza que deseja remover como administrador este usuário?"
                                    onSuccess={() => selectRemoveUser(user)}
                                  >
                                    <Button
                                      variant="link"
                                      className="p-0 font-weight-regular"
                                    >
                                      <FiTrash2 size="14" className="mr-2 c-dark" />

                                      Excluir usuário
                                    </Button>
                                  </Confirmation>
                                </Row>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </Card.Body>

          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={setShowModal} centered>
        <Card className="modal-card">
          <Card.Header>
            <h4 className="card-header-title">
              Adicionar Usuário
            </h4>
            <FiX onClick={() => setShowModal(false)} className="c-dark pointer" size="18" />
          </Card.Header>
          <Card.Body>
            <UserInviteForm ownerId={profile?.owner?.id} onSubmit={onSubmit} />
          </Card.Body>
        </Card>

      </Modal>

      <Modal show={showRemoveModal} onHide={setShowRemoveModal} centered>
        <Card className="modal-card">
          <Card.Header>
            <h4 className="card-header-title">
              Remover Usuário
            </h4>
            <FiX onClick={() => setShowRemoveModal(false)} className="c-dark pointer" size="18" />
          </Card.Header>
          <Card.Body>
            <Typography variant="h5" fontWeight="400" className="c-dark">Usuário para receber os dados:</Typography>
            <p className="text-muted font-size-sm">
              Selecione um usuário para receber os negócios, propostas, registro de ligações e agendamentos do usuário a ser removido.
            </p>
            <Select
              menuPortalTarget={document.body}
              name="userOwner"
              value={selectedUser}
              onChange={handleChangeUser}
              options={users
                .filter((u) => u.first_name !== null && u.last_name !== null && !isSupportUser(u.email)
                  && u.id !== removeUserId)
                .map((user) => ({ value: user.id, label: `${user.first_name} ${user.last_name}`, avatar: user.avatar }))}
              disabled
              placeholder="Selecione um usuário"
            />
            {selectedUser
              && (
                <Button variant="danger" className="mt-4" block onClick={removeSelectedUser} loading={loadingRemove}>
                  Remover usuário
                </Button>
              )}
          </Card.Body>
        </Card>

      </Modal>
    </>
  );
};

export default Users;
