import Logo from '@/assets/img/brand/eduzz-logo.svg';
import UserInviteForm from '@/components/Partner/Settings/UserInviteForm';
import { AuthContext } from '@/context/AuthContext';
import { Avatar, Typography } from '@partners-core/components';
import { Link } from 'found';
import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const OnboardInvite = () => {
  const [invitedList, setInvitedList] = useState([]);
  const { user } = useContext(AuthContext);

  const onSubmit = (invited) => {
    setInvitedList([...invitedList, invited]);
  };

  return (
    <Container>

      <Row className="align-items-center justify-content-center">
        <Col xs="12" lg="8" xl="6">
          <div className="header mt-md-4 b-0">
            <div className="header-body b-0">
              <div className="display-4 text-center mb-5">
                <img alt="" src={Logo} height="36" className="d-inline-block align-top mh-100 my-3 px-4" />
              </div>

              <h1 className="header-title c-dark mt-4 bold text-center">
                Bem vindo a Eduzz!
              </h1>

            </div>
          </div>
        </Col>

      </Row>
      <Row className="align-items-center justify-content-center">
        <Col xs="12" lg="6" xl="6">
          <div className="list-group list-group-flush mb-3">
            {invitedList.map((invited) => (
              <div className="list-group-item">
                <div className="row">
                  <div className="col-auto">
                    <Avatar rounded size="xs" title={invited.email} />
                  </div>
                  <div className="col ml-n2">

                    <h5 className="font-weight-normal mb-0 c-dark">
                      {invited.email}
                    </h5>

                    <small className="text-muted small-75">
                      Convite enviado
                    </small>

                  </div>

                </div>
              </div>
            ))}

          </div>
          <Typography variant="h5" fontWeight="500" className="c-dark mb-4">
            Quem mais está no seu time?
          </Typography>
          <UserInviteForm ownerId={user.owner_id} onSubmit={onSubmit} />
          <Row className="align-items-center justify-content-center text-center mt-3 ">
            <Col>
              <Link to="/deal" className={`btn btn-link ${invitedList.length === 0 ? 'text-muted' : 'c-blue'}`}>
                {invitedList.length === 0 ? 'ou, pular esta etapa' : 'Ir para próxima etapa'}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
  );
};
export default OnboardInvite;
