const enums = {
  AUTH_USER: 'AUTH_USER',
};

function set(KEY, obj) {
  if (!enums[KEY]) return new Error('KEY not found in enums');

  window.localStorage.setItem(KEY, JSON.stringify(obj));
}

function get(KEY) {
  if (!enums[KEY]) return new Error('KEY not found in enums');

  const item = window.localStorage.getItem(KEY);
  return JSON.parse(item);
}

const localStorage = {
  enums,
  set,
  get,
};

export default localStorage;
