/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const Shogun = forwardRef(({ gradient = false, color = 'none', size = 75, fill = 'url(#paint0_linear_1561_703)', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75 75"
    fill="none"
    stroke={color}
    x="0px"
    y="0px"
    {...rest}
  >
    <path d="M69,9.5c0-0.1,0-0.1-0.1-0.1l-0.1-0.1l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
      l-9.8,8.4c-1.5-1.3-3.1-2.4-4.7-3.4c1.6-6.7,0-13.1,0-13.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0h-0.2c0,0-0.1,0-0.1,0.1L48,5.8
      C47.8,6,47.8,6,47.8,6.2c0.2,1.8,0.3,3.7,0.2,5.5c-2.4-0.8-4.9-1.4-7.5-1.6l0-3.3c0-0.3,0-0.5,0-0.8c-0.1-0.2-0.2-0.5-0.4-0.7
      l-2.1-2c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-1.9,1.9-2.1,2c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.1,0.5,0,0.8
      l0,3.3c-2.5,0.2-5,0.8-7.5,1.6c-0.1-1.8-0.1-3.7,0.2-5.5c0-0.2-0.1-0.2-0.3-0.5l-5.3-4.6c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0
      c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0-1.6,6.4,0,13.1c-1.7,1-3.3,2.1-4.7,3.4
      L6.7,9.4c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0L6.1,9.4C6,9.4,6,9.5,6,9.5c0,0-3.2,12.8,0.2,26
      c-0.4,2.1-0.6,4.2-0.6,6.4c0,8.5,3.4,16.6,9.4,22.6c6,6,14.1,9.4,22.6,9.4c8.5,0,16.6-3.4,22.6-9.4c6-6,9.4-14.1,9.4-22.6
      c0-2.1-0.2-4.3-0.6-6.4C72.2,22.3,69,9.5,69,9.5z M57.7,22.2c0.2,3.4,0.3,9.2-1.2,15.2c-1.4,6.1-5.1,11.4-10.2,14.9
      c8.1-9.9,8.1-16.7,8-16.9c0-0.1,0-0.2-0.1-0.3c0-0.1-3-4.4-7.6-6.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0.1-0.5,0.5-0.5,0.6
      c-1.3,11.6-5.7,18.6-5.7,18.6L40.1,29c3-1.3,5.8-3.1,8.2-5.3c1.7-1.6,3.1-3.6,4-5.7C54.3,19.1,56.1,20.6,57.7,22.2L57.7,22.2z
      M47.3,15.4c0,0.1,0,0.2-0.1,0.2c-0.5,1.9-1.3,3.7-2.6,5.2c-1.2,1.5-2.7,2.8-4.5,3.6l0.1-10.7C42.7,14,45.1,14.5,47.3,15.4
      L47.3,15.4z M34.4,13.8l0.1,10.7c-1.7-0.9-3.3-2.1-4.5-3.6c-1.2-1.5-2.1-3.3-2.6-5.2c0-0.1,0-0.2-0.1-0.2
      C29.7,14.5,32.1,14,34.4,13.8L34.4,13.8z M26.4,23.7c2.4,2.2,5.2,4.1,8.2,5.3l0.2,19.1c0,0-4.4-7-5.7-18.6c0-0.1-0.1-0.5-0.5-0.6
      c-0.2,0-0.4,0-0.6,0.1c-4.6,1.7-7.5,6-7.6,6.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,6.9,8,16.9c-5.1-3.6-8.7-8.9-10.2-14.9
      c-1.5-6-1.4-11.8-1.2-15.2c1.6-1.6,3.3-3,5.3-4.2C23.3,20.1,24.7,22,26.4,23.7L26.4,23.7z M37.4,70.2c-7.2,0-14.1-2.7-19.3-7.7
      c-5.2-4.9-8.4-11.6-8.9-18.8c1.8,3.5,4.1,6.6,6.9,9.2c10,9.4,21.3,12.3,21.3,12.3S48.7,62.4,58.7,53c2.8-2.7,5.2-5.8,6.9-9.2
      c-0.5,7.2-3.7,13.9-8.9,18.8C51.5,67.4,44.6,70.2,37.4,70.2L37.4,70.2z" fill={fill}
    />
    { gradient
    && (
      <linearGradient id="paint0_linear_1561_703" x1="24.489" y1="0" x2="24.489" y2="54.2159" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E99048" />
        <stop offset="1" stopColor="#FFAD80" />
      </linearGradient>
    )}
  </svg>
));

export default Shogun;
