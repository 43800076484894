import { useField } from '@unform/core';
import React, { useRef, useEffect, useState } from 'react';

import DatePicker from '../DatePicker/DatePicker';

const FormDatePicker = ({ name, options, triggerOpen, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    if (triggerOpen) {
      datepickerRef.current.setOpen(true);
    }
  }, [triggerOpen]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <DatePicker
        ref={datepickerRef}
        selected={date}
        onChange={setDate}
        {...rest}
      />
      {error && <span className="text-danger font-size-xs ml-2 font-weight-bold">{error}</span>}
    </>
  );
};
export default FormDatePicker;
