/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type user_set_input = {|
  api4com_extension?: ?any,
  api4com_password?: ?string,
  avatar?: ?string,
  confirm_email_date_expired?: ?any,
  confirm_email_token?: ?string,
  confirmed_email?: ?boolean,
  created_at?: ?any,
  email?: ?string,
  facebook_external_id?: ?string,
  first_name?: ?string,
  google_access_token?: ?string,
  google_expiry_date?: ?any,
  google_external_id?: ?string,
  google_refresh_token?: ?string,
  id?: ?any,
  identifier?: ?string,
  intercom_id?: ?string,
  invited?: ?boolean,
  last_name?: ?string,
  link?: ?string,
  meta?: ?any,
  owner_id?: ?any,
  pagarme_customer_id?: ?number,
  password?: ?string,
  permissions?: ?any,
  phone?: ?string,
  reset_password_date_expired?: ?any,
  reset_password_token?: ?string,
  tours?: ?any,
  updated_at?: ?any,
  whatsapp?: ?string,
|};
export type updateUserMutationVariables = {|
  id: any,
  data?: ?user_set_input,
|};
export type updateUserMutationResponse = {|
  +update_user_by_pk: ?{|
    +id: any
  |}
|};
export type updateUserMutation = {|
  variables: updateUserMutationVariables,
  response: updateUserMutationResponse,
|};
*/


/*
mutation updateUserMutation(
  $id: uuid!
  $data: user_set_input
) {
  update_user_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "uuid!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data",
    "type": "user_set_input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "data"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "user",
    "kind": "LinkedField",
    "name": "update_user_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateUserMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateUserMutation",
    "operationKind": "mutation",
    "text": "mutation updateUserMutation(\n  $id: uuid!\n  $data: user_set_input\n) {\n  update_user_by_pk(pk_columns: {id: $id}, _set: $data) {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '59697cf01cfa9a3f93ed2c2bf364f552';

module.exports = node;
