import 'react-tippy/dist/tippy.css';
import './assets/scss/theme.scss';
import { Snack } from '@partners-core/components';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import { BrowserProtocol, queryMiddleware } from 'farce';
import { createFarceRouter } from 'found';
import { createNamedRoutesMiddleware } from 'found-named-routes';
import { Resolver } from 'found-relay';
import React from 'react';
import { hot } from 'react-hot-loader';
import { ToastProvider } from 'react-toast-notifications';
import { RelayEnvironmentProvider } from 'relay-hooks';
import 'dayjs/locale/pt-br';
import { SidebarProvider } from '@/context/SidebarContext';
import { Toaster } from 'react-hot-toast';

import environment from './config/environment';
import { AuthProvider } from './context/AuthContext';
import { locales, I18nProvider } from './locales';
import Router from './router/index.jsx';

const namedRoutesMiddleware = createNamedRoutesMiddleware(Router);

const resolver = new Resolver(environment);

dayjs.locale('pt-br');
Dinero.globalLocale = 'pt-BR';
Dinero.defaultCurrency = 'BRL';
Dinero.defaultPrecision = 2;

const RouterRelay = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [namedRoutesMiddleware, queryMiddleware],
  routeConfig: Router,
});

window.router = RouterRelay;
const TOAST_COMPONENTS = { Toast: Snack };
const App = () => (
  <I18nProvider locale={locales.BRAZILIAN_PORTUGUESE}>
    <AuthProvider>
      <ToastProvider components={TOAST_COMPONENTS} placement="bottom-center">
        <SidebarProvider>
          <RelayEnvironmentProvider environment={environment}>
            <RouterRelay resolver={resolver} />
          </RelayEnvironmentProvider>
        </SidebarProvider>
      </ToastProvider>
    </AuthProvider>
    <Toaster
      position="bottom-right"
      reverseOrder={false}
    />
  </I18nProvider>
);

export default hot(module)(App);
