import { ReactComponent as WhatsApp } from '@/assets/img/icons/whatsapp.svg';
import { Typography, Avatar, Button } from '@partners-core/components';
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';

const FooterDashboard = ({ support, handleEditing }) => (
  <Row>
    <Col xs="12">
      <Card className="p-6 bg-white">
        <Row className="justify-content-between">
          <Typography className="c-dark pt-2 font-weight-bold pl-3" variant="h5">
            Fale com a gente
          </Typography>
          <Button
            className="mr-2 text-primary"
            variant="link"
            size="sm"
            onClick={handleEditing}
            tooltip="Editar Suporte"
          >
            <AiOutlineEdit size="18" />
          </Button>
        </Row>
        <Typography className="c-dark pt-2" variant="h5" percent="90">
          Queremos saber como podemos melhorar, nos envie suas dúvidas, sugestões ou reclamações.
        </Typography>
        <Row className="py-4">
          <Col sm="4">
            <Typography className="c-dark font-weight-bold pb-2" variant="h5" percent="90">
              Dados do seu Customer Success Manager:
            </Typography>
            <Row>
              <Col xs="auto">
                <Avatar title={support?.CS?.name} size="xs" roundedCircle />
              </Col>
              <Col>
                <Typography className="c-dark font-weight-bold " variant="h5" percent="90">
                  {support?.CS?.name}
                </Typography>
                <Row className="py-2">
                  <FiMail size="14" className="c-dark mr-2 ml-3" />
                  <Typography className="c-dark " variant="h6" percent="100">
                    {support?.CS?.email}
                  </Typography>
                </Row>
                <Row>
                  <WhatsApp className="c-dark mr-2 ml-3" width="13" height="13" stroke="#3D3A6C" strokeWidth="0px" />
                  <Typography className="c-dark" variant="h6" percent="100">
                    {support?.CS?.phone}
                  </Typography>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col sm="4">
                        <Typography className="c-dark font-weight-bold pb-2" variant="h6">
                          Dados do seu Customer Technical Support
                        </Typography>
                        <Row>
                          <Col xs="auto">
                            <Avatar title={support?.CT?.name} size="xs" roundedCircle />
                          </Col>
                          <Col>
                            <Typography className="c-dark font-weight-bold " variant="h6">
                              {support?.CT?.name}
                            </Typography>
                            <Row className="py-2">
                              <Mail size="14" className="c-dark mr-2 ml-3" />
                              <Typography className="c-dark " variant="h6" percent="90">
                                {support?.CT?.email}
                              </Typography>
                            </Row>
                            <Row>
                              <WhatsApp className="c-dark mr-2 ml-3" width="13" height="13" stroke="#3D3A6C" strokeWidth="0px" />
                              <Typography className="c-dark" variant="h6" percent="90">
                                {support?.CT?.phone}
                              </Typography>
                            </Row>
                          </Col>
                        </Row>
                      </Col> */}
        </Row>
      </Card>
    </Col>
  </Row>
);
export default FooterDashboard;
