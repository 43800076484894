/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthSocialType = "FACEBOOK" | "GOOGLE" | "LOCAL" | "%future added value";
export type login_loginQueryVariables = {|
  email?: ?string,
  password?: ?string,
  credential?: ?string,
  auth_type: AuthSocialType,
|};
export type login_loginQueryResponse = {|
  +login: ?{|
    +id: string,
    +has_google_verify: ?boolean,
    +first_name: ?string,
    +last_name: ?string,
    +email: string,
    +avatar: ?string,
    +confirmed_email: ?boolean,
    +roles: $ReadOnlyArray<string>,
    +token: string,
    +owner_id: string,
    +owner_name: ?string,
    +owner_type: string,
    +information: ?string,
    +permissions: $ReadOnlyArray<string>,
  |}
|};
export type login_loginQuery = {|
  variables: login_loginQueryVariables,
  response: login_loginQueryResponse,
|};
*/


/*
query login_loginQuery(
  $email: String
  $password: String
  $credential: String
  $auth_type: AuthSocialType!
) {
  login(data: {email: $email, password: $password, credential: $credential, auth_type: $auth_type}) {
    id
    has_google_verify
    first_name
    last_name
    email
    avatar
    confirmed_email
    roles
    token
    owner_id
    owner_name
    owner_type
    information
    permissions
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "credential",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "auth_type",
    "type": "AuthSocialType!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "auth_type",
            "variableName": "auth_type"
          },
          {
            "kind": "Variable",
            "name": "credential",
            "variableName": "credential"
          },
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
          }
        ],
        "kind": "ObjectValue",
        "name": "data"
      }
    ],
    "concreteType": "LoginOutput",
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "has_google_verify",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confirmed_email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "information",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "login_loginQuery",
    "selections": (v1/*: any*/),
    "type": "query_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "login_loginQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "login_loginQuery",
    "operationKind": "query",
    "text": "query login_loginQuery(\n  $email: String\n  $password: String\n  $credential: String\n  $auth_type: AuthSocialType!\n) {\n  login(data: {email: $email, password: $password, credential: $credential, auth_type: $auth_type}) {\n    id\n    has_google_verify\n    first_name\n    last_name\n    email\n    avatar\n    confirmed_email\n    roles\n    token\n    owner_id\n    owner_name\n    owner_type\n    information\n    permissions\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5aa2c7ed15b79f065d8d9ef251bd482e';

module.exports = node;
