import { ReactComponent as BgSelo } from '@/assets/img/bg-selo.svg';
import { ReactComponent as IconBonus } from '@/assets/img/icons/icon-bonus.svg';
import { ReactComponent as IconMission } from '@/assets/img/icons/icon-mission.svg';
import { ReactComponent as IconPrize } from '@/assets/img/icons/icon-prize.svg';
import { Typography } from '@partners-core/components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FiX } from 'react-icons/fi';

import { ModalLevel, RowGradient } from './styles';

const LevelsModal = ({ showModal, setShowModal, getIcon, levelSelected, perks }) => (
  <ModalLevel show={showModal} onHide={setShowModal} centered size={isMobile ? 'xxl' : 'lg'}>
    <Row className="m-0">
      <Col xs="12" sm="5" className="m-0 p-0">
        <RowGradient className={isMobile ? 'm-0 row-mobile' : 'm-0 py-4 row-browser'}>
          {isMobile
            && (
              <Row className="justify-content-end w-100 pt-3 ">
                <FiX onClick={() => setShowModal(false)} className="c-white pointer" size="18" />
              </Row>
            )}
          <Col className="px-4 pt-4">
            <Row className="justify-content-start">
              <Col xs="auto">
                <Row className="pl-3 align-items-start justify-content-center">
                  <BgSelo width={140} height={140} style={{ position: 'relative', marginTop: '-40px' }} />
                  {getIcon({ type: levelSelected?.name, fill: '#FFCB00', size: 35, className: 'mt-3', style: { position: 'absolute' } })}
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-start pl-5">
              <Typography className="c-white" variant="h2">
                {levelSelected?.label}
              </Typography>
            </Row>
            <Row className="justify-content-start pl-5">
              <Typography className="c-white" variant="h5" percent="90">
                {levelSelected?.descriptive?.subtitle}
              </Typography>
            </Row>
            <Row>
              <div className="border-separator-purple py-2 px-5" />
            </Row>
            <Row className="justify-content-start pl-5 pr-4">
              <Typography className="c-white" variant="p" percent="80">
                {levelSelected?.descriptive?.text}
              </Typography>
            </Row>
          </Col>
        </RowGradient>
      </Col>
      <Col sm="7" xs="12" className="py-4 pl-6">
        <Row className="m-0" style={isMobile
          ? null
          : { overflow: 'auto', minHeight: '450px', maxHeight: '450px' }}
        >
          <Col>
            <Row className="align-items-center pl-3 pb-2">
              <IconPrize color="#2F2C5D" width="15px" strokeWidth="0px" />
              <Typography className="c-dark pl-2 m-0" variant="h4" percent="90">
                Benefícios
              </Typography>
            </Row>
            {
              perks
                .filter((p) => p.type_enum === 'BENEFIT' && p.level_id === levelSelected.id)
                .map((perk, index) => (
                  <Row className="align-items-center pr-6 pt-2" key={`benefit-${index}`}>
                    <Typography className="c-dark pl-3" variant="h6">
                      ●
                      {'  '}
                      {perk.name}
                    </Typography>
                  </Row>
                ))
            }
            <Row>
              <div className="border-separator py-3" />
            </Row>
            <Row className="align-items-center pl-3 pb-2">
              <IconMission color="#2F2C5D" width="15px" strokeWidth="0px" />
              <Typography className="c-dark pl-2 m-0" variant="h4" percent="90">
                Missões
              </Typography>
            </Row>
            {
              perks
                .filter((p) => p.type_enum === 'MISSION' && p.level_id === levelSelected.id)
                .map((perk, index) => (
                  <Row className="align-items-center pr-6 pt-2" key={`mission-${index}`}>
                    <Typography className="c-dark pl-3" variant="h6">
                      ●
                      {'  '}
                      {perk.name}
                    </Typography>
                  </Row>
                ))
            }
            <Row>
              <div className="border-separator py-3" />
            </Row>
            <Row className="align-items-center pl-3">
              <IconBonus color="#2F2C5D" width="15px" strokeWidth="0px" />
              <Typography className="c-dark m-0 pl-2" variant="h4" percent="90">
                Bônus
              </Typography>
            </Row>
            {
              perks
                .filter((p) => p.type_enum === 'BONUS' && p.level_id === levelSelected.id)
                .map((perk, index) => (
                  <Row className="align-items-center pr-6 pt-2" key={`bonus-${index}`}>
                    <Typography className="c-dark pl-3" variant="h6">
                      ●
                      {'  '}
                      {perk.name}
                    </Typography>
                  </Row>
                ))
            }
          </Col>
        </Row>
      </Col>
    </Row>
  </ModalLevel>
);

export default LevelsModal;
