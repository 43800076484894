import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

import Input from '../Input/Input';

const FormInput = ({ name, onFocus, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  const onFocusExecution = () => {
    if (onFocus) onFocus();
    clearError();
  };

  return (
    <>
      <Input
        id={fieldName}
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={onFocusExecution}
        {...rest}
      />
      {error && <span className="text-danger font-size-xs ml-2 mt-2 font-weight-bold">{error}</span>}
    </>
  );
};

export default FormInput;
