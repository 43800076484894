import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const TableCustomerHover = styled(Table)`

&.table-hover tbody tr:hover td {
  .font-bold {
    font-weight: bold !important;
  }
}


`;
