/* eslint-disable no-unused-vars */
import hasuraClient from '@partners-core/hasura-client';
import {
  set,
  camelCase,
  first,
  last,
} from 'lodash';

import * as metadata from '../data/metadata.json';
import mapping from './mapping';

const resources = Object.values(mapping).map(({ resource }) => resource);

const tables = metadata.tables.map(({ table }) => table);
const actions = metadata.actions.map((action) => ({
  name: action.name,
  type: action.type,
  resource: first(action.name.split('_')),
}));

const client = {};

const modules = [...new Set([...resources, ...tables])];

modules.forEach((module) => {
  const moduleName = camelCase(module);

  const isTable = tables.includes(module);

  if (isTable) {
    client[moduleName] = hasuraClient.repository(module);
  } else {
    client[moduleName] = {};
  }

  actions.forEach((action) => {
    const mapAction = mapping[action.name];

    if (mapAction && mapAction.resource === module) {
      if (isTable) {
        set(client, `${moduleName}.${mapAction.name}`, client[moduleName][action.type](action.name));
      } else {
        set(client, `${moduleName}.${mapAction.name}`, hasuraClient[action.type](action.name));
      }
      return;
    }

    if (action.resource === module) {
      const name = camelCase(
        action
          .name
          .split('_')
          .slice(1)
          .join('_'),
      );
      if (isTable) {
        set(client, `${moduleName}.${name}`, client[moduleName][action.type](action.name));
      } else {
        set(client, `${moduleName}.${name}`, hasuraClient[action.type](action.name));
      }
    }
  });
});

export default client;
