/* eslint-disable no-unused-expressions */

import { isEmpty } from 'lodash';

import { setState } from './config';

const configure = ({
  baseURL,
  authorization = 'Authorization',
  metadata = {},
  logger = true,
}) => {
  baseURL && setState('baseURL', baseURL);
  authorization && setState('authorization.key', authorization);
  !isEmpty(metadata) && setState('metadata', metadata);
  setState('logger', logger);
};

export default configure;
