import React from 'react';
import * as ReactSkeleton from 'react-loading-skeleton';
import styled from 'styled-components';
// import { Container } from './styles';
const StyledSkeleton = styled(ReactSkeleton.default)`
  &&& {
    border-radius: ${(props) => (props.radius ? props.radius : '4px')}
  }
`;

const WhiteStyledSkeleton = styled(StyledSkeleton)`
  &&& {
      background-color: #fdfdfd;
      background-image: linear-gradient( 90deg,#f9f9f9,#f5f5f5,#ffffff );
  }
`;

const BlueStyledSkeleton = styled(StyledSkeleton)`
  &&& {
      background-color: #1f2365;
      background-image: linear-gradient( 90deg,#202165,#22266a,#1a1c5d );
  }
`;

const Skeleton = (props) => {
  const { block, className, light, variant } = props;
  let classes = '';
  if (block) {
    classes += ' d-block';
  }
  classes += ` ${className}`;
  if (light) {
    return (
      <WhiteStyledSkeleton {...props} className={classes} />
    );
  }
  if (variant === 'blue') {
    return (
      <BlueStyledSkeleton {...props} className={classes} />
    );
  }
  return (
    <StyledSkeleton {...props} className={classes} />
  );
};

export default Skeleton;
