import Logo from '@/assets/img/brand/logo-eduzz-azul.svg';
import Lines from '@/assets/img/lines.svg';
import CardSignIn from '@/components/Auth/CardSignIn';
import React from 'react';
import { Row, Col, Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';

const AuthBackground = styled.div`
  background-image: url(${Lines});
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: -770px -233px;
`;

const Login = ({ router }) => {
  const handleHome = () => {
    router.replace('/login');
  };

  return (
    <AuthBackground className="min-vh-100 bg-auth">
      <Navbar variant="primary" expand="lg" className="p-0 b-0 mb-6">
        <Container fluid className="px-6 c-white">
          <Navbar.Brand href="#" onClick={handleHome}>
            <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top mh-100 my-4 " />
          </Navbar.Brand>

          {/* <Link to="/signup" className="c-white font-size-md font-weight-normal">
            Criar conta
          </Link> */}
        </Container>
      </Navbar>
      <Container fluid="lg">
        <Row className="align-items-center">
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <CardSignIn />
          </Col>
        </Row>
      </Container>
    </AuthBackground>
  );
};

export default Login;
