/* eslint-disable no-nested-ternary */
import { range, times } from 'lodash';
import React, { useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

const PageNavigation = ({ filter, countProject, loading, setFilter, onChange }) => {
  const { offset, limit } = filter;
  const currentPage = offset / limit;
  const pageQuantity = Math.ceil(countProject / limit);
  const isSmallerThanLastPage = (currentPage + 5) < pageQuantity;
  const pageInitialValidation = ((currentPage === 0) ? currentPage + 1 : currentPage);

  useEffect(() => {
    if (onChange) {
      onChange();
    }
  }, [filter]);

  const previous = () => {
    if (loading) return;
    setFilter({
      ...filter,
      offset: offset - limit,
    });
  };

  const next = () => {
    if (loading) return;
    setFilter({
      ...filter,
      offset: offset + limit,
    });
  };

  const paginate = (index) => {
    if (loading) return;
    setFilter({
      ...filter,
      offset: (limit * index),
    });
  };

  return (
    <>
      {(pageQuantity > 5)
        ? (
          <Pagination size="sm">
            <Pagination.Item disabled={!offset} onClick={previous}>
              Anterior
            </Pagination.Item>

            <Pagination.Item onClick={() => !(currentPage === 0) && paginate(0)} key="inicial" active={currentPage === 0}>
              1
            </Pagination.Item>

            {(currentPage > 1) ? <Pagination.Ellipsis /> : null}

            {range(isSmallerThanLastPage ? pageInitialValidation : pageQuantity - 5,
              (isSmallerThanLastPage) ? currentPage + 4 : pageQuantity - 1).map((number) => {
              const isActive = (number) === currentPage;

              return (
                <Pagination.Item onClick={() => !isActive && paginate(number)} key={number} active={isActive}>
                  {number + 1}
                </Pagination.Item>
              );
            })}

            {(isSmallerThanLastPage) ? <Pagination.Ellipsis /> : null}

            <Pagination.Item
              onClick={() => !(pageQuantity - 1 === currentPage) && paginate(pageQuantity - 1)}
              key={pageQuantity}
              active={currentPage === pageQuantity - 1}
            >
              {pageQuantity}
            </Pagination.Item>

            <Pagination.Item disabled={(limit + offset) > countProject} onClick={next}>
              Próximo
            </Pagination.Item>

          </Pagination>
        )
        : (
          <Pagination size="sm">
            <Pagination.Item disabled={!offset} onClick={previous}>
              Anterior
            </Pagination.Item>

            {times(pageQuantity).map((number) => {
              const isActive = (number) === currentPage;

              return (
                <Pagination.Item onClick={() => !isActive && paginate(number)} key={number} active={isActive}>
                  {number + 1}
                </Pagination.Item>
              );
            })}

            <Pagination.Item disabled={(limit + offset) >= countProject} onClick={next}>
              Próximo
            </Pagination.Item>

          </Pagination>
        )}
    </>
  );
};

export default PageNavigation;
