/* eslint-disable camelcase */
import settings from '@/api/query/auth/settings';
import CustomIcons from '@/components/CustomIcons';
import Skeleton from '@/components/Skeleton';
import { AuthContext } from '@/context/AuthContext';
import { IconWrapper } from '@partners-core/components';
import dayjs from 'dayjs';
import { useRouter } from 'found';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Tab, Nav, Badge } from 'react-bootstrap';

import Personal from '../../components/Partner/Settings/Personal';
import Security from '../../components/Partner/Settings/Security';
import Users from '../../components/Partner/Settings/Users';

const Settings = ({ match }) => {
  const { user } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [activeKey, setActiveKey] = useState(match.params?.option || 'personal');
  const { router } = useRouter();

  const fetchData = async () => {
    const usr = await settings();
    setProfile(usr?.me);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const days = dayjs(user?.owner?.subscription?.trial_until).diff(new Date(), 'days');

  const handleChangeActiveKey = (e) => {
    setActiveKey(e);
    router.push(`/settings/${e}`);
  };

  return (
    <Container fluid>
      <Row className="justify-content-center pt-5">
        {!profile
          && (

            <Col xs={12}>
              <Row>
                <Col sm={{ span: 2, offset: 1 }}>

                  <Skeleton width="100%" height="55px" radius="6px" />
                </Col>

              </Row>
              <Row className="mt-5">
                <Col sm={{ span: 3, offset: 1 }}>

                  <Skeleton width="100%" height="400px" radius="6px" />
                </Col>
                <Col md="7">

                  <Skeleton height="80px" block radius="6px" />
                  <Skeleton width="30%" className="mt-3" radius="6px" />
                </Col>
              </Row>
            </Col>
          )}

        {profile
          && (
            <Col xs={12} className="c-dark">

              <Row>
                <Col sm={{ span: 8, offset: 1 }}>
                  <h1 className="display-4 font-weight-regular mb-2">
                    Conta
                  </h1>

                  <p className="mb-0">
                    Detalhes de
                    {' '}
                    <b>{profile?.user?.first_name}</b>

                    {user?.owner?.subscription?.status === 'TRIALING'
                      && (
                        <Badge variant="purple" className="ml-3">
                          {days <= 0
                            && (
                              <span>
                                Seu teste gratuito da plataforma acabou
                                {' '}
                                <span role="img" aria-label="">😱</span>
                                . Contrate a plataforma para continuar acessando.
                              </span>
                            )}

                          {days > 0
                            && (
                              <span>

                                Você ainda tem
                                <b>
                                  {` ${days} dias `}
                                </b>
                                de teste gratuito na plataforma.
                              </span>
                            )}
                        </Badge>
                      )}

                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey}
                  activeKey={activeKey} onSelect={handleChangeActiveKey} mountOnEnter
                >
                  <Col sm={{ span: 3, offset: 1 }}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Nav variant="profile" className="flex-column w-100 font-size-md">

                            <Nav.Item>
                              <Nav.Link className="d-flex align-items-center" eventKey="personal">
                                <IconWrapper className="mr-3" width="34px" height="30px">
                                  <CustomIcons.UserCustom color="#B0AAFF" size="18" />
                                </IconWrapper>
                                Informações Pessoais
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link className="d-flex align-items-center" eventKey="security">
                                <IconWrapper className="mr-3" width="34px" height="30px">
                                  <CustomIcons.ShieldCheck color="#B0AAFF" size="18" />
                                </IconWrapper>
                                Login e segurança
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link className="d-flex align-items-center" eventKey="users">
                                <IconWrapper className="mr-3" width="34px" height="30px">
                                  <CustomIcons.UsersCustom color="#B0AAFF" size="18" />
                                </IconWrapper>
                                Usuários
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={7} className="pl-5 pb-5">
                    <Tab.Content>
                      <Tab.Pane eventKey="personal">
                        <Personal profile={profile.user} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="security">
                        <Security profile={profile} setProfile={setProfile} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="users">
                        <Users profile={profile.user} setActiveKey={setActiveKey} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Tab.Container>
              </Row>
            </Col>
          )}
      </Row>
    </Container>
  );
};

export default Settings;
