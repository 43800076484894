import React from 'react';
import {
  BrowserView,
  MobileView,
} from 'react-device-detect';

import Header from './Header/Header';

const LayoutMobile = ({ children }) => (
  <>
    <BrowserView>
      <Header />
      {children}
    </BrowserView>

    <MobileView>
      <Header isMobile />
      {children}
    </MobileView>
  </>
);

export default LayoutMobile;
