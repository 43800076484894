/* eslint-disable no-console */
import { ReactComponent as FixingBugs } from '@/assets/img/icons/fixing_bugs.svg';
import { ReactComponent as IconFaixa } from '@/assets/img/icons/icon-faixa.svg';
import partners from '@partners-core/partners-js';
import { Typography } from '@partners-core/components';
import { QueryRenderer } from '@cubejs-client/react';
import Dinero from 'dinero.js';
import React, { useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { FcCancel } from 'react-icons/fc';
import { FiTrash2, FiMoreVertical } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import { useDeepCompareMemo } from 'use-deep-compare';
import ConfirmationModal from '@/components/Confirmation';

import { TableCustomerHover } from './styles';

let keyTable = 1;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    className="btn btn-link p-0 c-dark"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FiMoreVertical className="dropdown-ellipses" size="18" strokeWidth="1px" />
  </button>
));

const formatTableData = (columns, data) => {
  function flatten() {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => ({ ...memo, [column.dataIndex]: column }), {});

  function formatValue(value, { type } = {}) {
    if (value === undefined) {
      return value;
    }

    if (type === 'boolean') {
      if (typeof value === 'boolean') {
        return value.toString();
      } if (typeof value === 'number') {
        return Boolean(value).toString();
      }

      return value;
    }

    if (type === 'number' && format === 'percent') {
      return [parseFloat(value).toFixed(2), '%'].join('');
    }

    return value?.toString() || '';
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => [dataIndex, formatValue(value, typeByIndex[dataIndex])]),
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, pivotConfig, handleOnClickCustomer, isSupportUser }) => {
  const { addToast } = useToasts();
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [showConfirmInactiveModal, setShowConfirmInactiveModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);

  const handleRemoveCustomer = async (customerId) => {
    try {
      const responseOwnerCustomer = await partners.ownerCustomer.deleteOne({
        where: {
          customer_id: {
            _eq: customerId,
          },
        },
      });

      const responseOwnerProduct = await partners.ownerProduct.deleteOne({
        where: { product: { customer_id: { _eq: customerId } } },
      });

      if (responseOwnerCustomer && responseOwnerProduct) {
        keyTable += 1;
        addToast('Vinculo removido com sucesso!', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInactiveCustomer = async (customerId) => {
    try {
      const responseData = await partners.partner.supportUnbind({
        customer_id: customerId,
      });
      if (responseData) {
        keyTable += 1;
        addToast('Produto inativado com sucesso!', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const typeColors = {
    WHITE_BELT: '#E4E4E4',
    RED_BELT: '#F05959',
    ORANGE_BELT: '#FF9C41',
    GREEN_BELT: '#89E3A8',
    BLACK_BELT: '#000000',
    SENSEI_BELT: '#ffd700',
    RED_BELT_200: '#F05959',
    ORANGE_BELT_400: '#FF9C41',
    GREEN_BELT_800: '#89E3A8',
    GOLDEN_BELT_D90: '#ffd700',
    GOLDEN_BELT_D80: '#ffd700',
    GOLDEN_BELT_D70: '#ffd700',
    GOLDEN_BELT_D60: '#ffd700',
    GOLDEN_BELT_D50: '#ffd700',
    GOLDEN_BELT_D40: '#ffd700',
    GOLDEN_BELT_D30: '#ffd700',
    GOLDEN_BELT_D20: '#ffd700',
    GOLDEN_BELT: '#ffd700',
    BLACK_BELT_D9: '#000000',
    BLACK_BELT_D8: '#000000',
    BLACK_BELT_D7: '#000000',
    BLACK_BELT_D6: '#000000',
    BLACK_BELT_D5: '#000000',
    BLACK_BELT_D4: '#000000',
    BLACK_BELT_D3: '#000000',
    BLACK_BELT_D2: '#000000',

  };

  return (
    <>
      <TableCustomerHover responsive hover className="table-sm" size="sm">
        <thead>
          <tr>
            {isSupportUser && <th><Col className="m-0 p-0">ID</Col></th>}
            <th>CLIENTE</th>
            <th><Col className="m-0 p-0 text-center">FATURAMENTO</Col></th>
            <th><Col className="m-0 p-0 text-center">RECEITA LÍQUIDA</Col></th>
            <th><Col className="m-0 p-0 text-center">RECEITA CO-PRODUTORES</Col></th>
            <th><Col className="m-0 p-0 text-center">PRODUTOS ATIVOS</Col></th>
            <th><Col className="m-0 p-0 text-center">NÍVEL ELITE</Col></th>
            <th><Col className="m-0 p-0 text-center">%REEMBOLSO</Col></th>
            {/* <th><Col className="m-0 p-0 text-center">CHARGEBACK</Col></th> */}
            {isSupportUser && <th />}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item, index) => (
            <tr key={`row-${item['Customer.name']}-${index}`}>
              {isSupportUser
                && (
                  <td>
                    <Typography className="c-dark m-0 pointer font-bold" variant="h5" percent="90"
                      onClick={() => handleOnClickCustomer(item)}
                    >
                      {item['Customer.externalId']}
                    </Typography>
                  </td>
                )}
              <td>
                <Typography className="c-dark m-0 pointer font-bold" variant="h5" percent="90"
                  onClick={() => handleOnClickCustomer(item)}
                  style={{ width: '150px' }}
                >
                  {item['Customer.name']}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center pointer font-bold" variant="h5" percent="90"
                  onClick={() => handleOnClickCustomer(item)}
                >
                  {Dinero({ amount: Math.trunc(item['Transaction.value']) }).toFormat()}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center pointer font-bold" variant="h5" percent="90"
                  onClick={() => handleOnClickCustomer(item)}
                >
                  {Dinero({ amount: Math.trunc(item['Transaction.netIncome']) }).toFormat()}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center pointer font-bold" variant="h5" percent="90"
                  onClick={() => handleOnClickCustomer(item)}
                >
                  {Dinero({ amount: Math.trunc(item['Transaction.coproductionAmount']) }).toFormat()}
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                  <span className={!item['OwnerCustomer.inactivatedAt'] ? 'c-greener mr-2' : 'c-gray mr-2'}>●</span>
                </Typography>
              </td>
              <td>
                <Typography className="c-dark m-0 text-center" variant="h5" percent="90" tooltip={item['CustomerType.label']}>
                  <IconFaixa size={10} color={typeColors[item['CustomerType.name']]} />
                </Typography>
              </td>
              <td>
                <Typography variant="h5" percent="90"
                  className={((item['Transaction.refundedCount'] / item['Transaction.count']) * 100) >= 15
                    ? 'c-danger m-0 text-center font-bold' : 'c-dark m-0 text-center font-bold'}
                  tooltip={((item['Transaction.refundedCount'] / item['Transaction.count']) * 100) >= 15 ? 'Reduza sua média de reembolso' : null}
                >
                  {item['Transaction.value'] > 0 ? ((item['Transaction.refundedCount'] / item['Transaction.count']) * 100).toFixed(0) : 0}
                  %
                </Typography>
              </td>
              {/* <td>
              <Typography className="c-dark m-0 text-center" variant="h5" percent="90">
                {((item['Transaction.chargebackCount'] / item['Transaction.count']) * 100).toFixed(0)}
                %
              </Typography>
            </td> */}
              {isSupportUser
                && (
                  <td>
                    <Dropdown drop="up" className="mr-3">
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                      >
                        <Dropdown.Item>
                          <Row
                            variant="link"
                            className="align-items-center"
                            onClick={() => { setShowConfirmRemoveModal(true); setSelectedCustomer(item['Customer.id']); }}
                          >
                            <Col xs="auto">
                              <FiTrash2 size="14" />
                            </Col>
                            <Col className="font-weight-regular pl-0">Remover vinculo</Col>
                          </Row>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Row
                            variant="link"
                            className="align-items-center"
                            onClick={() => { setShowConfirmInactiveModal(true); setSelectedCustomer(item['Customer.id']); }}
                          >
                            <Col xs="auto">
                              <FcCancel size="15" className="c-dark" />
                            </Col>
                            <Col className="font-weight-regular pl-0">Inativar</Col>
                          </Row>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </TableCustomerHover>
      {showConfirmInactiveModal &&
 <ConfirmationModal
                show={showConfirmRemoveModal}
                setShow={setShowConfirmRemoveModal}
                onSuccess={() => handleRemoveCustomer(selectedCustomer)}
              >
                <Typography variant="h5" fontWeight="300">O vinculo será removido. Deseja continuar?</Typography>
              </ConfirmationModal>
      }
      {showConfirmInactiveModal &&
      <ConfirmationModal
        show={showConfirmInactiveModal}
        setShow={setShowConfirmInactiveModal}
        onSuccess={() => handleInactiveCustomer(selectedCustomer)}
      >
        <Typography variant="h5" fontWeight="300">Novas transações deste cliente não serão contabilizadas. Deseja continuar?</Typography>
      </ConfirmationModal>
      }
    </>
  );
};

const renderChart = ({ resultSet, error, pivotConfig, handleOnClickCustomer, isSupportUser }) => {
  if (error) {
    console.error(error);
    return (
      <Row>
        <Col className="text-center">
          <FixingBugs width={100} height={100} className="mt-4 mb-2" />
          <Row className="justify-content-center">
            <Typography className="c-dark pt-2 font-weight-bold " variant="h4">
              Ops, ocorreu um erro inesperado
            </Typography>
          </Row>
          <Row className="justify-content-center mx-4">
            <Typography className="c-blue pt-2 pb-4" variant="p" percent="80">
              Entre em contato com suporte se esse problema persistir
            </Typography>
          </Row>
        </Col>
      </Row>
    );
  }

  if (!resultSet) {
    return '';
  }

  return (
    <TableRenderer resultSet={resultSet} pivotConfig={pivotConfig}
      handleOnClickCustomer={handleOnClickCustomer} isSupportUser={isSupportUser}
    />
  );
};

export const TableCustomer = ({ filterDateTable, filterTable, cubejsApi, filter, handleOnClickCustomer, isSupportUser }) => (
  <>
    <QueryRenderer key={keyTable}
      query={{
        measures: [
          'Transaction.value',
          'TransactionStatus.count',
          'Product.count',
          'Transaction.chargebackCount',
          'Transaction.refundedCount',
          'Transaction.count',
          'Transaction.netIncome',
          'Transaction.coproductionAmount',
        ],
        timeDimensions: [
          {
            dimension: 'Transaction.paidAt',
            dateRange: filterDateTable,
          },
        ],
        order: {
          'Transaction.value': 'desc',
        },
        dimensions: [
          'Customer.name',
          'Customer.id',
          'CustomerType.label',
          'CustomerType.name',
          'Customer.externalId',
          'OwnerCustomer.inactivatedAt',

        ],
        filters: filterTable,
        limit: filter.limit,
        offset: filter.offset,
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({
        ...props,
        chartType: 'table',
        pivotConfig: {
          x: [
            'Customer.name',
            'Customer.externalId',
            'CustomerType.label',
            'CustomerType.name',
            'OwnerCustomer.inactivatedAt',
          ],
          y: [
            'measures',
          ],
          fillMissingDates: true,
          joinDateRange: false,
          limit: filter.limit,
          offset: filter.offset,
        },
        handleOnClickCustomer,
        isSupportUser,
      })}
    />
  </>
);
