import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import AsyncSelect from '../Select/AsyncSelect';
import { FormStyle } from '../Select/SelectStyles';

const FormSelect = ({ name, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        // console.log(ref);
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map((option) => option.value);
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <AsyncSelect
        ref={selectRef}
        classNamePrefix="react-select"
        placeholder="Selecione..."
        styles={FormStyle}
        {...rest}
      />
      {error && <span className="text-danger font-size-xs ml-2 font-weight-bold">{error}</span>}
    </>
  );
};
export default FormSelect;
