import { Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalLevel = styled(Modal)`
  .modal-content {
    border: none !important;
  }
`;

export const RowGradient = styled(Row)`
  &.row-browser {
            background: linear-gradient(119.35deg, #14163E 30.14%, #000000 72.1%);
            overflow: auto;
            height: 100%;
            width: 100%;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
  }

  &.row-mobile {
            background: linear-gradient(119.35deg, #14163E 30.14%, #000000 72.1%);
            height: 100%;
            width: 100%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
  }
`;
