import { fetchQuery, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

export const query = graphql`
  query settingsQuery {
    me {
      user {
        id
        email
        first_name
        last_name
        facebook_external_id
        google_external_id
        confirm_email_token,
        phone
        whatsapp
        link
        identifier
        meta
        owner {
          id
          name
          document
          information
          users {
            id
            email
            first_name
            last_name
            avatar
            invited
            confirmed_email
            confirm_email_token,
            meta
          }
        }
      }
    }
  }
`;

const settings = () => fetchQuery(environment, query, null, { FetchQueryFetchPolicy: 'network-only' });
export default settings;
