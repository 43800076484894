import { camelCase, omit } from 'lodash';

import authenticate from './authenticate';
import { state } from './config';
import configure from './configure';
import custom from './custom';
import multi from './multi';

const register = (repositories) => {
  const base = Object.fromEntries(
    Object
      .entries(repositories)
      .reduce((result, [key, value]) => ([
        ...result,
        [camelCase(key), omit(value, 'query', 'mutation')],
      ]), []),
  );

  return {
    ...base,
    state,
    multi,
    custom,
    configure,
    authenticate,
  };
};

export default register;
