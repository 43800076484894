/* eslint-disable camelcase */
import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import React, { useRef, useState } from 'react';
import { Row, Col, Alert, Container } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
// import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';

import yup from '../../util/yup';

const schemaValidation = yup.object().shape({
  first_name: yup
    .string()
    .min(2)
    .required(),
  last_name: yup
    .string()
    .min(2)
    .required(),
  phone: yup
    .string()
    .min(10)
    .required(),
  company_profile: yup
    .string()
    .required(),
  password: yup
    .string()
    .min(6)
    .required(),
  password_repeat: yup
    .string()
    .min(6)
    .required()
    .oneOf([yup.ref('password'), null], 'A confirmação de senha não confere'),
});

export const segmentOptions = [
  { value: 'Expert/Produtor', label: 'Expert/Produtor' },
  { value: 'Co-produtor/Agência de lançamento', label: 'Co-produtor/Agência de lançamento' },
  { value: 'Agência de recuperação de vendas e onboarding de clientes', label: 'Agência de recuperação de vendas e onboarding de clientes' },
  { value: 'Agência digital em migração para lançamentos', label: 'Agência digital em migração para lançamentos' },
  { value: 'Afiliado', label: 'Afiliado' },
  { value: 'Ainda não atuo no mercado de lançamentos', label: 'Ainda não atuo no mercado de lançamentos' },
  { value: 'Outros', label: 'Outros' },
];

function CardSignUpStep2({ email, error, loading, setLoading, onBack, onSuccess }) {
  const formRef = useRef(null);
  const recaptchaRef = React.useRef();
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [token, setToken] = useState(null);
  const [phone, setPhone] = useState('');
  const [companyProfile, setCompanyProfile] = useState(null);
  // const [phoneCountryCode, setPhoneCountryCode] = useState('BR');
  const urlPage = window.location.href;

  const handleSubmit = async (data) => {
    setLoading(true);
    let newToken = token;
    let formData = data;

    if (!formData?.company_profile && urlPage.includes('invite')) {
      formData = { ...formData, company_profile: 'Outros' };
    }

    if (!token) {
      newToken = await recaptchaRef.current.executeAsync();
      setToken(newToken);
    }

    if (!newToken) {
      setRecaptchaError(true);
    } else {
      try {
        formRef.current.setErrors({});
        await schemaValidation.validate(formData, {
          abortEarly: false,
        });
        onSuccess({
          ...formData,
          token: newToken,
          email,
          // phone: phone.replace(`+${getCountryCallingCode(phoneCountryCode)}`, ''),
          // ddi: getCountryCallingCode(phoneCountryCode),
        });
      } catch (err) {
        const validationErrors = {};
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            validationErrors[e.path] = e.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  };

  return (
    <Container>
      <h3>
        Criar sua conta no Programa de Parceiros
      </h3>
      <p className="c-dark font-size-md">
        Agora nos informe seu nome e escolha uma senha para a sua conta.
      </p>
      <Unform id="criar-conta" ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Input
                id="first_name"
                name="first_name"
                type="text"
                placeholder="Nome"
                autoComplete="given-name"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Input
                id="last_name"
                name="last_name"
                type="text"
                placeholder="Sobrenome"
                autoComplete="family-name"
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            name="phone"
            mask="(99) 99999-9999"
            placeholder="(48) 99111-2233"
          />
          {/* <PhoneInput
            international
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            defaultCountry="BR"
            name="phone"
            onCountryChange={(v) => setPhoneCountryCode(v)}
            className="form-control c-dark bold"
          /> */}
        </Form.Group>

        {!urlPage.includes('invite') && (
          <Form.Group className="mt-3">
            <Form.Select
              value={companyProfile}
              onChange={setCompanyProfile}
              name="company_profile"
              placeholder="Seu perfil"
              noOptionsMessage={() => 'Nada encontrado...'}
              menuPortalTarget={document.body}
              options={segmentOptions}
            />

          </Form.Group>
        )}

        <Form.Group>
          <Form.Input
            id="password"
            name="password"
            type="password"
            placeholder="Senha"
            autoComplete="new-password"
          />
        </Form.Group>

        <Form.Group>
          <Form.Input
            id="password_repeat"
            name="password_repeat"
            type="password"
            placeholder="Confirmar senha"
            autoComplete="new-password"
          />
        </Form.Group>

        <Form.Group>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={recaptchaRef}
            size="invisible"
            badge="bottomleft"
          />

          {(recaptchaError || error)
            && (
              <Alert variant="danger-outline">
                <b>
                  Você deve verificar o Captcha.
                </b>
                <div>Fazemos isso certificar que você é um humano.</div>
              </Alert>
            )}
        </Form.Group>

        <Button variant="success" type="submit" loading={loading} block>
          Criar minha conta
        </Button>
      </Unform>

      {onBack
        && (
          <p className="font-size-sm mt-4 mb-0 text-center c-dark">
            <Button onClick={onBack} variant="link">
              <strong>Voltar</strong>
            </Button>
          </p>
        )}
    </Container>
  );
}

export default CardSignUpStep2;
