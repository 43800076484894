import ButtonGoogleVinculate from '@/components/Auth/ButtonGoogleVinculate';
import { Button } from '@partners-core/components';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const SocialAccounts = ({ id, toggle, opened }) => (
  <Row className="mt-6 pb-4 border-bottom">
    <Col>
      <h5 className="bold">
        Contas sociais
      </h5>
      {!opened
          && (
            <div className="font-size-md">
              conexão com google
            </div>
          )}
      {opened
          && (
            <div className="font-size-md">
              <p>Clique para conectar ou atualizar sua conexão com Google</p>
              <ButtonGoogleVinculate />
            </div>
          )}
    </Col>
    <Col xs="auto">
      <Button variant="link" onClick={() => toggle(id)}>
        {opened ? 'Cancelar' : 'Editar'}
      </Button>
    </Col>
  </Row>

);

export default SocialAccounts;
