import { Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import React, { useState, useRef, useContext } from 'react';
import { Alert } from 'react-bootstrap';

import sendForgetPassword from '../../api/mutation/auth/send-forget-password';
import { AuthContext } from '../../context/AuthContext';
import yup from '../../util/yup';

const schemaValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

function CardForgetPassword({ onBack }) {
  const formRef = useRef(null);
  const { logout } = useContext(AuthContext);
  const [localLoading, setLocalLoading] = useState(false);
  const [message, setMessage] = useState();

  const handleSubmit = async (payload) => {
    setMessage(null);
    setLocalLoading(true);
    try {
      formRef.current.setErrors({});
      await schemaValidation.validate(payload, {
        abortEarly: false,
      });
      logout();
      await sendForgetPassword(payload);
      setMessage('Email enviado com sucesso!');
    } catch (e) {
      const validationErrors = {};
      if (e instanceof yup.ValidationError) {
        e.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else setMessage(e?.source?.errors[0]?.message);
    }
    setLocalLoading(false);
  };

  return (
    <>
      <h4 className="text-center mb-4 bold c-dark">Recuperar senha</h4>
      <p className="c-dark font-size-sm mb-5 text-center">Um link para redefinição de senha será enviado para seu email.</p>
      <Unform ref={formRef} onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Input name="email" type="email" placeholder="Email" />
        </Form.Group>
        {message
        && (
          <Alert variant="success-outline" onClose={() => setMessage(null)} dismissible>
            <b>
              {message}
              .
            </b>
            <div>Verifique sua caixa de emails e siga os passos para redefinir sua senha.</div>
          </Alert>
        )}
        <Button loading={localLoading} variant="success" type="submit" block>
          Continuar
        </Button>
      </Unform>
      <p className="font-size-sm mt-4 mb-0 text-center c-dark">
        <Button onClick={onBack} variant="link">
          <strong>Voltar</strong>
        </Button>
      </p>
    </>
  );
}

export default CardForgetPassword;
