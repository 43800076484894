import { ReactComponent as WhatsApp } from '@/assets/img/icons/whatsapp.svg';
import Confirmation from '@/components/Confirmation';
import usePartners from '@/modules/partners/store';
import { validatePhone } from '@/util/utils';
import yup from '@/util/yup';
import { Typography, Avatar, Button, Form } from '@partners-core/components';
import { Form as Unform } from '@unform/web';
import { clone } from 'lodash';
import React, { useState, useRef } from 'react';
import { Row, Modal, Col, Card } from 'react-bootstrap';
import { FiMail, FiX, FiMonitor, FiUser, FiPlusCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

const schemaValidation = yup.object().shape({
  newName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required('O telefone é obrigatório').test('validatePhone', 'Telefone precisa ser um número válido', validatePhone),
});

const SupportModal = ({ showModal, setShowModal, support, setSupport, ownerId }) => {
  const { addToast } = useToasts();
  const { fetchSupportsData, deleteOwnerSupport, updateOwnerSupport, insertOwnerSupport, insertSupport } = usePartners();
  const [currentType, setCurrentType] = useState('CS');
  const [editing, setEditing] = useState(false);
  const [supportOptions, setSupportOptions] = useState();
  const [addSupport, setAddSupport] = useState(false);
  const formRef = useRef(null);

  const handleEditing = async () => {
    try {
      const supportData = await fetchSupportsData(currentType);
      const supportsMap = supportData?.map((s) => ({ value: s.id, label: s.name, phone: s.phone, email: s.email }));
      setSupportOptions(supportsMap);
      setEditing(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleRemoveSupport = async () => {
    try {
      const supportData = await deleteOwnerSupport(
        {
          where: {
            support_id: { _eq: support?.[currentType]?.id },
            owner_id: { _eq: ownerId },
          },
        },
      );
      const newSupport = clone(support);
      delete newSupport[currentType];
      setSupport(newSupport);
      if (supportData) {
        addToast('Suporte removido com sucesso!', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
        setShowModal(false);
      } else {
        addToast('Falha na exclusão do suporte!', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (data) => {
    if (data.name) {
      let supportData;
      if (support?.[currentType]) {
        supportData = await updateOwnerSupport({
          where: {
            support: {
              type_enum: { _eq: currentType },
            },
            owner_id: {
              _eq: ownerId,
            },
          },
          _set: { support_id: data.name },
          select: {
            support: {
              id: true,
              avatar: true,
              email: true,
              phone: true,
              name: true,
            },
          },
        });
      } else {
        supportData = await insertOwnerSupport({
          object: {
            support_id: data.name,
            owner_id: ownerId,
          },
          select: {
            support: {
              id: true,
              avatar: true,
              email: true,
              phone: true,
              name: true,
            },
          },
        });
      }

      setSupport({ ...support, [currentType]: supportData.support });
      if (supportData) {
        addToast('Suporte alterado com sucesso!', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
        setShowModal(false);
      } else {
        addToast('Falha na alteração do suporte!', {
          appearance: 'snack',
          autoDismiss: true,
          autoDismissTimeout: 6500,
        });
      }
    }

    if (data.newName) {
      try {
        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const supportData = await insertSupport({
          object: {
            name: data.newName,
            phone: data.phone,
            email: data.email,
            type_enum: currentType,
          },
        });

        let supportOwnerData;
        if (support?.[currentType]) {
          supportOwnerData = await updateOwnerSupport({
            where: { support: { type_enum: { _eq: currentType } } },
            _set: { support_id: supportData.id },
          });
        } else {
          supportOwnerData = await insertOwnerSupport({
            object: {
              support_id: supportData.id,
              owner_id: ownerId,
            },
          });
        }

        if (supportData && supportOwnerData) {
          addToast('Suporte adicionado com sucesso!', {
            appearance: 'snack',
            autoDismiss: true,
            autoDismissTimeout: 6500,
          });
          setShowModal(false);
        } else {
          addToast('Falha na adição do suporte!', {
            appearance: 'snack',
            autoDismiss: true,
            autoDismissTimeout: 6500,
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  return (
    <Modal show={showModal} onHide={setShowModal} centered size="xs">
      <Card className="modal-card">
        <Card.Header>
          <Typography className="card-header-title c-dark font-weight-bold">
            Edição de Suporte
          </Typography>
          <FiX onClick={() => {
            setShowModal(false);
          }} className="c-dark pointer" size="18"
          />
        </Card.Header>
        <Card.Body>
          <Col>
            <Unform ref={formRef} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="font-size-md bold c-dark">Tipo:</Form.Label>
                <Row>
                  <Col>
                    <Button
                      variant={currentType === 'CS' ? 'secondary' : 'outline-secondary'}
                      block
                      onClick={() => { setCurrentType('CS'); setEditing(false); setAddSupport(false); }}
                    >
                      <FiUser />
                      <div className="mt-2">Customer Success Manager</div>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant={currentType === 'CT' ? 'secondary' : 'outline-secondary'}
                      block
                      onClick={() => { setCurrentType('CT'); setEditing(false); setAddSupport(false); }}
                    >
                      <FiMonitor />
                      <div className="mt-2">Customer Technical Support</div>
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
              {!editing && !addSupport
                && (
                  <>
                    <Row>
                      <Typography className="c-dark font-weight-bold pb-2" variant="h5" percent="90">
                        Dados:
                      </Typography>
                    </Row>
                    <Row>
                      <Col xs="auto">
                        <Avatar title={support?.[currentType]?.name} size="xs" roundedCircle />
                      </Col>
                      <Col>
                        <Typography className="c-dark font-weight-bold " variant="h5" percent="90">
                          {support?.[currentType]?.name}
                        </Typography>
                        <Row className="py-2">
                          <FiMail size="14" className="c-dark mr-2 ml-3" />
                          <Typography className="c-dark " variant="h6" percent="100">
                            {support?.[currentType]?.email}
                          </Typography>
                        </Row>
                        <Row>
                          <WhatsApp className="c-dark mr-2 ml-3" width="13" height="13" stroke="#3D3A6C" strokeWidth="0px" />
                          <Typography className="c-dark" variant="h6" percent="100">
                            {support?.[currentType]?.phone}
                          </Typography>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}

              {editing
                && (
                  <>
                    <Form.Group>
                      <Form.Label className="font-size-md bold c-dark">Nome</Form.Label>
                      <Row className="align-items-center">
                        <Col>
                          <Form.Select
                            id="name"
                            name="name"
                            placeholder={support?.CS?.name}
                            options={supportOptions}
                            menuPortalTarget={document.body}
                          />
                        </Col>
                        <Col xs="auto">
                          <Button variant="link" onClick={() => { setAddSupport(true); setEditing(false); }}>
                            <FiPlusCircle />
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row className="justify-content-end">
                      <Button
                        variant="secondary"
                        size="md"
                        onClick={() => setEditing(false)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="success"
                        size="md"
                        className="ml-2"
                        type="submit"
                      >
                        Salvar
                      </Button>
                    </Row>
                  </>
                )}
              {addSupport
                && (
                  <>
                    <Form.Group>
                      <Form.Label className="font-size-md bold c-dark">Nome</Form.Label>
                      <Form.Input
                        id="newName"
                        name="newName"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md bold c-dark">E-mail</Form.Label>
                      <Form.Input
                        id="email"
                        name="email"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="font-size-md bold c-dark">Telefone</Form.Label>
                      <Form.Input
                        id="phone"
                        name="phone"
                        mask="(99) 99999-9999"
                      />
                    </Form.Group>
                    <Row className="justify-content-end">
                      <Button
                        variant="secondary"
                        size="md"
                        onClick={() => setAddSupport(false)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="success"
                        size="md"
                        className="ml-2"
                        type="submit"
                      >
                        Salvar
                      </Button>
                    </Row>
                  </>
                )}
              {!editing && !addSupport
                && (
                  <Row className="justify-content-end">
                    <Confirmation
                      message="Tem certeza que deseja remover o suporte deste partner?"
                      placement="top"
                      onSuccess={handleRemoveSupport}
                    >
                      <Button
                        variant="danger"
                        size="md"
                      >
                        Remover
                      </Button>
                    </Confirmation>
                    <Button className="ml-2"
                      variant="purple"
                      size="md"
                      onClick={() => handleEditing()}
                    >
                      Editar
                    </Button>
                  </Row>
                )}
            </Unform>
          </Col>
        </Card.Body>
      </Card>
    </Modal>
  );
};
export default SupportModal;
