import { commitMutation, graphql } from 'relay-runtime';

import environment from '../../../config/environment';

const mutation = graphql`
    mutation updateUserMutation($id: uuid!, $data: user_set_input) {
      update_user_by_pk(pk_columns: {id: $id}, _set: $data) {
        id
      }
    }
`;

export default function updateUser(variables) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        updater: (store) => {
          const user = store.get(variables.id);
          Object.entries(variables.data).forEach(([key, value]) => {
            user.setValue(value, key);
          });
        },
        onCompleted: (resp, error) => {
          if (!error) {
            resolve(resp);
          } else {
            reject(error);
          }
        },
        onError: (error) => {
          reject(error);
        },
      },
    );
  });
}
