/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type changePasswordMutationVariables = {|
  email: string,
  token: string,
  password: string,
|};
export type changePasswordMutationResponse = {|
  +change_password: ?{|
    +isChanged: boolean
  |}
|};
export type changePasswordMutation = {|
  variables: changePasswordMutationVariables,
  response: changePasswordMutationResponse,
|};
*/


/*
mutation changePasswordMutation(
  $email: String!
  $token: String!
  $password: String!
) {
  change_password(email: $email, token: $token, password: $password) {
    isChanged
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "ChangePasswordOutput",
    "kind": "LinkedField",
    "name": "change_password",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isChanged",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "changePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "changePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "changePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation changePasswordMutation(\n  $email: String!\n  $token: String!\n  $password: String!\n) {\n  change_password(email: $email, token: $token, password: $password) {\n    isChanged\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83fed3ed28a851b6d5d97db1185352dc';

module.exports = node;
