/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const Roshi = forwardRef(({ gradient = false, color = 'none', size = 75, fill = 'url(#paint0_linear_1561_700)', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75 75"
    fill="none"
    stroke={color}
    x="0px"
    y="0px"
    {...rest}
  >
    <path d="M74.2,16.8c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1l-4.3,2.8
      c-0.3-5.4-1.2-9-1.2-9c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1l-9.6,8.2
      c-4.4-3.8-9.9-6.4-16-7.3L42,6.7c0-0.2,0-0.5,0-0.8c-0.1-0.3-0.3-0.5-0.4-0.6c-0.1-0.1-3.6-2.6-3.7-2.7c-0.1-0.1-0.3-0.2-0.5-0.2
      c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-3.5,2.6-3.7,2.7c-0.1,0.1-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.6,0,0.8l0.2,4.5
      c-6.1,0.9-11.6,3.5-16,7.3l-9.6-8.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
      c0,0-0.1,0.1-0.1,0.2c0,0-0.9,3.6-1.2,9l-4.3-2.8c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
      c0,0,0,0.1,0,0.1c0,0,0.2,10.4,5.9,21.8c-0.1,1-0.2,2.1-0.2,3.2c0,17,13.9,30.9,31,30.9c17.1,0,31-13.8,31-30.9
      c0-1.1-0.1-2.1-0.2-3.2C74,27.2,74.2,16.8,74.2,16.8z M57.1,22.7c0.2,3.3,0.3,8.9-1.1,14.7c-1.7,7.1-5.9,11.6-9.9,14.4
      c7.8-9.6,7.8-16.1,7.8-16.3c0-0.1,0-0.2-0.1-0.3c0-0.1-2.9-4.2-7.3-5.9c-0.1,0-0.4-0.1-0.6-0.1c-0.3,0.1-0.5,0.5-0.5,0.5
      c-1.3,11.2-5.5,17.9-5.5,17.9l1.7-33C47.7,15.7,53,18.6,57.1,22.7z M34.9,47.7c0,0-4.2-6.8-5.5-17.9c0-0.1-0.1-0.5-0.5-0.5
      c-0.2,0-0.5,0-0.6,0.1c-4.5,1.7-7.3,5.8-7.3,5.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,6.7,7.8,16.3c-4-2.8-8.1-7.3-9.9-14.4
      c-1.4-5.8-1.3-11.4-1.1-14.7c4.1-4.2,9.5-7.1,15.5-8L34.9,47.7z M10.1,44.6c1.3,1.8,2.7,3.7,4.3,5.4c0.7,0.8,1.5,1.6,2.3,2.4
      c1.7,1.6,3.5,3,5.2,4.3c0,0,0,0,0,0c1.8,1.3,3.5,2.3,5.1,3.2h0c0,0,8.2,4.2,10,9.1C23.1,68.9,11.5,58.2,10.1,44.6z M37.8,69.1
      c1.8-4.9,10-9.1,10-9.1h0c1.6-0.9,3.4-2,5.1-3.2c0,0,0,0,0,0c1.7-1.2,3.5-2.7,5.2-4.3c0.8-0.8,1.6-1.6,2.3-2.4
      c1.6-1.8,3-3.6,4.3-5.4C63.3,58.2,51.8,68.9,37.8,69.1z" fill={fill}
    />
    { gradient
    && (
      <linearGradient id="paint0_linear_1561_700" x1="31.2004" y1="59.8674" x2="31.2004" y2="-0.00492119" gradientUnits="userSpaceOnUse">
        <stop stopColor="#853CF7" />
        <stop offset="1" stopColor="#5F2DED" />
      </linearGradient>
    )}
  </svg>
));

export default Roshi;
