import { Form as FormRB } from 'react-bootstrap';

import FormAsyncSelect from './FormAsyncSelect';
import FormCheckbox from './FormCheckbox';
import FormCurrency from './FormCurrency';
import FormDatePicker from './FormDatePicker';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextarea from './FormTextarea';

const Form = {};
Form.AsyncSelect = FormAsyncSelect;
Form.Check = FormRB.Check;
Form.Check.Input = FormRB.Check.Input;
Form.Check.Label = FormRB.Check.Label;
Form.Checkbox = FormCheckbox;
Form.DatePicker = FormDatePicker;
Form.Group = FormRB.Group;
Form.Input = FormInput;
Form.Label = FormRB.Label;
Form.Row = FormRB.Row;
Form.Select = FormSelect;
Form.Textarea = FormTextarea;
Form.Currency = FormCurrency;

export default Form;
