import ShieldCheck from './shield-check.jsx';
import UserCustom from './user-custom.jsx';
import UsersCustom from './users-custom.jsx';

export default {
  ShieldCheck,
  UserCustom,
  UsersCustom,
};

// export { default as CheckCircleCustom } from './check-circle-custom';
// export { default as Company } from './company';
// export { default as PaymentCheck } from './payment-check';
// export { default as ShieldCheck } from './shield-check';
// export { default as UserCustom } from './user-custom';
// export { default as UsersCustom } from './users-custom';
