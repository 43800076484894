import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`  
  mutation sendConfirmEmailMutation {
    send_confirm_email {
      isSend
    }
  }
`;

const sendConfirmEmail = () => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default sendConfirmEmail;
