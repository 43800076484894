/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const Ronin = forwardRef(({ gradient = false, color = 'none', size = 75, fill = 'url(#paint0_linear_1300_434)', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75 75"
    fill="none"
    stroke={color}
    x="0px"
    y="0px"
    {...rest}
  >
    <path d="M71.6,3.4c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1
      l-10.8,9.2C54,7.2,46,4,37.3,4c-8.7,0-16.6,3.2-22.7,8.5L3.8,3.3c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3,0c0,0,0,0-0.1,0
      c0,0-0.1,0.1-0.1,0.1C3.1,3.3,3,3.4,3,3.4c0,0-3.5,13.9,0.3,28.3c-0.5,2.3-0.7,4.6-0.7,7c0,19.2,15.6,34.7,34.7,34.7
      C56.5,73.4,72,57.9,72,38.7c0-2.4-0.2-4.7-0.7-7C75.1,17.3,71.6,3.4,71.6,3.4z M59.4,17.3c0.3,3.7,0.3,10-1.3,16.4
      C54,50.7,37.3,54.7,37.3,54.7s-16.6-4-20.8-20.9c-1.6-6.5-1.5-12.7-1.3-16.4C20.9,11.6,28.7,8,37.3,8C46,8,53.8,11.6,59.4,17.3z
      M37.3,69.5C21,69.5,7.6,56.7,6.6,40.6c1.8,3.6,4.3,7,7.5,10.1C25,60.9,37.3,64,37.3,64s12.3-3.1,23.2-13.3
      c3.2-3.1,5.7-6.5,7.5-10.1C67,56.7,53.6,69.5,37.3,69.5z" fill={fill}
    />
    { gradient
    && (
      <linearGradient id="paint0_linear_1300_434" x1="25.0003" y1="0" x2="25.0003" y2="50" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF0000" />
        <stop offset="1" stopColor="#FFA8A8" />
      </linearGradient>
    )}
  </svg>
));

export default Ronin;
