import dayjs from 'dayjs';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CalendarContainer } from 'react-datepicker';
import styled from 'styled-components';

const ButtonBordered = styled(Button)`
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 8px !important;
`;

const CalendarContainerCustom = ({ className, children, onChangeFilterDate }) => (
  <CalendarContainer className={className}>
    <Row>
      <Col xs="12" sm="3">
        <div className="py-3">
          <ButtonBordered
            variant="link"
            size="sm"
            onClick={() => onChangeFilterDate([new Date(), new Date()])}
            block
            className="text-left hover-blue"
          >
            Hoje
          </ButtonBordered>
          <ButtonBordered
            variant="link"
            block
            size="sm"
            className="text-left hover-blue"
            onClick={() => {
              const today = new Date();
              const yesterday = new Date(today.setDate(today.getDate() - 1));
              onChangeFilterDate([new Date(yesterday), new Date(yesterday)]);
            }}
          >
            Ontem
          </ButtonBordered>
          <ButtonBordered
            variant="link"
            block
            size="sm"
            className="text-left hover-blue"
            onClick={() => {
              const startDate = dayjs().startOf('week').toDate();
              const endDate = dayjs().endOf('week').toDate();
              onChangeFilterDate([startDate, endDate]);
            }}
          >
            Essa semana
          </ButtonBordered>
          <ButtonBordered
            variant="link"
            block
            size="sm"
            className="text-left hover-blue"
            onClick={() => {
              const startDate = dayjs().add(-1, 'week').startOf('week').toDate();
              const endDate = dayjs().add(-1, 'week').endOf('week').toDate();
              onChangeFilterDate([startDate, endDate]);
            }}
          >
            Semana passada
          </ButtonBordered>
          <ButtonBordered
            variant="link"
            block
            size="sm"
            className="text-left hover-blue"
            onClick={() => {
              const startDate = dayjs().startOf('month').toDate();
              const endDate = dayjs().endOf('month').toDate();
              onChangeFilterDate([startDate, endDate]);
            }}
          >
            Esse mês
          </ButtonBordered>
          <ButtonBordered
            variant="link"
            block
            size="sm"
            className="text-left hover-blue"
            onClick={() => {
              const startDate = dayjs().add(-1, 'month').startOf('month').toDate();
              const endDate = dayjs().add(-1, 'month').endOf('month').toDate();
              onChangeFilterDate([startDate, endDate]);
            }}
          >
            Mês passado
          </ButtonBordered>
          <Button
            variant="link"
            block
            size="sm"
            className="text-left hover-blue"
            onClick={() => {
              const startDate = dayjs().startOf('year').toDate();
              const endDate = dayjs().endOf('year').toDate();
              onChangeFilterDate([startDate, endDate]);
            }}
          >
            Esse ano
          </Button>
        </div>
      </Col>
      <Col xs="12" sm="9">
        <div style={{ position: 'relative' }}>{children}</div>
      </Col>

    </Row>

  </CalendarContainer>
);

export default CalendarContainerCustom;
