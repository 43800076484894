import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`
  mutation inviteUserMutation (
    $email: String!,
    $owner_id: String!,
    $resend: Boolean,
  ) {
    invite_user(email: $email, owner_id: $owner_id, resend: $resend) {
      success
      code
      message
      user_id
    }
  }
  
`;

const inviteUser = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store) => {
        try {
          const payload = store.getRootField('invite_user');
          const owner = store.get(variables.owner_id);
          const user_id = payload.getValue('user_id');
          const user = store.create(user_id, 'user');
          user.setValue(variables.email, 'email');
          user.setValue(false, 'confirmed_email');
          user.setValue(true, 'invited');
          user.setValue(user_id, 'id');
          const users = owner.getLinkedRecords('users') || [];
          const newUsers = [...users, user];
          owner.setLinkedRecords(newUsers, 'users');
        } catch (err) {
          console.log(err);
        }
      },
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default inviteUser;
