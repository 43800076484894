/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type outboxSubscriptionVariables = {||};
export type outboxSubscriptionResponse = {|
  +outbox: $ReadOnlyArray<{|
    +id: any,
    +progress: any,
    +meta: ?any,
    +created_at: any,
    +status: {|
      +label: string,
      +value: string,
    |},
  |}>
|};
export type outboxSubscription = {|
  variables: outboxSubscriptionVariables,
  response: outboxSubscriptionResponse,
|};
*/


/*
subscription outboxSubscription {
  outbox(where: {name: {_eq: "customer-bind"}, status_enum: {_in: [SENT, RUNNING, ERROR]}}) {
    id
    progress
    meta
    created_at
    status {
      label
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "name": {
            "_eq": "customer-bind"
          },
          "status_enum": {
            "_in": [
              "SENT",
              "RUNNING",
              "ERROR"
            ]
          }
        }
      }
    ],
    "concreteType": "outbox",
    "kind": "LinkedField",
    "name": "outbox",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "progress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "outbox_status",
        "kind": "LinkedField",
        "name": "status",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "outbox(where:{\"name\":{\"_eq\":\"customer-bind\"},\"status_enum\":{\"_in\":[\"SENT\",\"RUNNING\",\"ERROR\"]}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "outboxSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "outboxSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "outboxSubscription",
    "operationKind": "subscription",
    "text": "subscription outboxSubscription {\n  outbox(where: {name: {_eq: \"customer-bind\"}, status_enum: {_in: [SENT, RUNNING, ERROR]}}) {\n    id\n    progress\n    meta\n    created_at\n    status {\n      label\n      value\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e2d8dbd3c91ca83bc6763c5e1f757734';

module.exports = node;
