import { ReactComponent as FixingBugs } from '@/assets/img/icons/fixing_bugs.svg';
import { QueryRenderer } from '@cubejs-client/react';
import { Typography } from '@partners-core/components';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { isMobile } from 'react-device-detect';
import { useDeepCompareMemo } from 'use-deep-compare';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

let listCustomers;

const COLORS_SERIES = [
  '#7C00FF',
];

const formatValue = (value) => {
  let decimal;

  if (value > 999 && value < 999000) {
    decimal = `${value / 1000}`.indexOf('.');
    if (decimal !== -1) {
      return `${((value / 1000)).toFixed(1)}k`;
    }
    return `${(value / 1000)}k`;
  }
  if (value > 999000) {
    decimal = `${value / 1000000}`.indexOf('.');
    if (decimal !== -1) {
      return `${((value / 1000000)).toFixed(1)}M`;
    }
    return `${value / 1000000}M`;
  }
  return value;
};

const commonOptions = {
  responsive: true,
  backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F4F6FA 100%)',
  maintainAspectRatio: false,
  borderRadius: '10',
  categoryPercentage: '0.15',
  interaction: {
    intersect: false,
  },
  layout: {
    padding: 20,
  },

  plugins: {
    tooltip: {
      callbacks: {
        title: (tooltipItem) => (tooltipItem[0].label.length > 20 ? dayjs(tooltipItem[0].label).format('MMMM YYYY') : tooltipItem[0].label),
        label: (tooltipItem) => Dinero({ amount: Math.trunc(tooltipItem.raw * 100) || 0 }).toFormat(),
      },
    },
    datalabels: {
      display: 'auto',
      color: '#B5B5B5',
      font: {
        size: 11,
        family: 'sans-serif',
      },
      align: 'top',
      anchor: 'end',
      formatter(value, context) {
        const today = new Date();
        const newToday = dayjs(today).format('YY-MM-DD');
        const newValue = formatValue(value);
        const labelDate = dayjs(context.chart.data.labels[context.dataIndex]).format('YY-MM-DD');
        if (value === 0 && labelDate > newToday) {
          return '';
        } return `R$ ${newValue}`;
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      grid: {
        borderDash: [4, 16],
        color: '#C4C2FF',
        circular: true,
        borderWidth: 0,
      },
      ticks: {
        padding: 6,
        callback(value) {
          return formatValue(value);
        },
        color: '#686D86',
        font: {
          size: isMobile ? 9 : 11,
        },
      },
    },
    x: {
      grid: {
        display: false,
        borderWidth: 0,
      },
      ticks: {
        callback(value) {
          let date = this.getLabelForValue(value);
          if (date.length > 20) {
            date = dayjs(date).format('MMM YY');
          }
          return date;
        },
        padding: 12,
        color: '#7471AE',
        font: {
          size: isMobile ? 8 : 11,
          weight: 'bold',
          family: 'sans-serif',
        },
      },
    },
  },
};

const BarChartRenderer = ({ resultSet, pivotConfig, chartBy }) => {
  const datasets = useDeepCompareMemo(
    () => resultSet.series().map((s, index) => ({
      label: s.title,
      data: s.series.map((r) => r.value / 100),
      yValues: [s.key],
      backgroundColor: COLORS_SERIES[index],
      fill: false,
    })),
    [resultSet],
  );
  const customerFilter = resultSet?.loadResponse?.pivotQuery.filters.filter((f) => f.member === 'Customer.id').pop();
  const data = {
    labels: resultSet.categories().map((c) => (chartBy === 'day' ? dayjs(c.x).format('DD/MM/YY') : c.x)),
    datasets,
  };
  if (data.datasets.length > 0 && customerFilter) {
    const customersLabel = customerFilter.values.map((c) => listCustomers.filter((a) => a.value === c).pop().label);

    data.datasets[0].label = customersLabel;
  } else
  if (data.datasets.length > 0) {
    data.datasets[0].label = 'Todos clientes';
  }
  const stacked = !(pivotConfig.x || []).includes('measures');
  const options = {
    ...commonOptions,
    scales: {
      x: { ...commonOptions.scales.x, stacked },
      y: { ...commonOptions.scales.y, stacked },
    },
  };
  return (
    <Bar
      type="bar"
      data={data}
      options={options}
      height={300}
    />
  );
};

const renderChart = ({ resultSet, error, pivotConfig, chartBy }) => {
  if (error) {
    console.error(error.toString());
    return (
      <Row>
        <Col className="text-center">
          <FixingBugs width={100} height={100} className="mt-4 mb-2" />
          <Row className="justify-content-center">
            <Typography className="c-dark pt-2 font-weight-bold" variant="h4">
              Ops, ocorreu um erro inesperado
            </Typography>
          </Row>
          <Row className="justify-content-center mx-4">
            <Typography className="c-blue pt-2 pb-4" variant="p" percent="80">
              Entre em contato com suporte se esse problema persistir
            </Typography>
          </Row>
        </Col>
      </Row>
    );
  }

  if (!resultSet) {
    return '';
  }

  return (
    <BarChartRenderer
      resultSet={resultSet}
      pivotConfig={pivotConfig}
      responsive
      chartBy={chartBy}
    />
  );
};

export const ChartGmv = ({ filterDateChart, filterChart, cubejsApi, customers, chartBy }) => {
  listCustomers = customers;
  return (
    <QueryRenderer
      query={{
        measures: [
          'Transaction.value',
        ],
        timeDimensions: [
          {
            dimension: 'Transaction.paidAt',
            granularity: chartBy,
            dateRange: filterDateChart,
          },
        ],
        order: {
          'Transaction.createdAt': 'asc',
        },
        filters: filterChart,
      }}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({
        ...props,
        chartType: 'bar',
        pivotConfig: {
          x: [
            'Transaction.paidAt.month',
          ],
          y: [
            'measures',
          ],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartBy,
      })}
    />
  );
};
