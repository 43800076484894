import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const Dashed = styled(Button)`
    border: none;
    background: white;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect 
      width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23CAA3FFFF' stroke-width='3' 
      stroke-dasharray='6%2c 8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 4px;
    font-weight: 600;
    height: 42px;
    padding-top: 9px;
    color: black;
    button{
      background: #ECD9FF;
      border-color: #ECD9FF;
    }
  `;
const ButtonDashed = (props) => {
  const { children } = props;
  return (
    <Dashed {...props}>
      {children}
    </Dashed>
  );
};

export default ButtonDashed;
