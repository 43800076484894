import React from 'react';
import ReactDOM from 'react-dom';

import './util/jquery-loader';
import App from './App';

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethod(<App />, document.getElementById('root'));

// ReactDOM.hydrate(
//   <App />,
//   document.getElementById('root'),
// );
