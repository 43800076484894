import React, { forwardRef } from 'react';
import AsyncSelect from 'react-select/async';

import { DefaultStyle } from './SelectStyles';

const Select = forwardRef(({ name, styles, ...rest }, ref) => (
  <AsyncSelect
    classNamePrefix="react-select"
    placeholder="Selecione..."
    styles={styles || DefaultStyle}
    ref={ref}
    loadingMessage={() => 'Carregando...'}
    {...rest}
  />
));

export default Select;
