/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';

const Dragon = forwardRef(({ gradient = false, color = 'none', size = 75, fill = 'url(#paint6_linear_2172_825)', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75 75"
    fill="none"
    x="0px"
    y="0px"
    stroke={color}
    {...rest}
  >
    <path d="M73.8,22.3c-0.6,2.5-1.9,4.8-3.8,6.7
c-1.5-4.9-4.1-9.4-7.6-13.2c0,0-8.9-10.6-24.9-10.6c-15.6,0-24.5,10.3-24.5,10.3C9.2,19.3,6.5,23.9,5,29c-1.8-1.8-3.1-4.1-3.8-6.7
c0,3.1,1.4,5.8,3.4,7.8c-1.3,4.9-1.4,10.1-0.4,15c1,5,3.2,9.7,6.4,13.7c3.2,4,7.2,7.3,11.9,9.5c4.6,2.2,9.8,3.4,14.9,3.4
c5.2,0,10.3-1.2,14.9-3.4c4.7-2.2,8.7-5.5,11.9-9.5c3.2-4,5.4-8.7,6.4-13.7c1.1-5,0.9-10.1-0.4-15C72.4,28.1,73.8,25.4,73.8,22.3z
M65.8,28.8c0,0-0.1,0.1-0.1,0.1c-1,1-1.9,2-2.6,3.2c-0.1,0.2-0.3,0.5-0.4,0.7l0.2-0.1c1.1-0.7,2.2-1.4,3.4-2.2
c0.1,0.4,0.2,0.7,0.3,1.1c-9,5.4-14.7,12.7-18.3,14.5c0.6-3.7,3.3-5.6,7.9-11.4c4.8-6,4.4-12.9,4.2-15.1
C62.8,22.3,64.6,25.4,65.8,28.8z M37.5,9c4.4,0,8.7,0.9,12.6,2.7c4,1.8,7.5,4.4,10.3,7.7c-0.4,8-8.3,13.9-12,19
c-3.9,5.4-3.3,9.4-10.9,10.4c-7.6-1-7-5-10.9-10.4c-3.6-5.1-11.6-11.1-12-19c2.8-3.2,6.3-5.9,10.3-7.6C28.8,10,33.1,9,37.5,9z
 M8.6,30.4c1.2,0.8,2.3,1.5,3.4,2.2l0.3,0.2c-0.1-0.3-0.2-0.5-0.4-0.7c-0.7-1.2-1.7-2.2-2.6-3.2c0-0.1-0.1-0.1-0.1-0.2
c1.2-3.4,3-6.5,5.4-9.2c-0.3,2.1-0.6,9.1,4.2,15.1c4.7,5.8,7.3,7.7,7.9,11.4c-3.6-1.8-9.3-9.1-18.3-14.5
C8.4,31.2,8.5,30.8,8.6,30.4z M67.1,34.1c-1.8,0.9-8.4,8.6-8.7,8.9l1.3,0c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
c0,0.8-0.4,1.7-1,2.3c-0.5,0.5-1.1,0.8-1.7,1c-0.7,0.2-1.3,0.1-2-0.1c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.3-0.2,0.6-0.4,0.8
c-0.3,0.3-0.6,0.5-1,0.6c0.4,0.5-2,4.1-2.2,5.8c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
c-0.3-0.1-0.5-0.3-0.7-0.5c-0.3,1.2-2.1,9.6-2.4,10.4c-0.3,0.7-0.7,1.4-1.3,1.9c0,0-3.2,1.5-9.4,1.5c-6.3,0-9.4-1.5-9.4-1.5
c-0.6-0.5-1.1-1.2-1.3-1.9c-0.3-0.8-2.2-9.2-2.4-10.4c-0.2,0.2-0.4,0.4-0.7,0.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c-0.2-1.6-2.6-5.2-2.2-5.8c-0.4-0.1-0.8-0.3-1-0.6c-0.2-0.2-0.3-0.5-0.4-0.8
c-0.1-0.3,0-0.6,0-0.9c-0.6,0.2-1.3,0.2-2,0.1c-0.7-0.2-1.3-0.5-1.7-1c-0.6-0.6-0.9-1.4-1-2.3c0-0.1,0-0.1,0.1-0.2
c0.1-0.1,0.1-0.1,0.2-0.1l1.3,0c-0.3-0.3-6.9-7.9-8.7-8.9L7.8,34c0.1-0.4,0.1-0.8,0.2-1.1c0.1,0.1,0.3,0.2,0.4,0.2
c8.1,4.8,16.5,15.5,16.9,15.9c1.3,1.3,2.1,1.8,3.7,2.4c0,1.1,0.6,3.9,2.7,5.2c0,0-0.5-2.5,0.5-4.3c1.8,0.4,3.6,0.5,5.4,0.4
c1.8,0.1,3.6,0,5.3-0.4c1,1.8,0.5,4.3,0.5,4.3c2.1-1.3,2.7-4.1,2.7-5.2c1.6-0.6,2.4-1.1,3.7-2.4c0.4-0.4,8.8-11.1,17-15.9l0.4-0.2
C67.1,33.3,67.1,33.7,67.1,34.1C67.2,34.1,67.1,34.1,67.1,34.1z" fill={fill}
    />
    <path fill={fill} d="M41.9,35.6c0,0,2.8,2.5,5.3,0.1c0.7-0.7,1.3-1.5,1.6-2.5c0.3-0.9,0.4-1.9,0.3-2.9
C44.6,30.7,41.9,35.6,41.9,35.6z"
    />
    <path fill={fill} d="M33.1,35.6c0,0-2.8-4.9-7.2-5.3
c-0.1,1,0,2,0.3,2.9c0.3,0.9,0.9,1.8,1.6,2.5C30.2,38.1,33.1,35.6,33.1,35.6z"
    />
    <path fill={fill} d="M32.3,43.6c0,0,0.9-1,2-0.3
      c1.1,0.7,1.2,2.3,3.2,2.3c2,0,2.1-1.5,3.2-2.3c1.1-0.7,2,0.3,2,0.3c-0.8-4.8-5.2-4-5.2-4C37.2,39.6,33.1,38.9,32.3,43.6z"
    />
    <path fill={fill} d="M40.5,34.7c1.2-3.1,3.8-4.8,6.9-5.8
      c3-1,4-4.7,3.8-7.6c-0.2,1.1-0.6,2.1-1.3,3c-0.7,0.9-1.6,1.5-2.7,1.9c-2.8,1.1-5.8,2.5-7.6,5.1C38.7,32.6,39.1,34.1,40.5,34.7z"
    />
    <path fill={fill} d="M47.2,56c-0.7,0.8-1.1,1.7-1.3,2.8
c-0.1,0.3-0.2,0.7-0.2,1v0c-0.3,1.1-1,2-1.9,2.7c0.5-1.2,0.6-2.4,0.3-3.6c0,0-0.1,0.2-0.2,0.4c-0.4,0.9-1.4,3.1-2.9,4.5
c-1.1,0.3-2.2,0.5-3.4,0.5c-1.1,0-2.3-0.2-3.4-0.5c-1.5-1.4-2.6-3.6-3-4.5c-0.1-0.3-0.2-0.4-0.2-0.4c-0.3,1.2-0.2,2.5,0.3,3.6
c-1-0.7-1.7-1.6-1.9-2.7c-0.1-0.3-0.1-0.6-0.2-1c-0.9-3.6-3.8-4.6-3.8-4.6c1.1,1,1.9,3.2,2.2,5.8c0.4,3,3.3,4.4,3.3,4.4
c1.2,0.8,3.6,1.3,6.6,1.3c3,0,5.4-0.5,6.6-1.3l0,0l0,0c0,0,2.9-1.5,3.3-4.4c0.3-2.6,1.1-4.8,2.2-5.8C48.7,54.6,47.8,55.2,47.2,56z"
    />
    <path fill={fill} d="M34.5,34.7c1.4-0.6,1.8-2.1,0.9-3.4
c-1.8-2.6-4.8-3.9-7.6-5.1c-1-0.4-2-1.1-2.7-1.9c-0.7-0.9-1.2-1.9-1.3-3c-0.2,2.8,0.8,6.6,3.8,7.6C30.6,29.9,33.3,31.6,34.5,34.7z"
    />

    { gradient
    && (
      <>
        <linearGradient id="paint0_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
        <linearGradient id="paint1_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
        <linearGradient id="paint2_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
        <linearGradient id="paint3_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
        <linearGradient id="paint4_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
        <linearGradient id="paint5_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
        <linearGradient id="paint6_linear_2172_825" x1="12.5" y1="0" x2="12.5" y2="23.3593" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A220" />
          <stop offset="1" stopColor="#FFEBB8" />
        </linearGradient>
      </>

    )}
  </svg>
));

export default Dragon;
