import { commitMutation, graphql } from 'relay-hooks';

import environment from '../../../config/environment';

const mutation = graphql`  
  mutation confirmEmailMutation(
    $email: String!,
    $token:  String!){
    confirm_email(
      email: $email,
      token: $token,
    ) {
      success
      code
      message
      user_id
    }
  }
`;

const confirmEmail = (variables) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors) reject(errors);
        else resolve(response);
      },
      onError(error) {
        reject(error);
      },
    },
  );
});

export default confirmEmail;
