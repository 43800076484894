import CustomIcons from '@/components/CustomIcons';
import { IconWrapper } from '@partners-core/components';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Password from './Security/Password';
import SocialAccounts from './Security/SocialAccounts';

const Security = ({ profile }) => {
  const init = {
    name: false,
    phone: false,
    password: false,
    social: false,
  };
  const [opened, setOpened] = useState(init);

  const toggle = (item) => {
    setOpened({ ...init, [item]: !opened[item] });
  };
  return (
    <Row>
      <Col xs="auto" className="pr-0">
        <IconWrapper className="mr-3" width="44px" height="40px">
          <CustomIcons.ShieldCheck color="#B0AAFF" size="24" />
        </IconWrapper>
      </Col>
      <Col>
        <h4 className="bold mt-3">
          Login e Segurança
        </h4>
        <small>
          Forneça detalhes pessoais e informações de contato
        </small>
        <hr />

        <Password id="password" opened={opened.password} toggle={toggle} user={profile?.user} />
        <SocialAccounts id="social" opened={opened.social} toggle={toggle} />
      </Col>
    </Row>
  );
};

export default Security;
